import React, { Fragment, useState } from "react";
// import DataTable from "react-data-table-component";
import { DataTable } from "primereact/datatable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Media } from "reactstrap";
import axiosInstance from "../axios";
import { Column } from "primereact/column";

const MainCategoryDataTable = ({
	myData,
	myClass,
	pagination,
	HandleRender,
}) => {
	const handleSubmitDelete = (row) => {
		if (window.confirm(`هل تريد حذف  ${row.category.name_ar} ؟`)) {
			axiosInstance
				.delete(`/admin/main-page-category/${row.id}`)
				.then((res) => {
					toast.success("تم الحذف بنجاح");
					HandleRender();
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};

	const [rowDataID, setRowDataID] = useState(null);

	const editBodyTemplate = (category) => {
		return (
			<>
				<i
					onClick={(e) => {
						// setRowDataID(category.id);
						handleSubmitDelete(category);
					}}
					className="fa fa-trash"
					style={{
						width: 35,
						fontSize: 20,
						padding: 11,
						color: "rgb(40, 167, 69)",
					}}></i>
			</>
		);
	};
	const imageBodyTemplate = (category) => {
		return (
			<Media
				src={`${category?.category.thumbnail}`}
				alt=""
				className="img-fluid"
				width="150px"
			/>
		);
	};
	return (
		<div>
			<Fragment>
				<DataTable
					value={myData}
					tableStyle={{ minWidth: "50rem", textAlign: "right" }}>
					<Column field="id" header="Code" style={{ textAlign: "right" }}></Column>
					<Column
						field="category.name_ar"
						header="الاسم"
						style={{ textAlign: "right" }}></Column>
					<Column
						field="category.name_en"
						header="الاسم الانجليزي"
						style={{ textAlign: "right" }}></Column>
					{/* <Column field="category.name_ar" header="Quantity"></Column> */}
					<Column
						body={editBodyTemplate}
						header=""
						style={{ textAlign: "right" }}></Column>
					<Column
						body={imageBodyTemplate}
						style={{ width: "150px" }}
						header=""></Column>
				</DataTable>

				<ToastContainer autoClose={2000} rtl />
			</Fragment>
		</div>
	);
};

export default MainCategoryDataTable;
