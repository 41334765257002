import React from "react";
import UsersDatatable from "./UsersDatatable";
import Breadcrumb from "../common/breadcrumb";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
function UsersList() {
	return (
		<>
			<Breadcrumb title="المستخدمين والصلاحيات" />

			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<UsersDatatable />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default UsersList;
