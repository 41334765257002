import React from "react";
import { DollarSign, Layers, MessageSquare, Users } from "react-feather";
import { Card, CardBody, Col, Media, Row } from "reactstrap";
import CountUp from "react-countup";
import { useEffect } from "react";
import axiosInstance from "../axios";
import { useState } from "react";

function TopPart() {
	const [data, setData] = useState(null);
	useEffect(() => {
		axiosInstance.get(`/admin/dashboard-count`).then((res) => {
			setData(res.data);
		});
	}, []);

	return (
		<>
			<Row>
				<Col xl="3 xl-50" md="6">
					<Card className=" o-hidden widget-cards">
						<CardBody className="bg-warning">
							<Media className="static-top-widget row">
								<div className="icons-widgets col-4">
									<div className="align-self-center text-center">
										<DollarSign className="font-warning" />
									</div>
								</div>
								<Media body className="col-8">
									<span className="m-0">مبيعات فعلية</span>
									<h3 className="mb-0">
										<CountUp className="counter" end={data?.total_order_sales_paid} />{" "}
										جنيه
										{/* <small> </small> */}
									</h3>
								</Media>
							</Media>
						</CardBody>
					</Card>
				</Col>
				<Col xl="3 xl-50" md="6">
					<Card className=" o-hidden  widget-cards">
						<CardBody className="bg-secondary ">
							<Media className="static-top-widget row">
								<div className="icons-widgets col-4">
									<div className="align-self-center text-center">
										<Layers className="font-secondary" />
									</div>
								</div>
								<Media body className="col-8">
									<span className="m-0">الاصناف</span>
									<h3 className="mb-0">
										<CountUp className="counter" end={data?.product_count} /> صنف
									</h3>
								</Media>
							</Media>
						</CardBody>
					</Card>
				</Col>
				<Col xl="3 xl-50" md="6">
					<Card className="o-hidden widget-cards">
						<CardBody className="bg-primary">
							<Media className="static-top-widget row">
								<div className="icons-widgets col-4">
									<div className="align-self-center text-center">
										<MessageSquare className="font-primary" />
										{/* <Layers className="font-primary" /> */}
									</div>
								</div>
								<Media body className="col-8">
									<span className="m-0">الطلبيات</span>
									<h3 className="mb-0">
										<CountUp className="counter" end={data?.total_order_count} />
									</h3>
								</Media>
							</Media>
						</CardBody>
					</Card>
				</Col>
				<Col xl="3 xl-50" md="6">
					<Card className=" o-hidden widget-cards">
						<CardBody className="bg-danger ">
							<Media className="static-top-widget row">
								<div className="icons-widgets col-4">
									<div className="align-self-center text-center">
										<Users className="font-danger" />
									</div>
								</div>
								<Media body className="col-8">
									<span className="m-0">المستخدمين</span>
									<h3 className="mb-0">
										<CountUp className="counter" end={data?.total_user_count} />
									</h3>
								</Media>
							</Media>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</>
	);
}

export default TopPart;
