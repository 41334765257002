import React from "react";
import { Card, CardBody, Container, Row } from "reactstrap";

function Error403() {
	return (
		<>
			<Container fluid={true}>
				<Row>
					<Card className="mt-3">
						<CardBody>
							<h3>ليس لديك صلاحية</h3>
						</CardBody>
					</Card>
				</Row>
			</Container>
		</>
	);
}

export default Error403;
