import React, { Fragment, useEffect, useState } from "react";
// import Breadcrumb from "../../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import axiosInstance from "../axios";
import Breadcrumb from "../common/breadcrumb";
import DatatableOrderReportPrimeReact from "./components/datatableOrderReportPrimeReact";

const PeriodPaymentMethod = () => {
	const [render, setRender] = useState(false);
	const [data, setData] = useState([]);

	useEffect(() => {
		axiosInstance.get(`/report/order-report`).then((res) => {
			setData(res.data.results);
		});
	}, [render]);

	const handleRender = () => {
		setRender(!render);
	};

	return (
		<Fragment>
			<Breadcrumb title="تقرير طرق الدفع خلال فترة" />
			{/* <!-- Container-fluid starts--> */}
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<div className="clearfix"></div>
								<div id="basicScenario" className="product-physical">
									<DatatableOrderReportPrimeReact
										myData={data}
										multiSelectOption={false}
										pageSize={10}
										pagination={true}
										class="-striped -highlight"
										handleRender={handleRender}
										displayUser={false}
										displayPayStatus={false}
										displayOrderStatus={false}
										displayPaymentMethod={true}
										displayShippingArea={false}
										allowedFilters={["payment_method__in"]}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			{/* <!-- Container-fluid Ends--> */}
		</Fragment>
	);
};

export default PeriodPaymentMethod;
