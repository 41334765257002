import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
import axiosInstance from "../axios";
import CreateStaffUser from "./CreateStaffUser";
import { Button, Col, Row } from "reactstrap";
import AddPermission from "./AddPermission";

function UsersDatatable() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [render, setRender] = useState(false);
	useEffect(() => {
		setLoading(true);
		axiosInstance.get("/admin/staff-list").then((res) => {
			setData(res.data.results);
			setLoading(false);
		});
	}, [render]);

	function handleRender(params) {
		setRender(!render);
	}

	const [expandedRows, setExpandedRows] = useState(null);

	const rowExpansionTemplate = (row) => {
		return (
			<>
				<div className="d-flex justify-content-between align-items-center">
					<div
						onClick={() => {
							if (
								window.confirm(
									`هل انت متأكد من ${
										row.is_active === true ? "تعطيل" : "تفعيل"
									} المستخدم ؟`
								)
							) {
								axiosInstance
									.patch(`/admin/staff-detail/${row.id}`, {
										is_active: !row.is_active,
									})
									.then((res) => {
										toast.success("تم تعديل حالة المستخدم بنجاح");
										setRender(!render);
									})
									.catch((err) => {
										toast.error("حدث خطأ");
									});
							}
						}}
						className="btn btn-secondary">
						تفعيل/تعطيل
					</div>

					<div>
						<AddPermission userPermissionsData={row} handleRender={handleRender} />
					</div>
				</div>

				<hr />
				<div>
					<DataTable
						value={row.staff_permissions}
						rowHover
						showGridlines
						scrollable
						style={{ textAlign: "right" }}>
						<Column
							field="permission.name_ar"
							header="الصلاحية"
							style={{ textAlign: "right" }}></Column>
						<Column
							body={(row) => (
								<div>
									<Button
										onClick={() => {
											console.log(row, "row");
											axiosInstance
												.delete(`/admin/staff-permission-delete/${row.id}`)
												.then((res) => {
													handleRender();
													toast.success("تم حذف الصلاحية بنجاح");
												});
										}}>
										مسح
									</Button>
								</div>
							)}
							style={{ textAlign: "right" }}></Column>
					</DataTable>
				</div>
			</>
		);
	};

	return (
		<>
			<CreateStaffUser handleRender={handleRender} />
			<DataTable
				value={data}
				rowHover
				loading={loading}
				showGridlines
				scrollable
				expandedRows={expandedRows}
				onRowToggle={(e) => setExpandedRows(e.data)}
				rowExpansionTemplate={rowExpansionTemplate}>
				<Column field="id" header="كود" style={{ textAlign: "right" }}></Column>
				<Column
					field="first_name"
					header="اسم المستخدم"
					style={{ textAlign: "right" }}></Column>

				<Column
					field="email"
					header="البريد الالكتروني"
					style={{ textAlign: "right" }}></Column>
				<Column
					field="mobile_number"
					header="رقم الهاتف"
					style={{ textAlign: "right" }}></Column>

				<Column
					body={(row) => (
						<div>{row.is_active === true ? "active" : "not active"}</div>
					)}
					header="الحالة"
					style={{ textAlign: "right" }}></Column>
				<Column expander={true} style={{ width: "5rem" }} />
			</DataTable>
			<ToastContainer autoClose={2000} rtl />
		</>
	);
}

export default UsersDatatable;
