import React, { Fragment, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useSearchParams } from "react-router-dom";

import axiosInstance from "../../axios";
import OrderReportFilterComponent from "./OrderReportFilterComponent";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";

import { Tag } from "primereact/tag";
import { classNames } from "primereact/utils";
import { Button, Col, Row } from "reactstrap";
import { Dropdown } from "primereact/dropdown";
const DatatableOrderReportPrimeReact = ({
	myData,

	displayUser,
	displayPayStatus,
	displayOrderStatus,
	displayPaymentMethod,
	displayShippingArea,
	allowedFilters,
}) => {
	const navigate = useNavigate();
	const [first, setFirst] = useState([0, 0, 0]);
	const [rows, setRows] = useState([50]);
	const [countPerPage, setCountPerPage] = useState(10);
	const [totalCountFromApi, setTotalCountFromApi] = useState(null);
	const [nextPage, setNextPage] = useState(null);
	const [prevPage, setPrevPage] = useState(null);
	const [loading, setLoading] = useState(false);
	const [filterUser, setFilterUser] = useState(null);
	const [filterPaymentStatus, setFilterPaymentStatus] = useState(null);
	const [filterOrderStatus, setFilterOrderStatus] = useState(null);
	const [filterPaymentMethod, setFilterPaymentMethod] = useState(null);
	const [filterShippingArea, setFilterShippingArea] = useState(null);
	const [filterCreatedAtStartDate, setFilterCreatedAtStartDate] = useState(null);
	const [filterCreatedAtEndDate, setFilterCreatedAtEndDate] = useState(null);
	const [filteredData, setFilteredData] = useState(myData);
	const [searchParams, setSearchParams] = useSearchParams();
	const [totals, setTotals] = useState({});
	// filter area start here
	const [filterText, setFilterText] = useState("");

	useEffect(() => {}, [filterUser]);

	// filter area end here

	const handleFilterSubmit = (e) => {
		let allowed = false;
		if (allowedFilters === undefined || allowedFilters?.length === 0) {
			allowed = true;
		}
		searchParams?.forEach((value, key) => {
			if (allowedFilters?.includes(key)) {
				allowed = true;
			}
		});
		if (
			searchParams.get("start_date") &&
			searchParams.get("end_date") &&
			allowed === true
		) {
			setLoading(true);
			let countChange = countPerPage;
			if (Number.isInteger(e)) {
				countChange = e;
			}
			axiosInstance
				.get(
					`report/order-report?${searchParams.toString()}&page_size=${countChange}`
				)
				.then((res) => {
					setFilteredData(res.data.results);
					setPrevPage(res.data.previous);
					setNextPage(res.data.next);
					setLoading(false);
					setTotalCountFromApi(res.data.count);
					setTotals(res.data.totals);
				});
		} else {
			alert("يجب ادخال الحقول المطلوب البحث عنها");
		}
	};
	const handleClearAllClick = () => {
		// clear all use states

		setFilterUser(null);
		setFilterPaymentStatus(null);
		setFilterOrderStatus(null);
		setFilterPaymentMethod(null);
		setFilterShippingArea(null);
		setFilterCreatedAtStartDate(null);
		setFilterCreatedAtEndDate(null);
		setFilterText("");
	};
	const subHeaderComponentMemo = (
		<OrderReportFilterComponent
			//
			displayUser={displayUser}
			displayPayStatus={displayPayStatus}
			displayOrderStatus={displayOrderStatus}
			displayPaymentMethod={displayPaymentMethod}
			displayShippingArea={displayShippingArea}
			//
			onFilter={(e) => {
				setFilterText(e.target.value);
			}}
			// onClear={handleClear}
			filterUser={filterUser}
			filterPaymentStatus={filterPaymentStatus}
			filterOrderStatus={filterOrderStatus}
			filterPaymentMethod={filterPaymentMethod}
			filterShippingArea={filterShippingArea}
			filterCreatedAtStartDate={filterCreatedAtStartDate}
			filterCreatedAtEndDate={filterCreatedAtEndDate}
			onUserChange={(e) => {
				setFilterUser(e.value);
				let tempParams = "";
				for (let i = 0; i < e.value.length; i++) {
					tempParams += e.value[i].id + ",";
				}
				if (e.value === undefined || e.value.length === 0) {
					// searchParams.delete("user__in", "");
					searchParams.delete("user__in");
					setSearchParams(searchParams);
				} else {
					searchParams.set("user__in", tempParams);
					setSearchParams(searchParams);
					//name of th input is user-id
				}
			}}
			onPaymentStatusChange={(e) => {
				//name of th input is user-id
				setFilterPaymentStatus(e.target.value);

				let tempParams = "";
				for (let i = 0; i < e.value.length; i++) {
					tempParams += e.value[i].name + ",";
				}
				if (e.target.value === undefined || e.value.length === 0) {
					searchParams.delete("payment_status__in");
					setSearchParams(searchParams);
				} else {
					searchParams.set("payment_status__in", tempParams);
					setSearchParams(searchParams);
				}
			}}
			onOrderStatusChange={(e) => {
				setFilterOrderStatus(e.target.value);

				let tempParams = "";
				for (let i = 0; i < e.value.length; i++) {
					tempParams += e.value[i].name + ",";
				}
				if (e.target.value === undefined || e.value.length === 0) {
					searchParams.delete("order_status__in");
					setSearchParams(searchParams);
				} else {
					searchParams.set("order_status__in", tempParams);
					setSearchParams(searchParams);
				}
			}}
			onPaymentMethodChange={(e) => {
				//name of th input is user-id
				setFilterPaymentMethod(e.target.value);

				let tempParams = "";
				for (let i = 0; i < e.value.length; i++) {
					tempParams += e.value[i].id + ",";
				}
				if (
					e.target.value === undefined ||
					// searchParams.get("payment_method__in") !== null ||
					e.value.length === 0
				) {
					// searchParams.set("payment_method__in", "");
					searchParams.delete("payment_method__in");
					setSearchParams(searchParams);
				} else {
					searchParams.set("payment_method__in", tempParams);
					setSearchParams(searchParams);
				}
			}}
			onShippingAreaChange={(e) => {
				setFilterShippingArea(e.target.value);

				let tempParams = "";
				for (let i = 0; i < e.value.length; i++) {
					tempParams += e.value[i].id + ",";
				}
				if (e.target.value === undefined || e.value.length === 0) {
					searchParams.delete("shipping_area__in");
					setSearchParams(searchParams);
				} else {
					searchParams.set("shipping_area__in", tempParams);
					setSearchParams(searchParams);
				}
				//name of th input is user-id
			}}
			onCreatedAtStartDateChange={(e) => {
				setFilterCreatedAtStartDate(e.target.value);
				let startDate = new Date(e.target.value);
				startDate = new Date(startDate.getTime());
				startDate = startDate.toISOString();
				searchParams.set("start_date", `${startDate}`);
				setSearchParams(searchParams);
			}}
			onCreatedAtEndDateChange={(e) => {
				setFilterCreatedAtEndDate(e.target.value);
				let endDate = new Date(e.target.value);
				endDate = new Date(endDate.setDate(endDate.getDate() + 1));
				endDate = new Date(endDate.getTime());
				endDate = endDate.toISOString();
				searchParams.set("end_date", `${endDate}`);
				setSearchParams(searchParams);
			}}
			filterText={filterText}
			onFilterClick={handleFilterSubmit}
			onClearAllClick={handleClearAllClick}
		/>
	);

	const onPageChange = (e, index) => {
		setFirst(first.map((f, i) => (index === i ? e.first : f)));
		setRows(rows.map((r, i) => (index === i ? e.rows : r)));
	};

	const template1 = {
		layout: "PrevPageLink PageLinks NextPageLink RowsPerPageDropdown ",
		PrevPageLink: (options) => {
			return (
				<button
					className={classNames(options.className, "border-round")}
					type="button"
					onClick={(e) => {
						// setCurrentPage(currentPage + 1);
						setLoading(true);
						options.onClick(e);
						setLoading(true);
						axiosInstance
							.get(
								`report/order-report?${searchParams.toString()}&page=${prevPage}&page_size=${countPerPage}`
							)
							// .get(`admin/get-test-user-list?page_size=100000`)
							.then((res) => {
								setFilteredData(res.data.results);
								setPrevPage(res.data.previous);
								setNextPage(res.data.next);
								setLoading(false);
								setTotalCountFromApi(res.data.count);
								setTotals(res.data.totals);
							});
					}}
					disabled={options.disabled}>
					<span className="p-3">Previous</span>
				</button>
			);
		},
		NextPageLink: (options) => {
			return (
				<button
					className={classNames(options.className, "border-round")}
					type="button"
					onClick={(e) => {
						// setCurrentPage(currentPage + 1);
						options.onClick(e);
						setLoading(true);
						axiosInstance
							.get(
								`report/order-report?${searchParams.toString()}&page=${nextPage}&page_size=${countPerPage}`
							)

							.then((res) => {
								setFilteredData(res.data.results);
								setPrevPage(res.data.previous);
								setNextPage(res.data.next);
								setLoading(false);
								setTotalCountFromApi(res.data.count);
								setTotals(res.data.totals);
							});
					}}
					disabled={options.disabled}>
					<span className="p-3">Next</span>
				</button>
			);
		},
		PageLinks: (options) => {
			if (
				(options.view.startPage === options.page && options.view.startPage !== 0) ||
				(options.view.endPage === options.page &&
					options.page + 1 !== options.totalPages)
			) {
				const className = classNames(options.className, { "p-disabled": true });

				return (
					<span className={className} style={{ userSelect: "none" }}>
						...
					</span>
				);
			}

			return (
				<button
					type="button"
					className={options.className}
					onClick={(e) => {
						setLoading(true);
						options.onClick(e);

						axiosInstance
							.get(
								`report/order-report?${searchParams.toString()}&page=${
									options.page + 1
								}&page_size=${countPerPage}`
							)
							// .get(`admin/get-test-user-list?page_size=100000`)
							.then((res) => {
								setFilteredData(res.data.results);
								setPrevPage(res.data.previous);
								setNextPage(res.data.next);
								setLoading(false);
								setTotalCountFromApi(res.data.count);
								setTotals(res.data.totals);
							});
					}}>
					{options.page + 1}
				</button>
			);
		},

		CurrentPageReport: (options) => {
			return (
				<span
					className="mx-3"
					style={{ color: "var(--text-color)", userSelect: "none" }}></span>
			);
		},
		RowsPerPageDropdown: (options) => {
			const dropdownOptions = [
				{ label: 10, value: 10 },
				{ label: 25, value: 25 },
				{ label: 50, value: 50 },
			];

			return (
				<Dropdown
					value={options.value}
					options={dropdownOptions}
					onChange={(e) => {
						options.onChange(e);
						setCountPerPage(e.value);
						handleFilterSubmit(e.value);
					}}
				/>
			);
		},
	};

	const footer = (
		<>
			<Row>
				<h3>الاجماليات خلال فترة</h3>
				<hr />
			</Row>
			<Row>
				<Col className="text-right" sm={12} md={6} lg={4}>
					<div className="d-flex justify-content-between">
						<h5>اجمالى مبيعات </h5>={" "}
						{totals?.total_every_thing ? totals?.total_every_thing : 0}
					</div>

					<div className="d-flex justify-content-between">
						<h5>(-) اجمالى مرتجع </h5>={" "}
						{totals?.total_refund_price ? totals?.total_refund_price : 0}
					</div>
					<div className="d-flex justify-content-between">
						<h5>(-) خصم </h5>={" "}
						{totals?.total_promo_code_discount
							? totals?.total_promo_code_discount
							: 0}
					</div>
					<hr />
					<div className="d-flex justify-content-between">
						<h5> اجمالى بالشحن </h5>={" "}
						{totals?.total_every_thing
							? parseFloat(totals?.total_every_thing) -
							  parseFloat(totals?.total_refund_price) -
							  parseFloat(totals?.total_promo_code_discount)
							: 0}
					</div>
					<div className="d-flex justify-content-between">
						<h5>(-) اجمالى الشحن </h5>={" "}
						{totals?.total_shipping_price ? totals?.total_shipping_price : 0}
					</div>
					<hr />
					<div className="d-flex justify-content-between">
						<h5> صافى مبيعات </h5>={" "}
						{totals?.total_items_price ? totals?.total_items_price : 0}
					</div>
				</Col>
				<Col sm={12} md={6} lg={4}>
					<div className="d-flex justify-content-between">
						<h5>صافى مبيعات محصل </h5>={" "}
						{totals?.total_items_price_paid ? totals?.total_items_price_paid : 0}
					</div>
					<div className="d-flex justify-content-between">
						<h5>(+) اجمالى الشحن محصل </h5>={" "}
						{totals?.total_shipping_price_paid
							? totals?.total_shipping_price_paid
							: 0}
					</div>
					<hr />
					<div className="d-flex justify-content-between">
						<h5>اجمالى محصل </h5>={" "}
						{totals?.total_items_price_paid
							? parseFloat(totals?.total_items_price_paid) +
							  parseFloat(totals?.total_shipping_price_paid)
							: 0}
					</div>
				</Col>
				<Col sm={12} md={6} lg={4}>
					<div className="d-flex justify-content-between">
						<h5>صافى مبيعات غير محصل </h5>={" "}
						{totals?.total_items_price_unpaid ? totals?.total_items_price_unpaid : 0}
					</div>
					<div className="d-flex justify-content-between">
						<h5>(+) اجمالى الشحن غير محصل </h5>={" "}
						{totals?.total_shipping_price_unpaid
							? totals?.total_shipping_price_unpaid
							: 0}
					</div>
					<hr />
					<div className="d-flex justify-content-between">
						<h5>اجمالى غير محصل </h5>={" "}
						{totals?.total_shipping_price_unpaid
							? parseFloat(totals?.total_shipping_price_unpaid) +
							  parseFloat(totals?.total_items_price_unpaid)
							: 0}
					</div>
				</Col>
			</Row>
			<hr />
			<Row>
				<Paginator
					template={template1}
					first={first[0]}
					rows={countPerPage}
					totalRecords={totalCountFromApi}
					onPageChange={(e) => onPageChange(e, 0)}
				/>
			</Row>
		</>
	);

	const statusBodyTemplate = (order) => {
		return (
			<>
				<span className={`order-badge status-${order?.order_status.toLowerCase()}`}>
					{order?.order_status}
				</span>
			</>
		);
	};
	const paymentStatusBodyTemplate = (order) => {
		return (
			<>
				<span
					className={`order-badge status-${order?.payment_status.toLowerCase()}`}>
					{order?.payment_status}
				</span>
			</>
		);
	};

	return (
		<>
			{subHeaderComponentMemo}
			<DataTable
				footer={footer}
				value={filteredData}
				dataKey="id"
				rowHover
				stripedRows
				filterDisplay="menu"
				loading={loading}
				globalFilterFields={["id"]}
				onRowClick={(e) => navigate(`/sales/orders/order-detail/${e.data.id}`)}
				emptyMessage="لا يوجد طلبات  توافق الاختيارات">
				<Column
					className="text-right"
					field="id"
					header="رقم الطلب"
					style={{ minWidth: "10%", textAlign: "right" }}></Column>
				<Column
					className="text-right"
					field="created_at"
					header="تاريخ الطلب"
					body={(rowData) => <>{rowData.created_at.split("T")[0]}</>}
					style={{ minWidth: "15%", textAlign: "right" }}></Column>
				<Column
					className="text-right"
					field="order_status"
					header="حالة الطلب"
					body={statusBodyTemplate}
					style={{ minWidth: "15%", textAlign: "right" }}></Column>
				<Column
					className="text-right"
					field="shipping_price"
					header="الشحن"
					style={{ minWidth: "15", textAlign: "right" }}></Column>
				<Column
					className="text-right"
					field="shipping_area"
					header=" الشحن"
					style={{ minWidth: "15%", textAlign: "right" }}></Column>
				<Column
					className="text-right"
					field="total_refund_price"
					header="اجمالى المرتجعات"
					style={{ minWidth: "15%", textAlign: "right" }}></Column>
				<Column
					className="text-right"
					field="total_price_after_discount"
					header="السعر بعد الخصم"
					style={{ minWidth: "15%", textAlign: "right" }}></Column>
				<Column
					className="text-right"
					field="payment_method"
					header="طريقة الدفع"
					style={{ minWidth: "15%", textAlign: "right" }}></Column>
				<Column
					className="text-right"
					field="payment_status"
					header="حالة الدفع"
					body={paymentStatusBodyTemplate}
					style={{ minWidth: "15%", textAlign: "right" }}></Column>

				{/* <Column
					className="text-right"
					field="status"
					header="الحالة"
					// filterMenuStyle={{ width: "14rem" }}
					style={{ minWidth: "10%" }}
					body={statusBodyTemplate}
					// filter
					filterElement={statusFilterTemplate}
				/> */}
				{/* <Column
					className="text-right"
					body={actionBodyTemplate}
					exportable={false}
					style={{ minWidth: "10%" }}></Column> */}
			</DataTable>
		</>
	);
};

export default DatatableOrderReportPrimeReact;
