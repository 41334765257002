import React from "react";
import { useState } from "react";
import {
	Button,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "reactstrap";
import axiosInstance from "../../axios";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function RemoveQuantity({ HandleRenderColor, colorData }) {
	const [open, setOpen] = useState(false);
	const [formData, updateFormData] = useState(null);
	const { productID } = useParams();
	const onOpenModal = () => {
		setOpen(true);
	};

	const onCloseModal = () => {
		setOpen(false);
	};
	const handleChange = (e) => {
		updateFormData({
			...formData,
			// Trimming any whitespace
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		if (formData) {
			e.preventDefault();
			axiosInstance
				.patch(
					`/products/product/${productID}/product-line-remove-count/${colorData.id}`,
					formData
				)
				.then((res) => {
					// setRender(res.data.id);
					updateFormData(null);
					onCloseModal("VaryingMdo");

					if (res.status === 200) {
						HandleRenderColor();
						toast.success("تم تقليل الكمية بنجاح");
					}
				})
				.catch((err) => {
					if (err.response.status === 400) {
						toast.warning(`تاكد من البيانات المدخلة`);
					} else {
						toast.error(`${err.response.status} تاكد من البيانات المدخلة`);
					}
				});
		} else {
			toast.error("من فضلك ادخل الكمية المراد تقليلها");
		}
	};
	return (
		<>
			<i
				onClick={(e) => {
					if (colorData !== null) {
						onOpenModal();
					} else {
						toast.error("برجاء اختيار لون");
					}
				}}
				className="fa fa-minus"
				style={{
					width: 35,
					fontSize: 20,
					padding: 11,
					color: "rgb(40, 167, 69)",
					cursor: "pointer",
				}}></i>

			<Modal isOpen={open} toggle={onCloseModal}>
				<ModalHeader toggle={onCloseModal}>
					<div className="modal-title f-w-600" id="exampleModalLabel2">
						إنقاص كمية
					</div>
				</ModalHeader>
				<ModalBody>
					<Form>
						<div>
							<h3>الكمية المتاحة</h3>
							<p>{colorData?.stock_quantity}</p>
						</div>
						<FormGroup>
							<Label htmlFor="recipient-name" className="col-form-label">
								الكمية :
							</Label>
							<Input
								type="number"
								name="stock_quantity"
								className="form-control"
								onChange={handleChange}
							/>
						</FormGroup>
					</Form>
				</ModalBody>
				<ModalFooter className="d-flex justify-content-between">
					<Button
						type="button"
						color="secondary"
						onClick={(e) => {
							handleSubmit(e);
						}}>
						حفظ
					</Button>
					<Button
						type="button"
						color="primary"
						onClick={() => onCloseModal("VaryingMdo")}>
						اغلاق
					</Button>
				</ModalFooter>
			</Modal>
			<ToastContainer autoClose={2000} rtl />
		</>
	);
}

export default RemoveQuantity;
