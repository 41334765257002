import React, { Fragment, useEffect, useState } from "react";
import { useRef } from "react";
import DataTable from "react-data-table-component";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Compressor from "compressorjs";
import "react-toastify/dist/ReactToastify.css";
import {
	Button,
	Form,
	FormGroup,
	Input,
	Label,
	Media,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "reactstrap";
import axiosInstance from "../axios";
import FilterComponent from "./FilterComponent";

const DatatableStockReport = ({
	myData,
	myClass,
	multiSelectOption,
	pagination,
	handleRender,
}) => {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [formData, updateFormData] = useState(null);
	const [newRowData, setNewRowData] = useState([]);
	const [filteredData, setFilteredData] = useState(myData);

	// filter area start here
	const [filterText, setFilterText] = useState("");

	function isArabic(text) {
		let arabic = /[\u0600-\u06FF]/;
		// var pattern = /[\u0600-\u06FF\u0750-\u077F]/;
		return arabic.test(text);
	}
	useEffect(() => {
		let filteredItems = [];
		if (filterText === "") {
			setFilteredData(myData);
		} else {
			if (isArabic(filterText)) {
				filteredItems = myData.filter(
					(item) => item.product.name_ar && item.product.name_ar.includes(filterText)
				);
				if (filteredItems.length === 0) {
					filteredItems = myData.filter(
						(item) => item.color_ar && item.color_ar.includes(filterText)
					);
				}
			} else {
				filteredItems = myData.filter(
					(item) => item.product.name_en && item.product.name_en.includes(filterText)
				);
				if (filteredItems.length === 0) {
					filteredItems = myData.filter(
						(item) =>
							item.color_en &&
							item.color_en.toLowerCase().includes(filterText.toLowerCase())
					);
				}
			}
			setFilteredData(filteredItems);
		}
	}, [filterText, myData]);

	const subHeaderComponentMemo = (
		<FilterComponent
			onFilter={(e) => {
				setFilterText(e.target.value);
			}}
			// onClear={handleClear}
			filterText={filterText}
		/>
	);
	// filter area end here

	const handleChange = (e) => {
		updateFormData({
			...formData,
			// Trimming any whitespace
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		if (formData !== null) {
			e.preventDefault();
			axiosInstance
				.patch(
					`/products/product/${newRowData.product.id}/product-line-add-count/${newRowData.id}`,
					formData
				)
				.then((res) => {
					updateFormData(null);
					handleRender();
					onCloseModal("VaryingMdo");

					if (res.status === 200) {
						toast.success("تم الاضافة بنجاح");
					}
				})
				.catch((err) => {
					if (err.response.status === 400) {
						toast.warning("من فضلك ادخل الكمية المراد اضافتها");
					} else {
						toast.error(`${err.response.status} تاكد من البيانات المدخلة`);
					}
				});
		} else {
			toast.error("من فضلك ادخل الكمية المراد اضافتها");
		}
	};

	const onOpenModal = () => {
		setOpen(true);
	};

	const onCloseModal = () => {
		setOpen(false);
	};

	const columns = [
		{
			id: "id",
			name: "Id",
			selector: (row) => row.id,
			style: {
				textAlign: "center",
			},
		},
		{
			id: "name_ar",
			name: "الاسم عربى",
			sortable: true,

			selector: (row) => row.product.name_ar,
			style: {
				textAlign: "center",
			},
		},
		{
			id: "name_en",
			name: "الاسم انجليزي",
			sortable: true,

			selector: (row) => row.product.name_en,
			style: {
				textAlign: "center",
			},
		},
		{
			id: "color_en",
			name: "اللون انجليزي",
			sortable: true,

			selector: (row) => row.color_en,
			style: {
				textAlign: "center",
			},
		},
		{
			id: "color_ar",
			name: "اللون عربى",
			sortable: true,

			selector: (row) => row.color_ar,
			style: {
				textAlign: "center",
			},
		},

		{
			id: "alert_stock_quantity",
			name: "تحذير عند الكمية",
			sortable: true,

			selector: (row) => row.alert_stock_quantity,
			style: {
				textAlign: "center",
			},
		},
		{
			id: "stock_quantity",
			name: "الكمية المتاحة",
			sortable: true,

			selector: (row) => row.stock_quantity,
			style: {
				textAlign: "center",
			},
		},

		{
			id: "is_deleted",
			name: "مخفى",
			cell: (row) => {
				return <div>{row.is_deleted.toString() === "true" ? "مخفى" : "مرئى"}</div>;
			},
			style: {
				textAlign: "center",
			},
		},
	];

	columns.push({
		name: <b>عمليات</b>,
		id: "delete",
		accessor: (str) => "delete",
		cell: (row, index) => (
			<div>
				<span>
					<i
						onClick={(e) => {
							onOpenModal();
							setNewRowData(row);
						}}
						className="fa fa-pencil"
						style={{
							width: 35,
							fontSize: 20,
							padding: 11,
							color: "rgb(40, 167, 69)",
							cursor: "pointer",
						}}></i>
					{/* main modal to edi color start */}
					<Modal isOpen={open} toggle={onCloseModal} key={row.id}>
						<ModalHeader toggle={onCloseModal}>
							<h5 className="modal-title f-w-600" id="exampleModalLabel2">
								اضافة كمية
							</h5>
						</ModalHeader>
						<ModalBody>
							<Form>
								<FormGroup>
									<Label htmlFor="recipient-name" className="col-form-label">
										الكمية :
									</Label>
									<Input
										type="number"
										name="stock_quantity"
										className="form-control"
										onChange={handleChange}
										defaultValue={newRowData?.stock_quantity}
									/>
								</FormGroup>
							</Form>
						</ModalBody>
						<ModalFooter className="d-flex justify-content-between">
							<Button
								type="button"
								color="secondary"
								onClick={(e) => {
									handleSubmit(e);
								}}>
								حفظ
							</Button>
							<Button
								type="button"
								color="primary"
								onClick={() => onCloseModal("VaryingMdo")}>
								اغلاق
							</Button>
						</ModalFooter>
					</Modal>
					{/* main modal to edi color end */}
				</span>
			</div>
		),
		style: {
			textAlign: "center",
		},
		sortable: false,
	});
	columns.push({
		name: <b>صورة</b>,
		cell: (row, index) => (
			<div>
				<Media
					src={`${row.product_line_image[0].url}`}
					alt=""
					className="img-fluid customCursor"
					onClick={() => {
						navigate(`/products/${row.product.id}/product-detail`);
					}}
				/>
			</div>
		),
		style: {
			textAlign: "center",
		},
		sortable: false,
	});
	return (
		<div>
			<Fragment>
				<DataTable
					data={filteredData}
					columns={columns}
					className={myClass}
					pagination={pagination}
					striped={true}
					center={true}
					subHeader
					subHeaderComponent={subHeaderComponentMemo}
				/>

				<ToastContainer />
			</Fragment>
		</div>
	);
};

export default DatatableStockReport;
