import React from "react";

const UserPanel = () => {
	return (
		<div>
			<div className="sidebar-user text-center">
				<h6 className="mt-3 f-14">اسم</h6>
				<p>اسم مستخدم</p>
			</div>
		</div>
	);
};

export default UserPanel;
