import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import App from "../components/app";
import Datatable from "../components/common/datatable";

import Dashboard from "../components/dashboard";

import Slider from "../components/main-page/Silder";

import Category from "../components/products/category";
import Productdetail from "../components/products/product-detail";

import Products from "../components/products/products";

import Orders from "../components/sales/orders";

import Profile from "../components/settings/profile";

import ParallaxImage from "../components/main-page/ParallaxImage";
import BestSeller from "../components/main-page/BestSeller";
import AddCity from "../components/Delivery/AddCity";
import StockReport from "../components/reports/stockReport";
import OrderDetail from "../components/sales/OrderDetail";
import OrderReport from "../components/reports/order-report";
import ContactUs from "../components/contact-us/ContactUs";
import PeriodPaymentMethod from "../components/reports/periodPaymentMethod";
import PaymentStatus from "../components/reports/paymentStatus";
import Refund from "../components/reports/refund";
import ClientReport from "../components/reports/client-report";
import Messages from "../components/contact-us/Messeges";
import ProductCount from "../components/reports/productCount";
import ProductReport from "../components/reports/components/ProductReport";
import Invoice from "../components/invoice/Invoice";
import MainPageCategory from "../components/main-page/mainPageCategory";
import AboutUs from "../components/about-us/AboutUs";
import SwitchOff from "../components/siteSetting/SwitchOff";
import PromoCodes from "../components/siteSetting/PromoCodes";
import UsersList from "../components/siteSetting/UsersList";
import Error403 from "../components/403";

const LayoutRoutes = () => {
	return (
		<Fragment>
			<Routes>
				<Route element={<App />}>
					<Route
						path={`${process.env.PUBLIC_URL}/dashboard`}
						element={<Dashboard />}
					/>
					<Route path={`/Invoice`} element={<Invoice />} />
					<Route path={`/403`} element={<Error403 />} />
					<Route path={`/category`} element={<Category />} />
					<Route path={`/products`} element={<Products />} />

					<Route
						path={`/products/:productID/product-detail`}
						element={<Productdetail />}
					/>

					<Route path={`/switch-off`} element={<SwitchOff />} />
					<Route path={`/promo-codes`} element={<PromoCodes />} />
					<Route path={`/user-list`} element={<UsersList />} />

					<Route path={`/slider`} element={<Slider />} />
					<Route path={`/ParallaxImage`} element={<ParallaxImage />} />
					<Route path={`/bestSeller`} element={<BestSeller />} />
					<Route path={`/category-main-page`} element={<MainPageCategory />} />

					<Route path={`/AddCity`} element={<AddCity />} />

					<Route
						path={`${process.env.PUBLIC_URL}/reports/stock-report`}
						element={<StockReport />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/reports/period-Payment-Method-Report`}
						element={<PeriodPaymentMethod />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/reports/payment-status-Report`}
						element={<PaymentStatus />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/reports/refund-Report`}
						element={<Refund />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/reports/client-Report`}
						element={<ClientReport />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/reports/product-count`}
						element={<ProductCount />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/reports/product-count/:productID`}
						element={<ProductReport />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/sales/orders`}
						element={<Orders />}
					/>
					<Route path={`${process.env.PUBLIC_URL}/about-us`} element={<AboutUs />} />
					<Route
						path={`${process.env.PUBLIC_URL}/contact-us`}
						element={<ContactUs />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/Messages`}
						element={<Messages />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/sales/orders/order-detail/:orderId`}
						element={<OrderDetail />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/reports/order-report`}
						element={<OrderReport />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/settings/profile`}
						element={<Profile />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/data-table`}
						element={<Datatable />}
					/>
				</Route>
			</Routes>
		</Fragment>
	);
};

export default LayoutRoutes;
