import React from "react";
import { Col, Media, Row } from "reactstrap";

import { useState } from "react";
import { useEffect } from "react";
import axiosInstance from "../axios";
import { Chart } from "primereact/chart";
function City() {
	const [data, setData] = useState([]);
	// useEffect(() => {
	// 	axiosInstance.get(`/report/shipping-area-count`).then((res) => {
	// 		setData(res.data.data.slice(0, 5));
	// 		console.log(res.data.data.slice(0, 5));
	// 	});
	// }, []);

	useEffect(() => {
		axiosInstance.get(`/report/shipping-area-count`).then((res) => {
			let tempLable = [];
			let tempColor = [];
			let tempPrice = [];

			console.log(res.data);
			for (let i = 0; i < 5; i++) {
				tempLable.push(res.data.data[i].name_ar);
				tempColor.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`);
				tempPrice.push(res.data.data[i].count);
			}
			setData(res.data.data.slice(0, 5));
			setChartPieColor(tempColor);
			setChartPiePrice(tempPrice);
			setChartPieLable(tempLable);
		});
	}, []);

	const [chartPieLable, setChartPieLable] = useState([]);
	const [chartPieColor, setChartPieColor] = useState(["#ffffff"]);
	const [chartPiePrice, setChartPiePrice] = useState([]);
	// const [chartQty, setChartQty] = useState([1]);

	const chartData1 = {
		labels: chartPieLable,
		datasets: [
			{
				data: chartPiePrice,
				backgroundColor: chartPieColor,
			},
		],
	};
	const [lightOptions] = useState({
		plugins: {
			legend: {
				labels: {
					color: "#495057",
				},
			},
		},
	});
	return (
		<>
			{" "}
			<Row>
				<Col sm="12" className="mt-2">
					<h6>اماكن الشحن</h6>
					<div className="order-graph sm-order-space">
						<h6>نسب الالوان</h6>
						<div className="peity-chart-dashboard text-center">
							<div className="w-50  m-auto">
								{data.length}
								<Chart
									type="doughnut"
									data={chartData1}
									options={lightOptions}
									style={{ position: "relative" }}
								/>
							</div>
						</div>
					</div>

					<div className="order-graph">
						<div className="order-graph-bottom">
							{data?.map((item) => (
								<Media key={item.id}>
									<div key={item.id} className="order-color-primary"></div>
									<Media key={item.id} body>
										<h6 className="mb-0">
											{item.name_ar} <span className="pull-right">{item.count}</span>
										</h6>
									</Media>
								</Media>
							))}
						</div>
					</div>
				</Col>

				{/* <Col xl="6 xl-100">
      <div className="order-graph xl-space">
        <h6>مبيعات اخر شهر</h6>
        <div className="ct-4 flot-chart-container">
          <Line type="area" data={employeeData} options={employeeOptions} />
        </div>
      </div>
    </Col> */}
			</Row>
		</>
	);
}

export default City;
