import React from "react";
import image from "../../assets/images/invoice.svg";
function Invoice() {
	return (
		<>
			{/* <section class="theme-invoice-1 section-b-space pt-5">
				<div class="container">
					<div class="row">
						<div class="col-xl-8 m-auto">
							<div class="invoice-wrapper">
								<div class="invoice-header">
									<div class="upper-icon">
										<img src={image} class="img-fluid" alt="" />
									</div>
									<div class="row header-content">
										<div class="col-md-6">
											<img src="assets/images/logo.png" class="img-fluid" alt="" />
											<div class="mt-md-4 mt-3">
												<h4 class="mb-2">Multikart Demo Store india - 363512</h4>
												<h4 class="mb-0">support@multikart.com</h4>
											</div>
										</div>
										<div class="col-md-6 text-md-end mt-md-0 mt-4">
											<h2>invoice</h2>
											<div class="mt-md-4 mt-3">
												<h4 class="mb-2">2664 Tail Ends Road, ORADELL</h4>
												<h4 class="mb-0">New Jersey, 07649</h4>
											</div>
										</div>
									</div>
									<div class="detail-bottom">
										<ul>
											<li>
												<span>issue date :</span>
												<h4>20 march, 2020</h4>
											</li>
											<li>
												<span>invoice no :</span>
												<h4>908452</h4>
											</li>
											<li>
												<span>email :</span>
												<h4>user@gmail.com</h4>
											</li>
										</ul>
									</div>
								</div>
								<div class="invoice-body table-responsive-md">
									<table class="table table-borderless mb-0">
										<thead>
											<tr>
												<th scope="col">#</th>
												<th scope="col">description</th>
												<th scope="col">price</th>
												<th scope="col">hrs.</th>
												<th scope="col">total</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<th scope="row">1</th>
												<td>Logo Designing</td>
												<td>$50</td>
												<td>2</td>
												<td>$100</td>
											</tr>
											<tr>
												<th scope="row">2</th>
												<td>website & banner design</td>
												<td>$30</td>
												<td>3</td>
												<td>$90</td>
											</tr>
											<tr>
												<th scope="row">3</th>
												<td>frontend development</td>
												<td>$95</td>
												<td>1</td>
												<td>$95</td>
											</tr>
											<tr>
												<th scope="row">4</th>
												<td>backend development</td>
												<td>$95</td>
												<td>1</td>
												<td>$95</td>
											</tr>
											<tr>
												<th scope="row">5</th>
												<td>SEO, Deigital marketing</td>
												<td>$95</td>
												<td>1</td>
												<td>$95</td>
											</tr>
										</tbody>
										<tfoot>
											<tr>
												<td colspan="2"></td>
												<td class="font-bold text-dark" colspan="2">
													GRAND TOTAL
												</td>
												<td class="font-bold text-theme">$325.00</td>
											</tr>
										</tfoot>
									</table>
								</div>
								<div class="invoice-footer text-end">
									<div class="buttons" id="printPageButton">
										<a
											href="/assets/pdf/invoice.pdf"
											class="btn black-btn btn-solid rounded-2 me-2"
											onclick={() => {}}>
											export as PDF
										</a>
										<a href="/" class="btn btn-solid rounded-2" onclick="window.print();">
											print
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}
			<div>test inv</div>
		</>
	);
}

export default Invoice;
