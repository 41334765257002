import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import Slider from "react-slick";
import "../../assets/scss/slick.scss";
import "../../assets/scss/slick-theme.scss";
// image import
import { Button, Card, Col, Container, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import axiosInstance from "../axios";
import MainDetails from "./components/main-details";
import AddNewColor from "./components/addNewColor";
import EditColor from "./components/editColor";
import AddImg from "./components/AddImg";
import DeleteImage from "./components/DeleteImage";
import AddQuantity from "./components/AddQuantity";
import RemoveQuantity from "./components/RemoveQuantity";
import { toast } from "react-toastify";

const Product_detail = () => {
	const { productID } = useParams();
	const [render, setRender] = useState(false);
	const [renderColor, setRenderColor] = useState(false);
	const [renderColorImage, setRenderColorImage] = useState(false);
	const [productData, setProductData] = useState(null);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [selectedImage, setSelectedImage] = useState(null);
	const [nav, setNav] = useState({
		nav1: null,
		nav2: null,
	});

	const HandleRender = () => {
		setRender(!render);
	};

	const HandleRenderColor = () => {
		setRenderColor(!renderColor);
	};
	const HandleRenderColorImage = () => {
		setRenderColorImage(!renderColorImage);
	};

	useEffect(() => {
		setNav({
			nav1: Slider.slider1,
			nav2: Slider.slider2,
		});
	}, []);

	const [selectedSeason, setSelectedSeason] = useState(null);

	useEffect(() => {
		axiosInstance.get(`/products/product/${productID}`).then((res) => {
			setProductData(res.data);
			if (res.data.season === "winter") {
				setSelectedSeason({ name: "شتوى", code: "winter" });
			} else {
				setSelectedSeason({ name: "صيفى", code: "summer" });
			}
		});
	}, [render]);

	useEffect(() => {
		if (selectedProduct !== null) {
			axiosInstance
				.get(`/products/product/${productID}/product-line/${selectedProduct.id}`)
				.then((res) => {
					setSelectedProduct(res.data);
				});
		}
	}, [renderColorImage, renderColor]);

	return (
		<Fragment>
			<Breadcrumb title="بيانات صنف" />
			<Container fluid={true}>
				<Card>
					<Row className="product-page-main card-body">
						<Col xl="4">
							{selectedProduct === null ? (
								<img className="img-fluid" src={productData?.url} alt="" />
							) : (
								<Slider
									swipeToSlide={false}
									asNavFor={nav.nav2}
									ref={(slider) => (Slider.slider1 = slider)}
									className="product-slider">
									{selectedProduct?.product_line_image?.map((image) => (
										<div className="item" key={image.id}>
											<img key={image.id} className="img-fluid" src={image.url} alt="" />
										</div>
									))}
								</Slider>
							)}
							{selectedProduct === null ? (
								""
							) : (
								<Slider
									infinite={false}
									asNavFor={nav.nav1}
									ref={(slider) => (Slider.slider2 = slider)}
									slidesToShow={4}
									swipeToSlide={true}
									focusOnSelect={true}
									className="small-slick">
									{selectedProduct?.product_line_image?.map((image) => (
										<div className="item" key={image.id}>
											<img
												key={image.id}
												onClick={() => {
													setSelectedImage(image);
												}}
												className="img-fluid"
												src={image.url}
												alt=""
											/>
										</div>
									))}
								</Slider>
							)}
						</Col>
						<Col xl="8">
							<div className="product-page-details product-right mb-0">
								<MainDetails
									data={productData}
									HandleRender={HandleRender}
									setSelectedSeason={setSelectedSeason}
									selectedSeason={selectedSeason}
								/>
								<hr />
								<Col
									xl="12"
									className="d-flex align-items-center justify-content-between">
									<div>
										<ul className="color-variant">
											{productData?.product_line?.map((color) => (
												<li
													style={{
														backgroundColor: `${color.color_value}`,
														border: "2px solid #626262",
													}}
													key={color.id}
													title={color.color}
													onClick={() => {
														setSelectedImage(null);
														setSelectedProduct(color);
													}}></li>
											))}
										</ul>
									</div>
									<div>
										<AddNewColor HandleRender={HandleRender} />
									</div>
								</Col>

								{selectedProduct === null ? (
									""
								) : (
									<>
										<hr />
										<Row>
											<Col className="d-flex align-items-center justify-content-between">
												<h2
													style={{
														textDecoration: "underline",
														textUnderlinePosition: "under",
													}}>
													تفاصيل اللون
												</h2>

												<div>
													<i
														onClick={() => {
															if (
																window.confirm(
																	`هل تريد حذف اللون (${selectedProduct.color_ar}) نهائياََ؟`
																)
															) {
																axiosInstance
																	.delete(
																		`/admin/product/${productID}/delete-product-line/${selectedProduct.id}`
																	)
																	.then((res) => {
																		if (res.status === 204) {
																			toast.success("تم الحذف بنجاح");
																		}
																		setRender(!render);
																	});
															}
														}}
														className="fa fa-trash"
														style={{
															width: 35,
															fontSize: 20,
															padding: 11,
															color: "red",
															cursor: "pointer",
														}}></i>
												</div>
											</Col>
										</Row>

										<Col
											xl="12"
											className="d-flex align-items-center justify-content-between">
											<div style={{ fontSize: "20px" }}>
												{selectedProduct?.color_ar} - {selectedProduct?.color_en}
											</div>
											<div>
												<div className="d-flex align-items-center">
													<h2>الكمية</h2>
													<AddQuantity
														colorData={selectedProduct}
														HandleRenderColor={HandleRenderColor}
													/>
													<RemoveQuantity
														colorData={selectedProduct}
														HandleRenderColor={HandleRenderColor}
													/>
												</div>
												<p>{selectedProduct?.stock_quantity}</p>
											</div>
											<div>
												<h2>تحذير</h2>
												{selectedProduct?.alert_stock_quantity}
											</div>
											<div>
												<EditColor
													HandleRenderColor={HandleRenderColor}
													colorData={selectedProduct}
												/>
											</div>
										</Col>
										<hr />

										<Row className="mb-3">
											<h2
												style={{
													textDecoration: "underline",
													textUnderlinePosition: "under",
												}}>
												الصور
											</h2>
										</Row>
										<Row>
											<Col
												xl="12"
												className="d-flex align-items-center justify-content-between">
												{/* add img render */}
												<div>
													<h3>اضافة صورة جديدة</h3>
													<AddImg
														colorData={selectedProduct}
														HandleRenderColorImage={HandleRenderColorImage}
													/>
												</div>
												<div>
													<h3>عرض و حذف صورة</h3>
													{selectedImage === null ? (
														""
													) : (
														<DeleteImage
															colorData={selectedProduct}
															selectedImage={selectedImage}
															HandleRenderColor={HandleRenderColor}
														/>
													)}
												</div>
											</Col>
										</Row>
									</>
								)}
							</div>
						</Col>
					</Row>
				</Card>
			</Container>
		</Fragment>
	);
};

export default Product_detail;
