import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";

import { Card, CardBody, Col, Container, Row, Label } from "reactstrap";
import { Badge } from "primereact/badge";
import axiosInstance from "../axios";
import { ToastContainer, toast } from "react-toastify";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
const SwitchOff = () => {
	const [maintenance, setMaintenance] = useState(false);
	const [freeShipping, setFreeShipping] = useState(false);
	const [render, setRender] = useState(false);
	const [selectedSeason, setSelectedSeason] = useState(null);

	const seasons = [
		{ name: "شتوى", code: "winter" },
		{ name: "صيفى", code: "summer" },
	];

	useEffect(() => {
		axiosInstance.get(`/admin/general-settings`).then((res) => {
			setMaintenance(res.data.is_maintenance);
			setFreeShipping(res.data.is_free_shipping);
			if (res.data.season === "winter") {
				setSelectedSeason({ name: "شتوى", code: "winter" });
			} else {
				setSelectedSeason({ name: "صيفى", code: "summer" });
			}
		});
	}, [render]);

	function switchOnOffMaintenance(params) {
		if (window.confirm(`هل انت متاكد من غيير حالة الموقع من ${1} الى ${2}`)) {
			const id = toast.loading("Please wait...");
			axiosInstance
				.patch(`/admin/general-settings`, {
					is_maintenance: !maintenance,
				})
				.then((res) => {
					setRender(!render);
					toast.update(id, {
						render: "تم الحفظ بنجاح",
						type: "success",
						isLoading: false,
						autoClose: 1000,
					});
				});
		}
	}
	function freeShippingOnOff(params) {
		if (window.confirm(`هل انت متاكد من غيير حالة الموقع من ${1} الى ${2}`)) {
			const id = toast.loading("Please wait...");
			axiosInstance
				.patch(`/admin/general-settings`, {
					is_free_shipping: !freeShipping,
				})
				.then((res) => {
					setRender(!render);
					toast.update(id, {
						render: "تم الحفظ بنجاح",
						type: "success",
						isLoading: false,
						autoClose: 1000,
					});
				});
		}
	}
	function SeasonSwitch(season) {
		if (window.confirm(`هل تريد تغيير عرض المنتجات الى الموسم ${season}`)) {
			const id = toast.loading("Please wait...");
			axiosInstance
				.patch(`/admin/general-settings`, {
					season: season,
				})
				.then((res) => {
					setRender(!render);
					toast.update(id, {
						render: "تم الحفظ بنجاح",
						type: "success",
						isLoading: false,
						autoClose: 1000,
					});
				});
		}
	}

	return (
		<Fragment>
			<ToastContainer rtl />
			<Breadcrumb title="اعدادات الموقع" />
			{/* <!-- Container-fluid starts--> */}
			<Container fluid={true}>
				<Row>
					<Col sm="6">
						<Card>
							<CardBody className="">
								<div className="d-flex justify-content-between">
									<h2>حالة الموقع الحالية</h2>

									{maintenance === false ? (
										<Badge value="Online" size="xlarge" severity="success"></Badge>
									) : (
										<Badge value="Offline" size="xlarge" severity="danger"></Badge>
									)}
								</div>
								<hr />
								<div>
									<InputSwitch
										checked={maintenance}
										onChange={(e) => switchOnOffMaintenance()}
									/>
									{maintenance === true ? (
										<div>تعطيل وضع الصيانة</div>
									) : (
										<div>تفعيل وضع الصيانة</div>
									)}
								</div>
							</CardBody>
						</Card>
					</Col>

					<Col sm="6">
						<Card>
							<CardBody className="">
								<div className="d-flex justify-content-between">
									<h2>شحن مجانى</h2>

									{freeShipping === false ? (
										<Badge value="غير مفعل" size="xlarge" severity="danger"></Badge>
									) : (
										<Badge value="مفعل" size="xlarge" severity="success"></Badge>
									)}
								</div>
								<hr />

								<InputSwitch
									checked={freeShipping}
									onChange={(e) => freeShippingOnOff()}
								/>
								{freeShipping === true ? (
									<div>تعطيل الشحن المجانى</div>
								) : (
									<div>تفعيل الشحن المجانى</div>
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col sm="6">
						<Card>
							<CardBody className="">
								<div className="d-flex justify-content-between">
									<h2>التحكم فى ظهور الموسم</h2>

									<Badge value={selectedSeason?.name} size="xlarge"></Badge>
								</div>
								<hr />

								<Label
									htmlFor="recipient-name"
									className="col-form-label"
									style={{ marginLeft: 15 }}>
									الموسم
								</Label>
								<Dropdown
									value={selectedSeason}
									onChange={(e) => {
										setSelectedSeason(e.value);
										console.log(e, "e");
										SeasonSwitch(e.value.code);
									}}
									options={seasons}
									optionLabel="name"
									placeholder="اختار الموسم"
									className="w-full md:w-14rem"
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			{/* <!-- Container-fluid Ends--> */}
		</Fragment>
	);
};

export default SwitchOff;
