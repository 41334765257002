import React from "react";

import {
	Button,
	Col,
	Form,
	Input,
	Label,
	Row,
	Container,
	Modal,
	ModalHeader,
	ModalBody,
} from "reactstrap";
import { useState } from "react";

import axiosInstance from "../axios";

import { ToastContainer, toast } from "react-toastify";

function AddBranch({ setRender1, render1 }) {
	const [formData, updateFormData] = useState("");
	const [open, setOpen] = useState(false);
	const onOpenModal = () => {
		setOpen(true);
	};

	const onCloseModal = () => {
		setOpen(false);
	};

	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		axiosInstance
			.post(`/admin/contact-us-main-page`, formData)
			.then((res) => {
				updateFormData(null);
				setRender1(!render1);
				onCloseModal();
				toast.success("تم الانشاء بنجاح");
			})
			.catch((err) => {
				if (err.response.status === 400) {
					toast.warning(`تاكد من البيانات المدخلة`);
				} else {
					toast.error(`${err.response.status} تاكد من البيانات المدخلة`);
				}
			});
	};
	return (
		<>
			<Container fluid={true}>
				<Row>
					<Col xl="12">
						<span
							style={{
								fontSize: 20,
								padding: 11,
								color: "rgb(40, 167, 69)",
							}}
							className="customCursor"
							onClick={(e) => {
								onOpenModal();
							}}>
							{" "}
							اضافة فرع جديد
							<i
								className="fa fa-pencil"
								style={{
									fontSize: 20,
									padding: 11,
									color: "rgb(40, 167, 69)",
								}}></i>
							<Modal
								isOpen={open}
								toggle={onCloseModal}
								style={{ overlay: { opacity: 0.1 } }}>
								<ModalHeader toggle={onCloseModal}>
									<h6 className="modal-title f-w-600" id="exampleModalLabel2">
										تعديل القسم
									</h6>
								</ModalHeader>
								<ModalBody>
									<Form
										className="theme-form"
										onSubmit={(e) => {
											handleSubmit(e);
										}}>
										<div>
											<Row className="m-2">
												<Col md="6">
													<Label className="form-label" for="old_password">
														اسم الفرع عربى
													</Label>
													<Input
														type="text"
														className="form-control"
														id="home-ploat"
														// placeholder="الرقم السرى القديم"

														name="title_ar"
														onChange={handleChange}
													/>
												</Col>
												<Col md="6">
													<Label className="form-label" for="old_password">
														اسم الفرع انجليزي
													</Label>
													<Input
														type="text"
														className="form-control"
														id="home-ploat"
														// placeholder="الرقم السرى القديم"
														name="title_en"
														onChange={handleChange}
													/>
												</Col>
											</Row>
											<Row className="m-2">
												<Col md="6">
													<Label className="form-label" for="old_password">
														رقم التلفون 1
													</Label>
													<Input
														type="text"
														className="form-control"
														id="home-ploat"
														// placeholder="الرقم السرى القديم"
														name="phone_1"
														onChange={handleChange}
													/>
												</Col>
												<Col md="6">
													<Label className="form-label" for="old_password">
														2
													</Label>
													<Input
														type="text"
														className="form-control"
														id="home-ploat"
														// placeholder="الرقم السرى القديم"
														name="phone_2"
														onChange={handleChange}
													/>
												</Col>
												<Col md="6">
													<Label className="form-label" for="old_password">
														رقم التلفون 3
													</Label>
													<Input
														type="text"
														className="form-control"
														id="home-ploat"
														// placeholder="الرقم السرى القديم"
														name="phone_3"
														onChange={handleChange}
													/>
												</Col>
												<Col md="6">
													<Label className="form-label" for="old_password">
														رقم التلفون 4
													</Label>
													<Input
														type="text"
														className="form-control"
														id="home-ploat"
														// placeholder="الرقم السرى القديم"
														name="phone_4"
														onChange={handleChange}
													/>
												</Col>
												<Col md="6">
													<Label className="form-label" for="old_password">
														رقم التلفون 5
													</Label>
													<Input
														type="text"
														className="form-control"
														id="home-ploat"
														// placeholder="الرقم السرى القديم"
														name="phone_5"
														onChange={handleChange}
													/>
												</Col>
											</Row>
											<Row className="m-2">
												<Col md="6">
													<Label className="form-label" for="old_password">
														العنوان
													</Label>
													<Input
														type="text"
														className="form-control"
														id="home-ploat"
														// placeholder="الرقم السرى القديم"
														name="address"
														onChange={handleChange}
													/>
												</Col>
												<Col md="6">
													<Label className="form-label" for="old_password">
														الخريطة
													</Label>
													<Input
														className="form-control"
														id="home-ploat"
														// placeholder="الرقم السرى القديم"
														name="google_map_src"
														onChange={handleChange}
														type="url"
													/>
												</Col>
											</Row>
										</div>
										<div className="col-md-12 mt-3 d-flex justify-content-between">
											<Button className="btn-sm " type="submit" color="secondary">
												حفظ الفرع
											</Button>
											<Button
												type="button"
												color="primary"
												onClick={() => onCloseModal("")}>
												اغلاق
											</Button>
										</div>
									</Form>
								</ModalBody>
							</Modal>
						</span>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default AddBranch;
