import React, { useEffect, useState } from "react";
import axiosInstance from "../axios";
import { toast } from "react-toastify";
import { ProgressBar } from "primereact/progressbar";
import { Dropdown } from "primereact/dropdown";
import {
	Button,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ButtonGroup,
} from "reactstrap";
function AddMainProduct({ HandleRender }) {
	const [open, setOpen] = useState(false);
	const [category, setCategory] = useState([]);
	const [formData, updateFormData] = useState(null);
	const [selectedSeason, setSelectedSeason] = useState(null);
	const [selectedVideoType, setSelectedVideoType] = useState("no-video");

	useEffect(() => {
		axiosInstance.get(`/products/category`).then((res) => {
			setCategory(res.data);
		});
	}, []);
	const seasons = [
		{ name: "شتوى", code: "winter" },
		{ name: "صيفى", code: "summer" },
	];
	const videoTypes = [
		{ name: "لايوجد", code: "no-video" },
		{ name: "لينك", code: "video-url" },
		{ name: "رفع فيديو", code: "video-upload" },
	];
	const onOpenModal = () => {
		setOpen(true);
	};

	const onCloseModal = () => {
		setOpen(false);
	};
	function handleChangeCategory(e) {
		updateFormData({
			...formData,
			// Trimming any whitespace
			category_id: e.target.value,
		});
	}

	const [video, setVideo] = useState(null);

	const [image, setImage] = useState(null);
	const [progressValue, setProgressValue] = useState(0);
	const [progressVideoValue, setProgressVideoValue] = useState(0);
	const config = {
		headers: { "Content-Type": "multipart/form-data" },
		onUploadProgress: function (progressEvent) {
			var percentCompleted = Math.round(
				(progressEvent.loaded * 100) / progressEvent.total
			);
			setProgressValue(percentCompleted);
			setProgressVideoValue(percentCompleted);
		},
	};

	const handleSubmit = (e) => {
		if (formData !== null || image !== null) {
			const formData1 = new FormData();
			formData1.append("name_ar", formData?.name_ar);
			formData1.append("name_en", formData?.name_en);
			formData1.append("description_ar", formData?.description_ar);
			formData1.append("description_en", formData?.description_en);
			formData1.append("price", formData?.price);
			if (formData?.discount) {
				formData1.append("discount", formData?.discount);
			}
			formData1.append("category_id", formData?.category_id);
			if (image) {
				formData1.append("url", image);
			}

			if (formData?.video_upload) {
				formData1.append("video_upload", video);
			}

			if (formData?.video_url) {
				formData1.append("video_url", formData?.video_url);
			}

			formData1.append("is_deleted", true);
			formData1.append("season", formData?.season);
			formData1.append("video_type", formData?.video_type || "no-video");
			console.log(formData1, "formData1");
			console.log(formData, "formData1");

			e.preventDefault();
			axiosInstance
				.post(`/products/product`, formData1, config)
				.then((res) => {
					setProgressValue(0);
					updateFormData(null);
					onCloseModal("VaryingMdo");
					HandleRender();
					toast.success("تم الانشاء بنجاح");
				})
				.catch((err) => {
					setProgressValue(0);
					if (err.response.status === 400) {
						toast.warning(`تاكد من البيانات المدخلة`);
					} else {
						toast.error(`${err.response.status} تاكد من البيانات المدخلة`);
					}
				});
		} else {
			toast.warning("تاكد من البيانات المدخلة");
		}
	};
	const handleChange = (e) => {
		updateFormData({
			...formData,
			// Trimming any whitespace
			[e.target.name]: e.target.value,
		});
	};

	const handleChangeImage = (e) => {
		setImage(e.target.files[0]);
		// Trimming any whitespace
	};
	const handleChangeVideo = (e) => {
		setVideo(e.target.files[0]);
		// Trimming any whitespace
	};
	const handleChangeSeason = (e) => {
		updateFormData({
			...formData,
			// Trimming any whitespace
			season: e,
		});
	};
	const handleChangeVideoType = (e) => {
		updateFormData({
			...formData,
			// Trimming any whitespace
			video_type: e,
		});
	};

	return (
		<>
			<div className="btn-popup pull-right">
				<Button
					type="button"
					color="primary"
					onClick={onOpenModal}
					data-toggle="modal"
					data-original-title="test"
					data-target="#exampleModal">
					إضافة صنف جديد
				</Button>
				<Modal isOpen={open} toggle={onCloseModal}>
					<ModalHeader toggle={onCloseModal}>
						<h5 className="modal-title f-w-600" id="exampleModalLabel2">
							إضافة صنف جديد
						</h5>
					</ModalHeader>
					<ModalBody>
						<Form>
							<FormGroup>
								<Label for="exampleSelect">اختر</Label>
								<Input
									id="exampleSelect"
									name="select"
									type="select"
									onChange={(e) => {
										handleChangeCategory(e);
									}}>
									<option selected={true} disabled="disabled">
										برجاء اختيار قسم
									</option>
									{category.map((cat) => (
										<option key={cat.id} value={cat.id}>
											{cat.name_ar}
										</option>
									))}
								</Input>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="name_ar" className="col-form-label">
									الاسم عربى :
								</Label>
								<Input
									id="name_ar"
									type="text"
									name="name_ar"
									className="form-control"
									onChange={handleChange}
								/>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="name_en" className="col-form-label">
									الاسم انجليزي :
								</Label>
								<Input
									id="name_en"
									type="text"
									name="name_en"
									className="form-control"
									onChange={handleChange}
								/>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="description_ar" className="col-form-label">
									الوصف عربى :
								</Label>
								<Input
									id="description_ar"
									type="text"
									name="description_ar"
									className="form-control"
									onChange={handleChange}
								/>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="description_en" className="col-form-label">
									الوصف انجليزي :
								</Label>
								<Input
									id="description_en"
									type="text"
									name="description_en"
									className="form-control"
									onChange={handleChange}
								/>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="price" className="col-form-label">
									السعر
								</Label>
								<Input
									id="price"
									type="text"
									name="price"
									className="form-control"
									onChange={handleChange}
								/>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="discount" className="col-form-label">
									الخصم
								</Label>
								<Input
									id="discount"
									type="number"
									name="discount"
									step={1}
									min={0}
									max={100}
									className="form-control"
									onChange={handleChange}
								/>
							</FormGroup>
							<FormGroup>
								<Label
									htmlFor="recipient-name"
									className="col-form-label"
									style={{ marginLeft: 15 }}>
									الموسم
								</Label>
								<Input
									id="exampleSelect"
									name="select"
									type="select"
									onChange={(e) => {
										console.log(e, "e");
										setSelectedSeason(e.target.value);
										handleChangeSeason(e.target.value);
									}}>
									<option selected={true} disabled="disabled">
										برجاء اختيار موسم
									</option>
									{seasons.map((seasons) => (
										<option key={seasons.id} value={seasons.code}>
											{seasons.name}
										</option>
									))}
								</Input>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="validationCustom02" className="col-form-label">
									الصورة الرئيسية للمنتج
								</Label>
								<Input
									name="url"
									className="form-control"
									id="validationCustom02"
									type="file"
									onChange={handleChangeImage}
								/>
							</FormGroup>
							<div className="card">
								<ProgressBar color="secondary" value={progressValue}></ProgressBar>
							</div>
							<FormGroup>
								<Label
									htmlFor="recipient-name"
									className="col-form-label"
									style={{ marginLeft: 15 }}>
									نوع الفيديو
								</Label>
								<Input
									id="videoType"
									name="video_type"
									type="select"
									onChange={(e) => {
										console.log(e.target.value, "e.target");
										setSelectedVideoType(e.target.value);
										handleChangeVideoType(e.target.value);
									}}>
									<option selected={true} disabled="disabled">
										برجاء اختيار نوع
									</option>
									{videoTypes.map((video) => (
										<option key={video.id} value={video.code}>
											{video.name}
										</option>
									))}
								</Input>
							</FormGroup>
							<FormGroup>
								{selectedVideoType === "video-url" ? (
									<Input
										id="video"
										type="text"
										name="video_url"
										className="form-control"
										onChange={handleChange}
									/>
								) : selectedVideoType === "video-upload" ? (
									<>
										<Input
											id="video"
											type="file"
											name="video_upload"
											className="form-control"
											onChange={handleChangeVideo}
										/>
										<div className="card">
											<ProgressBar
												color="secondary"
												value={progressVideoValue}></ProgressBar>
										</div>
									</>
								) : null}
							</FormGroup>
						</Form>
					</ModalBody>
					<ModalFooter className="d-flex justify-content-between">
						<Button
							type="button"
							color="secondary"
							onClick={(e) => {
								handleSubmit(e);
							}}>
							حفظ
						</Button>
						<Button
							type="button"
							color="primary"
							onClick={() => onCloseModal("VaryingMdo")}>
							إغلاق
						</Button>
					</ModalFooter>
				</Modal>
			</div>
			<div className="clearfix"></div>
		</>
	);
}

export default AddMainProduct;
