import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import axiosInstance from "../../axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import ExportComponent from "./exportComponent";
import { Col, Label } from "reactstrap";
import { Calendar } from "primereact/calendar";

import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

// import { ProductService } from "./service/ProductService";

export default function DataTableCount() {
	const [loading, setLoading] = useState(false);
	const [products, setProducts] = useState([]);
	const [totals, setTotals] = useState({});
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [selectedSort, setSelectedSort] = useState({
		name: "السعر اقل الى الاكثر",
		sort: "ascending",
		sort_by: "total_price",
	});
	const navigate = useNavigate();

	const [startDateString, setStartDateString] = useState(null);
	const [endDateString, setEndDateString] = useState(null);

	function searchFunction() {
		if (selectedSort && startDateString && endDateString) {
			let sortParams = "";
			if (selectedSort) {
				sortParams = `&sort=${selectedSort.sort}&sort_by=${selectedSort.sort_by}`;
			}

			setLoading(true);
			axiosInstance
				.get(
					`/report/product-count-report?${sortParams}${startDateString}${endDateString}`
				)
				.then((res) => {
					setProducts(res.data.results);
					setTotals(res.data.totals);
					setLoading(false);
				});
		} else {
			alert("يجب اختيار تاريخ البداية والنهاية والترتيب");
		}
	}

	const [filterCreatedAtStartDate, setFilterCreatedAtStartDate] = useState(null);
	const [filterCreatedAtEndDate, setFilterCreatedAtEndDate] = useState(null);
	const onCreatedAtStartDateChange = (e) => {
		setFilterCreatedAtStartDate(e.target.value);
		let startDate = new Date(e.target.value);

		const offset = startDate.getTimezoneOffset();
		startDate = new Date(startDate.getTime() - offset * 60 * 1000);
		startDate = startDate.toISOString().substring(0, 10);

		setStartDateString(`&start_date=${startDate}T00:00:00.00`);
	};

	const onCreatedAtEndDateChange = (e) => {
		setFilterCreatedAtEndDate(e.target.value);

		let endDate = new Date(e.target.value);
		const offsetEnd = endDate.getTimezoneOffset();
		endDate = new Date(endDate.getTime() - offsetEnd * 60 * 1000);
		endDate = endDate.toISOString().substring(0, 10);

		// searchParams.set("end_date", `${endDate}T23:59:59.99`);
		setEndDateString(`&end_date=${endDate}T23:59:59.99`);
	};

	const sortItems = [
		{ name: "السعر اقل الى الاكثر", sort: "ascending", sort_by: "total_price" },
		{ name: "السعر اكثر الى الاقل", sort: "descending", sort_by: "total_price" },
		{
			name: "الكمية اقل الى الاكثر",
			sort: "ascending",
			sort_by: "total_quantity",
		},
		{
			name: "الكمية اكثر الى الاقل",
			sort: "descending",
			sort_by: "total_quantity",
		},
	];

	const header = (
		<>
			<div className="d-flex justify-content-between align-items-center flex-wrap gap-2">
				<div>
					<div className="d-flex flex-column">
						<Label htmlFor="calendar-24h" className="font-bold block mb-2">
							تاريخ بداية
						</Label>
						<Calendar
							value={filterCreatedAtStartDate}
							onChange={onCreatedAtStartDateChange}
							type="date"
						/>
					</div>
					<div className="d-flex flex-column mt-2">
						<Label htmlFor="calendar-24h" className="font-bold block mb-2">
							تاريخ نهاية
						</Label>
						<Calendar
							value={filterCreatedAtEndDate}
							onChange={onCreatedAtEndDateChange}
							type="date"
						/>
					</div>
				</div>

				<div className="d-flex flex-column ">
					<Label htmlFor="calendar-24h" className="font-bold block mb-2">
						ترتيب حسب
					</Label>
					<Dropdown
						value={selectedSort}
						onChange={(e) => setSelectedSort(e.value)}
						options={sortItems}
						optionLabel="name"
						placeholder="فلتر"
						className="w-full md:w-14rem"
					/>
					<Button label="بحث" className="p-button-text" onClick={searchFunction} />
				</div>
				<div className="d-flex">
					<ExportComponent
						products={products}
						startDate={startDateString}
						endDate={endDateString}
					/>
				</div>
			</div>
		</>
	);
	const footer = (
		<>
			<Col className="d-flex justify-content-between">
				<div>
					<h3>اجمالى سعر البيع</h3>
					{totals?.total_price_sum}
				</div>
				<div>
					<h3>اجمالى كميات مباعة</h3>
					{totals?.total_quantity_sum}
				</div>
			</Col>
		</>
	);

	return (
		<div className="card">
			<div className="flex justify-content-center align-items-center mb-4 gap-2"></div>
			<DataTable
				footer={footer}
				header={header}
				value={products}
				selectionMode="single"
				selection={selectedProduct}
				onSelectionChange={(e) => setSelectedProduct(e.value)}
				dataKey="id"
				rowHover
				loading={loading}
				showGridlines
				responsiveLayout="scroll"
				onRowClick={(e) => navigate(`/reports/product-count/${e.data.id}`)}>
				<Column
					field="id"
					header="كود الصنف"
					style={{ textAlign: "right" }}></Column>
				<Column
					field="product_detail_name_ar"
					header="اسم الصنف"
					style={{ textAlign: "right" }}></Column>
				<Column
					field="total_price"
					header="اجمالى السعر"
					style={{ textAlign: "right" }}></Column>
				<Column
					field="total_quantity"
					header="اجمالى الكمية \ متر"
					style={{ textAlign: "right" }}></Column>
			</DataTable>
		</div>
	);
}
