import React from "react";
import { useState } from "react";
import {
	Button,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "reactstrap";
import axiosInstance from "../../axios";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function AddNewColor({ HandleRender }) {
	const [open, setOpen] = useState(false);
	const [formData, updateFormData] = useState({ color_value: "#000000" });
	const { productID } = useParams();
	const onOpenModal = () => {
		setOpen(true);
	};

	const onCloseModal = () => {
		setOpen(false);
	};
	const handleChange = (e) => {
		updateFormData({
			...formData,
			// Trimming any whitespace
			[e.target.name]: e.target.value,
		});
	};

	let sendingData = false;
	const handleSubmit = (e) => {
		e.preventDefault();
		if (
			parseFloat(formData.stock_quantity) <=
			parseFloat(formData.alert_stock_quantity)
		) {
			toast.warning("يجب ان تكون كمية التحذير افل من الكمية المتاحة");
		} else {
			if (sendingData === false) {
				sendingData = true;
				axiosInstance
					.post(`/products/product/${productID}/product-line`, formData)
					.then((res) => {
						updateFormData({ color_value: "#000000" });
						sendingData = false;
						onCloseModal("VaryingMdo");
						HandleRender();
						if (res.status === 200) {
							toast.success("تم الاضافة بنجاح");
						}
					})
					.catch((err) => {
						sendingData = false;
						if (err.response.status === 400) {
							toast.warning(`تاكد من البيانات المدخلة`);
						} else {
							toast.error(`${err.response.status} تاكد من البيانات المدخلة`);
						}
					});
			}
		}
	};
	return (
		<>
			<Button color="primary" onClick={onOpenModal}>
				اضافة لون جديد
				<i className="fa fa-plus"></i>
			</Button>
			<Modal isOpen={open} toggle={onCloseModal}>
				<ModalHeader toggle={onCloseModal}>
					<div className="modal-title f-w-600" id="exampleModalLabel2">
						اضافة
					</div>
				</ModalHeader>
				<ModalBody>
					<Form>
						<FormGroup>
							<Label htmlFor="recipient-name" className="col-form-label">
								اللون عربى :
							</Label>
							<Input
								type="text"
								name="color_ar"
								className="form-control"
								onChange={handleChange}
							/>
						</FormGroup>
						<FormGroup>
							<Label htmlFor="recipient-name" className="col-form-label">
								اللون انجليزي :
							</Label>
							<Input
								type="text"
								name="color_en"
								className="form-control"
								onChange={handleChange}
							/>
						</FormGroup>
						<FormGroup>
							<Label htmlFor="message-text" className="col-form-label">
								لون :
							</Label>
							<Input
								name="color_value"
								// className="form-control"
								id="validationCustom02"
								type="color"
								onChange={handleChange}
							/>
						</FormGroup>
						<FormGroup>
							<Label htmlFor="recipient-name" className="col-form-label">
								الكمية :
							</Label>
							<Input
								type="number"
								name="stock_quantity"
								className="form-control"
								onChange={handleChange}
							/>
						</FormGroup>
						<FormGroup>
							<Label htmlFor="recipient-name" className="col-form-label">
								تحذير الكمية :
							</Label>
							<Input
								type="text"
								name="alert_stock_quantity"
								className="form-control"
								onChange={handleChange}
							/>
						</FormGroup>
					</Form>
				</ModalBody>
				<ModalFooter className="d-flex justify-content-between">
					<Button
						type="button"
						color="secondary"
						onClick={(e) => {
							handleSubmit(e);
						}}>
						اضافة
					</Button>
					<Button
						type="button"
						color="primary"
						onClick={() => onCloseModal("VaryingMdo")}>
						اغلاق
					</Button>
				</ModalFooter>
			</Modal>
			<ToastContainer autoClose={2000} rtl />
		</>
	);
}

export default AddNewColor;
