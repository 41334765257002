import { Button } from "primereact/button";
import React, { useState } from "react";
import "../../../../assets/fonts/Amiri-Regular-normal";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import axiosInstance from "../../../axios";
import { useSearchParams } from "react-router-dom";

function ExCompOrderReport({ startDate, endDate }) {
	const [searchParams, setSearchParams] = useSearchParams();
	const cols = [
		{ dataKey: "payment_status", header: "حالة الدفع" },
		{ dataKey: "payment_method", header: "طريقة الدفع" },
		{ dataKey: "total_refund_price", header: "مرتجعات" },
		{
			dataKey: "total_price_after_discount",
			header: "اجمالى الطلب",
		},
		{ dataKey: "shipping_area", header: "مكان الشحن" },
		{ dataKey: "shipping_price", header: "سعر الشحن" },
		{ dataKey: "order_status", header: "حالة الطلب" },
		{ dataKey: "created_at", header: "التاريخ" },
		{ dataKey: "id", header: "الكود" },
	];

	async function getAllRecordsForCurrentFilter() {
		let tempData = [];
		let tempCountPerPage = 500;

		const response = await axiosInstance.get(
			`report/order-report?${searchParams.toString()}&page=${1}&page_size=${1}`
		);
		let totalCountFromApi = response.data.count;
		let pageCount = Math.ceil(totalCountFromApi / tempCountPerPage);

		let totals = response.data.totals;
		for (let i = 1; i <= pageCount; i++) {
			const res2 = await axiosInstance.get(
				`report/order-report?${searchParams.toString()}&page=${i}&page_size=${tempCountPerPage}`
			);
			console.log(res2, "res2");

			tempData.push(...res2.data.results);
			console.log(tempData, "tempData");
		}
		return { tempData: tempData, totals: totals };
	}

	async function exportPdf2() {
		const doc = new jsPDF("landscape");

		doc.setFont("Amiri-Regular");
		doc.setTextColor(155, 34, 38);
		doc.setFontSize(14);
		const width = doc.getTextWidth("تقرير مبيعات مجمع");
		console.log(width);

		doc.text("تقرير مبيعات مجمع", width / 2 + 150, 10, "right");
		doc.text("-----------------", width / 2 + 150, 15, "right");

		doc.setTextColor(3, 4, 94);
		doc.setFontSize(14);
		doc.text(
			`من تاريخ ${
				new Date(
					new Date(searchParams.get("start_date").split("T")[0]).setDate(
						new Date(searchParams.get("start_date").split("T")[0]).getDate() + 1
					)
				)
					.toISOString()
					.split("T")[0]
			}`,
			280,
			25,
			"right"
		);
		doc.text(
			`الى تاريخ ${searchParams.get("end_date").split("T")[0]}`,
			230,
			25,
			"right"
		);
		var totalPagesExp = "{total_pages_count_string}";
		let temp = await getAllRecordsForCurrentFilter();
		console.log(temp, "temp");
		let tempProducts = temp.tempData;
		console.log(tempProducts, "tempProducts");
		let tempTotals = temp.totals;
		console.log(tempTotals, "tempTotals");

		let totalSales = tempTotals?.total_every_thing;
		let totalRefund = tempTotals?.total_refund_price;
		let totalDiscount = tempTotals?.total_promo_code_discount;
		let totalWithShipping =
			parseFloat(tempTotals?.total_every_thing) -
			parseFloat(tempTotals?.total_refund_price) -
			parseFloat(tempTotals?.total_promo_code_discount);
		let totalShipping = tempTotals?.total_shipping_price;
		let totalNetSales = tempTotals?.total_items_price;

		let totalSalesPaid = tempTotals?.total_items_price_paid;
		let totalShippingPaid = tempTotals?.total_shipping_price_paid;
		let totalPaid =
			parseFloat(tempTotals?.total_items_price_paid) +
			parseFloat(tempTotals?.total_shipping_price_paid);

		let totalSalesUnPaid = tempTotals?.total_items_price_unpaid;
		let totalShippingUnPaid = tempTotals?.total_shipping_price_unpaid;
		let totalUnPaid =
			parseFloat(tempTotals?.total_shipping_price_unpaid) +
			parseFloat(tempTotals?.total_items_price_unpaid);

		tempProducts.push({
			id: "اجمالى",
			order_status: "",
			created_at: "",
			shipping_price: tempTotals.total_shipping_price,
			shipping_area: "",
			total_price_after_discount: tempTotals.total_price_after_discount,
			payment_method: "",
			payment_status: "",
		});
		console.log(tempProducts, "tempProducts 2");

		let pageWidth =
			doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
		let distanceFromRight = 190;
		// first column
		doc.setFontSize(12);
		doc.text(`اجمالى مبيعات`, 280, 35, "right");
		doc.text(`${totalSales}`, 200, 35, "left");
		doc.text(`اجمالى مرتجع (-)`, 280, 45, "right");
		doc.text(`${totalRefund}`, 200, 45, "left");
		doc.text(`خصم (-)`, 280, 55, "right");
		doc.text(`${totalDiscount}`, 200, 55, "left");

		doc.setLineWidth(0.2);
		doc.line(200, 60, 280, 60);
		doc.text(`اجمالى بالشحن`, 280, 65, "right");
		doc.text(`${totalWithShipping}`, 200, 65, "left");

		doc.text(`اجمالى الشحن (-)`, 280, 75, "right");
		doc.text(`${totalShipping}`, 200, 75, "left");

		doc.setLineWidth(0.2);
		doc.line(200, 80, 280, 80);
		doc.text(`صافى مبيعات`, 280, 85, "right");
		doc.text(`${totalNetSales}`, 200, 85, "left");

		// first column

		// second column

		doc.text(`صافى مبيعات محصل`, 190, 35, "right");
		doc.text(`${totalSalesPaid}`, 112, 35, "left");

		doc.text(`اجمالى الشحن محصل (+)`, 190, 45, "right");
		doc.text(`${totalShippingPaid}`, 112, 45, "left");

		doc.setLineWidth(0.2);
		doc.line(112, 50, 190, 50);
		doc.text(`اجمالى محصل`, 190, 55, "right");
		doc.text(`${totalPaid}`, 112, 55, "left");

		// second column

		// third column
		doc.text(`صافى مبيعات غير محصل`, 102, 35, "right");
		doc.text(`${totalSalesUnPaid}`, 17, 35, "left");

		doc.text(`اجمالى الشحن غير محصل (+)`, 102, 45, "right");
		doc.text(`${totalShippingUnPaid}`, 17, 45, "left");

		doc.setLineWidth(0.2);
		doc.line(17, 50, 102, 50);
		doc.text(`اجمالى غير محصل`, 102, 55, "right");
		doc.text(`${totalUnPaid}`, 17, 55, "left");

		// third column

		autoTable(doc, {
			startY: 95,
			body: tempProducts,
			columns: cols,
			styles: {
				halign: "center",
				valign: "middle",
			},

			rowPageBreak: "auto",
			columnStyles: {
				0: { minCellWidth: 10 },
				1: { minCellWidth: 10 },
				2: { minCellWidth: 10 },
				3: { minCellWidth: 10 },
				4: { minCellWidth: 10 },
				5: { minCellWidth: 10 },
				6: { minCellWidth: 10 },
				7: { minCellWidth: 10 },
				// etc
			},
			didParseCell: function (data) {
				if (data.column.dataKey === "created_at") {
					data.cell.text = data.cell.text[0].split(".")[0].replace("T", " ");
				}
			},
			headStyles: {
				font: "Amiri-Regular",
				fontSize: 13,
				// fillColor: [51, 173, 255],
				fillColor: [3, 4, 94],
			}, // For Arabic text in the table head
			bodyStyles: { font: "Amiri-Regular" }, // For Arabic text in the table body
			// margin: { top: 40 },
			didDrawPage: (data) => {
				var str = "Page " + doc.internal.getNumberOfPages();

				// Total page number plugin only available in jspdf v1.0+
				if (typeof doc.putTotalPages === "function") {
					str = str + " of " + totalPagesExp;
				}
				doc.setFontSize(10);

				var pageSize = doc.internal.pageSize;
				var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
				doc.text(str, data.settings.margin.left, pageHeight - 10);
			},
		});

		let finalY = doc.previousAutoTable.finalY;

		// doc.text(
		//     "لوريم إيبسوم هو ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس",
		//     pageWidth - distanceFromRight ,
		//     20,
		//     "right"
		// );

		if (typeof doc.putTotalPages === "function") {
			doc.putTotalPages(totalPagesExp);
		}

		doc.save("تقرير مبيعات.pdf");
		doc.close();
	}
	function FilteringData(DataArray) {
		let temp = [];
		for (let i = 0; i < DataArray.length; i++) {
			temp.push({
				الكود: DataArray[i].id,
				التاريخ: DataArray[i].created_at.split(".")[0].replace("T", " "),
				"حالة الطلب": DataArray[i].order_status,
				"سعر الشحن": DataArray[i].shipping_price,
				"مكان الشحن": DataArray[i].shipping_area,
				"اجمالى الطلب": DataArray[i].total_price_after_discount,
				مرتجعات: DataArray[i].total_refund_price,
				"طريقة الدفع": DataArray[i].payment_method,
				"حالة الدفع": DataArray[i].payment_status,
			});
		}
		return temp;
	}
	async function exportExcel() {
		const allExcelData = await getAllRecordsForCurrentFilter();
		let excelData = FilteringData(allExcelData.tempData);
		import("xlsx").then((xlsx) => {
			const worksheet = xlsx.utils.json_to_sheet(excelData);
			const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
			const excelBuffer = xlsx.write(workbook, {
				bookType: "xlsx",
				type: "array",
			});
			saveAsExcelFile(excelBuffer, "تقرير مبيعات");
		});
	}
	const saveAsExcelFile = (buffer, fileName) => {
		import("file-saver").then((module) => {
			if (module && module.default) {
				let EXCEL_TYPE =
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
				let EXCEL_EXTENSION = ".xlsx";
				const data = new Blob([buffer], {
					type: EXCEL_TYPE,
				});

				module.default.saveAs(
					data,
					fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
				);
			}
		});
	};

	return (
		<>
			<div className="d-flex gap-2">
				<Button
					type="button"
					icon="pi pi-file-excel"
					onClick={exportExcel}
					className="p-button-success mr-2 p-button-outlined"
					tooltip="XLS"
					tooltipOptions={{ position: "bottom" }}
				/>
				<Button
					type="button"
					icon="pi pi-file-pdf"
					onClick={exportPdf2}
					className="p-button-danger mr-2 p-button-outlined"
					tooltip="PDF"
					tooltipOptions={{
						position: "bottom",
					}}></Button>
			</div>
		</>
	);
}

export default ExCompOrderReport;
