import React, { Fragment, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import axiosInstance from "../axios";
import Breadcrumb from "../common/breadcrumb";

import ParallaxDataTable from "../common/parallaxDataTable";

const ParallaxImage = () => {
	const [render, setRender] = useState(false);
	const [data, setData] = useState([]);
	useEffect(() => {
		axiosInstance.get(`/admin/main-page-parallax`).then((res) => {
			setData(res.data);
		});
	}, [render]);
	function HandleRender() {
		setRender(!render);
	}
	return (
		<Fragment>
			<Breadcrumb title="الصورة المتحرك" />
			{/* <!-- Container-fluid starts--> */}
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<div className="clearfix"></div>
								<div id="basicScenario" className="product-physical">
									<ParallaxDataTable
										myData={data}
										multiSelectOption={false}
										pageSize={10}
										pagination={true}
										class="-striped -highlight"
										HandleRender={HandleRender}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			{/* <!-- Container-fluid Ends--> */}
		</Fragment>
	);
};

export default ParallaxImage;
