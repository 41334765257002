import React, { Fragment, useEffect, useState } from "react";
import Notification from "./notification";
import UserMenu from "./user-menu";
import {
	AlignLeft,
	Maximize2,
	Bell,
	MoreHorizontal,
	AlertTriangle,
} from "react-feather";

//images
import logo from "../../../assets/images/dashboard/logo.png";
import axiosInstance from "../../axios";
import { useNavigate } from "react-router-dom";

const Header = ({ sidebar, setSidebar }) => {
	const navigate = useNavigate();
	// const [sidebar, setSidebar] = useState(false);
	const [navMenus, setNavMenus] = useState(false);
	const [filteredData, setFilteredData] = useState([]);
	const [totalCount, setTotalCount] = useState(null);
	const [stockAlert, setStockAlert] = useState([]);
	const toggle = () => {
		// setNavMenus((prevState) => ({
		// 	navMenus: !prevState.navMenus,
		// }));
		setNavMenus(!navMenus);
	};

	const goFull = () => {
		if (
			(document.fullScreenElement && document.fullScreenElement !== null) ||
			(!document.mozFullScreen && !document.webkitIsFullScreen)
		) {
			if (document.documentElement.requestFullScreen) {
				document.documentElement.requestFullScreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullScreen) {
				document.documentElement.webkitRequestFullScreen(
					Element.ALLOW_KEYBOARD_INPUT
				);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	};
	const openCloseSidebar = () => {
		if (sidebar) {
			setSidebar(false);
			document.querySelector(".page-main-header").classList.add("open");
			document.querySelector(".page-sidebar").classList.add("open");
			document.querySelector(".footer").classList.add("open");
		} else {
			setSidebar(true);
			document.querySelector(".page-main-header").classList.remove("open");
			document.querySelector(".page-sidebar").classList.remove("open");
			document.querySelector(".footer").classList.remove("open");
		}
	};
	useEffect(() => {
		axiosInstance
			.get(`report/order-report-2?order_status=pending&page_size=3`)
			.then((res) => {
				setFilteredData(res.data.results);
				setTotalCount(res.data.count);
			});
		axiosInstance.get(`report/notification/stock-alert`).then((res) => {
			setStockAlert(res.data);
		});
	}, []);
	//! change timer
	useEffect(() => {
		const interval = setInterval(() => {
			axiosInstance
				.get(`report/order-report?order_status=pending&page_size=3`)
				.then((res) => {
					setFilteredData(res.data.results);
					setTotalCount(res.data.count);
				});
		}, 60000);
		return () => clearInterval(interval);
	}, []);

	return (
		<Fragment>
			{/* open */}
			<div className="page-main-header open">
				<div className="main-header-right row">
					<div className="main-header-left d-lg-none col-auto">
						<div className="logo-wrapper">
							<a href="index.html">
								<img className="blur-up lazyloaded" src={logo} alt="" />
							</a>
						</div>
					</div>
					<div className="mobile-sidebar col-auto p-0">
						<div className="media-body text-end switch-sm">
							<label className="switch">
								<div className="customCursor" onClick={openCloseSidebar}>
									<AlignLeft />
								</div>
							</label>
						</div>
					</div>
					<div className="nav-right col">
						<ul className={"nav-menus " + (navMenus ? "open" : "")}>
							<li>
								<div onClick={goFull} className="text-dark customCursor">
									<Maximize2 />
								</div>
							</li>

							<li className="onhover-dropdown">
								<Bell />
								<span className="badge rounded-pill badge-primary pull-right notification-badge">
									{totalCount > 10 ? "9+" : totalCount}
									{/* {totalCount} */}
								</span>
								<span className="dot"></span>
								<Notification totalCount={totalCount} filteredData={filteredData} />
							</li>

							{stockAlert.count > 0 ? (
								<li
									className="onhover-dropdown"
									onClick={() => {
										navigate("/reports/stock-report/");
									}}>
									<AlertTriangle />
									<span className="badge rounded-pill badge-primary pull-right notification-badge">
										{stockAlert?.count}
									</span>
								</li>
							) : (
								""
							)}

							<UserMenu />
						</ul>
						<div
							className="d-lg-none mobile-toggle pull-right"
							onClick={() => toggle()}>
							<MoreHorizontal />
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Header;
