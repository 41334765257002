import React, { Fragment, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Dropdown } from "primereact/dropdown";

import axiosInstance from "../axios";
import Breadcrumb from "../common/breadcrumb";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import BestSellerDataTable from "../common/BestSellerDataTable";
import { toast } from "react-toastify";

import { Dialog } from "primereact/dialog";

const BestSeller = () => {
	const [render, setRender] = useState(false);
	const [data, setData] = useState([]);
	const [products, setProducts] = useState([{ name_ar: ", name_en: " }]);
	const [formData, updateFormData] = useState(null);

	const [selectedProduct, setSelectedProduct] = useState(null);

	useEffect(() => {
		axiosInstance.get(`/admin/main-page-best-seller`).then((res) => {
			setData(res.data);
		});
		axiosInstance.get(`/admin/product-list-for-dropdown`).then((res) => {
			setProducts(res.data);
		});
	}, [render]);

	const handleSubmit = (e) => {
		e.preventDefault();
		axiosInstance
			.post(`/admin/main-page-best-seller`, { product_id: selectedProduct.id })
			.then((res) => {
				updateFormData(null);
				setRender(!render);
				setVisible(false);
				toast.success("تم اضافة الصنف بنجاح");
			})
			.catch((err) => {
				console.error(err.response.data.error);
				if (err.response.data.error === "Product already exist") {
					toast.warning(`لا يمكن اختيار المنتج مرة اخرى`);
				} else {
					toast.error(`${err.response.status} تاكد من البيانات المدخلة`);
				}
			});
	};

	function HandleRender() {
		setRender(!render);
	}
	const [visible, setVisible] = useState(false);
	const footerContent = (
		<div className="d-flex justify-content-between">
			<Button
				type="button"
				color="primary"
				onClick={(e) => {
					handleSubmit(e);
				}}>
				حفظ
			</Button>
			<Button type="button" color="secondary" onClick={() => setVisible(false)}>
				X
			</Button>
		</div>
	);

	const countryOptionTemplate = (option) => {
		return (
			<div
				className="d-flex align-items-center justify-content-between"
				key={option.id}>
				<div>{option.name_ar}</div>
				<img
					alt={option?.name_ar}
					src={option?.thumbnail}
					// className={`mr-2 flag `}
					style={{ width: "50px" }}
				/>
			</div>
		);
	};
	return (
		<Fragment>
			<Breadcrumb title="الاكثر مبيعا" />
			{/* <!-- Container-fluid starts--> */}
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<div className="btn-popup pull-right">
									<Button
										type="button"
										color="primary"
										onClick={() => setVisible(true)}
										data-toggle="modal"
										data-original-title="test"
										data-target="#exampleModal">
										إضافة
									</Button>
									<Dialog
										footer={footerContent}
										header="اختر صنف جديد"
										visible={visible}
										style={{ minWidth: "70%" }}
										onHide={() => setVisible(false)}>
										<div className="d-flex justify-content-center m-3">
											<Dropdown
												className="m-2  w-100"
												value={selectedProduct}
												onChange={(e) => {
													setSelectedProduct(e.value);
												}}
												options={products}
												optionLabel="name_ar"
												placeholder="اختر"
												filter
												filterMatchMode="contains"
												itemTemplate={countryOptionTemplate}
											/>
										</div>
									</Dialog>
								</div>
								<div className="clearfix"></div>
								<div id="basicScenario" className="product-physical">
									<BestSellerDataTable
										myData={data}
										multiSelectOption={false}
										pageSize={10}
										pagination={true}
										class="-striped -highlight"
										HandleRender={HandleRender}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			{/* <!-- Container-fluid Ends--> */}
		</Fragment>
	);
};

export default BestSeller;
