import React, { Fragment } from "react";
// import Breadcrumb from "../../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import Breadcrumb from "../common/breadcrumb";
import DatatableOrderReportPrimeReact from "./components/datatableOrderReportPrimeReact";
import ExCompOrderReport from "./components/exportComponents/exCompOrder-report";

const OrderReport = () => {
	return (
		<Fragment>
			<Breadcrumb title="تقرير مبيعات خلال فترة" />
			{/* <!-- Container-fluid starts--> */}
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card className="p-4 ">
							<div className="d-flex justify-content-end align-items-center">
								<ExCompOrderReport />
							</div>
						</Card>
						<Card>
							<CardBody>
								<div>
									<DatatableOrderReportPrimeReact
										displayUser={true}
										displayPayStatus={true}
										displayOrderStatus={true}
										displayPaymentMethod={true}
										displayShippingArea={true}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			{/* <!-- Container-fluid Ends--> */}
		</Fragment>
	);
};

export default OrderReport;
