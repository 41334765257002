import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";

import TabsetTeam from "./tabset-About";
import AddTeamMember from "./AddTeamMember";

function AboutUs() {
	const [render1, setRender1] = useState(false);
	return (
		<>
			<Breadcrumb title="من نحن" parent="لوحة التحكم" />
			<Container fluid={true}>
				<Row>
					<Col xl="12">
						<Card className="profile-card">
							<AddTeamMember render1={render1} setRender1={setRender1} />
							<CardBody>
								<TabsetTeam render1={render1} setRender1={setRender1} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default AboutUs;
