import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
//images import
import man from "../../../assets/images/dashboard/man.png";
import { Button } from "reactstrap";

const UserMenu = () => {
	const navigate = useNavigate();
	return (
		<Fragment>
			<li className="onhover-dropdown">
				<div className="media align-items-center">
					<img
						className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
						src={man}
						alt="header-user"
					/>
					<div className="dotted-animation">
						<span className="animate-circle"></span>
						<span className="main-circle"></span>
					</div>
				</div>
				<ul className="profile-dropdown onhover-show-div p-15 profile-dropdown-hover">
					<li>
						<Link to={`${process.env.PUBLIC_URL}/settings/profile`}>
							<i data-feather="user"></i>الملف الشخصي
						</Link>
					</li>

					<li>
						<Button
							outline
							size="sm"
							onClick={() => {
								localStorage.clear();
								navigate("/auth/login");
							}}>
							<i data-feather="log-out"></i>تسجيل الخروج
						</Button>
					</li>
				</ul>
			</li>
		</Fragment>
	);
};

export default UserMenu;
