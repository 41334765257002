import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axiosInstance from "../axios";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Paginator } from "primereact/paginator";
import Breadcrumb from "../common/breadcrumb";
import { toast } from "react-toastify";
export default function Messages() {
	const [data, setData] = useState([]);
	const [render, setRender] = useState(false);
	const [expandedRows, setExpandedRows] = useState(null);

	const [first, setFirst] = useState([0, 0, 0]);
	const [rows, setRows] = useState([5]);
	const [countPerPage, setCountPerPage] = useState(10);
	const [totalCountFromApi, setTotalCountFromApi] = useState(null);
	const [nextPage, setNextPage] = useState(null);
	const [prevPage, setPrevPage] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		axiosInstance
			.get(`/admin/contact-us?page_size=${countPerPage}`)
			.then((res) => {
				console.log(res.data, "res");
				setData(res.data.results);

				setPrevPage(res.data.previous);
				setNextPage(res.data.next);
				setLoading(false);
				setTotalCountFromApi(res.data.count);
			});
	}, [render]);

	const rowExpansionTemplate = (data) => {
		return (
			<div className="p-3">
				<h5>{data.message}</h5>
				<Button
					size="small"
					onClick={() => {
						axiosInstance
							.patch(`/admin/contact-us/${data.id}`, { status: "read", is_read: true })
							.then((res) => {
								setExpandedRows(null);
								setRender(!render);
							})
							.catch((err) => {
								toast.error(`${err.response.status} حدث خطأ`);
							});
					}}>
					تغيير الحالة
				</Button>
			</div>
		);
	};
	const allowExpansion = (rowData) => {
		return rowData.message.length > 0;
	};

	const template1 = {
		layout: "PrevPageLink PageLinks NextPageLink RowsPerPageDropdown ",
		PrevPageLink: (options) => {
			return (
				<button
					className={classNames(options.className, "border-round")}
					type="button"
					onClick={(e) => {
						options.onClick(e);
						setLoading(true);
						axiosInstance
							.get(`/admin/contact-us?&page=${prevPage}&page_size=${countPerPage}`)

							.then((res) => {
								setData(res.data.results);
								setPrevPage(res.data.previous);
								setNextPage(res.data.next);
								setLoading(false);
								setTotalCountFromApi(res.data.count);
							});
					}}
					disabled={options.disabled}>
					<span className="p-3">Previous</span>
				</button>
			);
		},
		NextPageLink: (options) => {
			return (
				<button
					className={classNames(options.className, "border-round")}
					type="button"
					onClick={(e) => {
						// setCurrentPage(currentPage + 1);
						options.onClick(e);
						setLoading(true);
						axiosInstance
							.get(`/admin/contact-us?&page=${nextPage}&page_size=${countPerPage}`)
							// .get(`admin/get-test-user-list?page_size=100000`)
							.then((res) => {
								setData(res.data.results);

								setPrevPage(res.data.previous);
								setNextPage(res.data.next);
								setLoading(false);
								setTotalCountFromApi(res.data.count);
							});

						// e.target.disabled = true;
						// e.target.setAttribute("disabled", true);
					}}
					disabled={options.disabled}>
					<span className="p-3">Next</span>
				</button>
			);
		},
		PageLinks: (options) => {
			if (
				(options.view.startPage === options.page && options.view.startPage !== 0) ||
				(options.view.endPage === options.page &&
					options.page + 1 !== options.totalPages)
			) {
				const className = classNames(options.className, { "p-disabled": true });

				return (
					<span className={className} style={{ userSelect: "none" }}>
						...
					</span>
				);
			}

			return (
				<button
					type="button"
					className={options.className}
					onClick={(e) => {
						setLoading(true);
						options.onClick(e);

						axiosInstance
							.get(
								`/admin/contact-us?&page=${options.page + 1}&page_size=${countPerPage}`
							)

							.then((res) => {
								setData(res.data.results);

								setPrevPage(res.data.previous);
								setNextPage(res.data.next);
								setLoading(false);
								setTotalCountFromApi(res.data.count);
							});
					}}>
					{options.page + 1}
				</button>
			);
		},

		CurrentPageReport: (options) => {
			return (
				<span
					className="mx-3"
					style={{ color: "var(--text-color)", userSelect: "none" }}></span>
			);
		},
	};
	const onPageChange = (e, index) => {
		setFirst(first.map((f, i) => (index === i ? e.first : f)));
		setRows(rows.map((r, i) => (index === i ? e.rows : r)));
	};

	const footer = (
		<Paginator
			template={template1}
			first={first[0]}
			rows={countPerPage}
			totalRecords={totalCountFromApi}
			onPageChange={(e) => onPageChange(e, 0)}
		/>
	);

	const statusBodyTemplate = (order) => {
		return (
			<>
				<span className={`status-badge status-${order?.status.toLowerCase()}`}>
					{order?.status}
				</span>
			</>
		);
	};

	return (
		<>
			<Breadcrumb title="الرسائل" />

			<DataTable
				style={{ textAlign: "right" }}
				loading={loading}
				value={data}
				tableStyle={{ minWidth: "50rem" }}
				showGridlines
				rowExpansionTemplate={rowExpansionTemplate}
				expandedRows={expandedRows}
				onRowToggle={(e) => setExpandedRows(e.data)}
				dataKey="id"
				footer={footer}>
				<Column style={{ textAlign: "center" }} field="id" header="Code"></Column>
				<Column style={{ textAlign: "right" }} field="name" header="الاسم"></Column>
				<Column
					style={{ textAlign: "right" }}
					field="email"
					header="البريد الالكترونى"></Column>
				<Column
					style={{ textAlign: "right" }}
					field="phone"
					header="الموبايل"></Column>
				<Column
					style={{ textAlign: "right" }}
					field="status"
					header="الحالة"
					body={statusBodyTemplate}></Column>
				<Column
					header="قراءة"
					expander={allowExpansion}
					style={{ width: "5rem" }}
				/>
			</DataTable>
		</>
	);
}
