import React from "react";
import { Button } from "reactstrap";
import styled from "styled-components";

const TextField = styled.input`
	height: 32px;
	width: 200px;
	border-radius: 3px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;
	&:hover {
		cursor: pointer;
	}
`;

function FilterComponent({ filterText, onFilter, onClear }) {
	return (
		<>
			<div className="d-flex justify-content-center align-items-center">
				<TextField
					id="search"
					type="text"
					placeholder="ابحث بالاسم"
					aria-label="Search Input"
					value={filterText}
					onChange={onFilter}
				/>
				<Button
					color="primary"
					type="button"
					style={{
						height: "32px",
						width: "64px",
						borderTopRightRadius: "0",
						borderBottomRightRadius: "0",
						borderTopLeftRadius: "5px",
						borderBottomLeftRadius: "5px",
					}}
					onClick={onClear}>
					X
				</Button>
			</div>
		</>
	);
}

export default FilterComponent;
