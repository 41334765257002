import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Form,
	FormGroup,
	Input,
	Label,
	Row,
} from "reactstrap";
import PromoCodeDatatable from "./PromoCodeDatatable";
import { Dropdown } from "primereact/dropdown";
import axiosInstance from "../axios";
import { toast } from "react-toastify";
import Breadcrumb from "../common/breadcrumb";
function EditPromoCode({ data, setRender, render }) {
	console.log(data, "data");
	const [selectedType, setSelectedType] = useState(null);
	const [valuePercentage, setValuePercentage] = useState(0);
	const [valueAmount, setValueAmount] = useState(0);
	const [limit, setLimit] = useState(0);
	const [minOrder, setMinOrder] = useState(0);
	const [formData, updateFormData] = useState(null);

	const [visible, setVisible] = useState(false);
	const types = [
		{ name: "شحن مجانى", code: "shipping" },
		{ name: "خصم نسبة", code: "discount_percentage" },
		{ name: "خصم مبلغ", code: "discount_amount" },
	];

	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};
	function handleChangeCodesTypes(e) {
		// console.log(e.target.value, "e.target.value");
		updateFormData({
			...formData,
			// Trimming any whitespace
			promo_type: e.target.value.code,
		});
	}

	const handleSubmit = (e) => {
		if (formData) {
			console.log(formData, "formData");
			// if (formData?.promo_type) {
			// 	formData.append("promo_type", formData?.promo_type);
			// }
			e.preventDefault();
			axiosInstance
				.patch(`/admin/promo-codes/${data?.id}`, formData)
				.then((res) => {
					if (res.status === 200) {
						toast.success("تم التعديل بنجاح");
					}
					updateFormData(null);
					setVisible(false);
					setRender(!render);
				})
				.catch((err) => {
					if (err.response.status === 400) {
						toast.warning(`تاكد من البيانات المدخلة`);
					} else {
						toast.error(`${err.response.status} تاكد من البيانات المدخلة`);
					}
				});
		} else {
			toast.error();
		}
	};

	const footerContent = (
		<div className="d-flex justify-content-between">
			<Button
				type="button"
				color="secondary"
				onClick={(e) => {
					handleSubmit(e);
				}}>
				حفظ
			</Button>
			<Button
				type="button"
				color="primary"
				onClick={() => {
					setVisible(false);
					updateFormData(null);
					setValuePercentage(0);
					setValueAmount(0);
					setLimit(0);
				}}>
				اغلاق
			</Button>
		</div>
	);

	useEffect(() => {
		if (data?.promo_type === "discount_percentage") {
			setSelectedType({ name: "خصم نسبة", code: "discount_percentage" });
			setValuePercentage(data?.discount_percentage);
		} else if (data?.promo_type === "discount_amount") {
			setSelectedType({ name: "خصم مبلغ", code: "discount_amount" });
			setValueAmount(data?.discount_amount);
		} else {
			setSelectedType({ name: "شحن مجانى", code: "shipping" });
			setValueAmount(0);
			setValuePercentage(0);
		}
	}, [data]);

	console.log(selectedType, "selectedType");
	return (
		<div>
			<Button
				type="button"
				color="primary"
				onClick={() => setVisible(true)}
				data-toggle="modal"
				data-original-title="test"
				data-target="#exampleModal">
				تعديل
			</Button>
			<Dialog
				footer={footerContent}
				header={`تعديل الكود الترويجي ${data?.code}`}
				visible={visible}
				style={{ width: "50vw" }}
				onHide={() => setVisible(false)}>
				<Form>
					<FormGroup>
						<Label htmlFor="recipient-name" className="col-form-label">
							الكود الترويجي :
						</Label>
						<InputText
							keyfilter="alphanum"
							name="code"
							className="form-control"
							onChange={handleChange}
							defaultValue={data?.code}
						/>
						<small>مثال توضيحي : elyousr15 , yousr10</small>
					</FormGroup>
					<FormGroup>
						<Label for="exampleSelect" className="col-form-label">
							نوع الكود
						</Label>
						<Dropdown
							value={selectedType}
							onChange={(e) => {
								setSelectedType(e.value);

								handleChangeCodesTypes(e);
								setValuePercentage(0);
								setValueAmount(0);
							}}
							options={types}
							optionLabel="name"
							placeholder="اختر نوع الكود"
							className="w-100"
						/>
						{/* <Dropdown
							value={selectedType}
							onChange={(e) => {
								setSelectedType(e.value);
								handleChangeCodesTypes(e);
								setValuePercentage(0);
								setValueAmount(0);
							}}
							options={types}
							optionLabel="name"
							placeholder="اختر نوع الكود"
							filter
							className="w-100"
						/> */}
						{/* <Dropdown
							value={selectedType}
							onChange={(e) => {
								setSelectedType(e.value);
								handleChangeCodesTypes(e);
								updateFormData({
									code: formData?.code,
									promo_type: e.value.code,
								});
								setValuePercentage(0);
								setValueAmount(0);
							}}
							options={types}
							optionLabel="name"
							placeholder="اختر نوع الكود"
							filter
							className="w-100"
						/> */}
					</FormGroup>
					{selectedType?.code === "discount_percentage" ? (
						<FormGroup>
							<Label for="minmax" className="col-form-label">
								نسبة الخصم
							</Label>
							<InputNumber
								inputId="minmax"
								value={valuePercentage}
								onValueChange={(e) => {
									setValuePercentage(e.value);
									handleChange(e);
								}}
								min={0}
								max={100}
								className="w-100"
								name="discount_percentage"
							/>
						</FormGroup>
					) : selectedType?.code === "discount_amount" ? (
						<FormGroup>
							<Label for="minmax" className="col-form-label">
								مبلغ الخصم
							</Label>
							<InputNumber
								inputId="minmax"
								value={valueAmount}
								onValueChange={(e) => {
									setValueAmount(e.value);
									handleChange(e);
								}}
								className="w-100"
								name="discount_amount"
							/>
						</FormGroup>
					) : (
						""
					)}
					<FormGroup>
						<Label for="minmax" className="col-form-label">
							عدد مرات الاستخدام
						</Label>
						<Input
							type="number"
							name="usage_limit"
							className="form-control"
							onChange={(e) => {
								setLimit(e.value);
								handleChange(e);
							}}
							defaultValue={data?.usage_limit}
						/>
						{/* <InputNumber
							defaultValue={data?.usage_limit}
							inputId="minmax"
							value={limit}
							onValueChange={(e) => {
								setLimit(e.value);
								handleChange(e);
							}}
							className="w-100"
							name="usage_limit"
						/> */}
					</FormGroup>
					<FormGroup>
						<Label for="minmax" className="col-form-label">
							حد ادنى للاستخدام
						</Label>
						<Input
							type="number"
							name="discount_amount_min_amount"
							className="form-control"
							onChange={(e) => {
								setMinOrder(e.value);
								handleChange(e);
							}}
							defaultValue={data?.discount_amount_min_amount}
						/>
						{/* <InputNumber
							defaultValue={data?.discount_amount_min_amount}
							inputId="minmax"
							value={minOrder}
							onValueChange={(e) => {
								setMinOrder(e.value);
								handleChange(e);
							}}
							className="w-100"
							name="discount_amount_min_amount"
						/> */}
					</FormGroup>
				</Form>
			</Dialog>
		</div>
	);
}

export default EditPromoCode;
