import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Media, Row } from "reactstrap";
import { useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { classNames } from "primereact/utils";
import axiosInstance from "../axios";
import { Dropdown } from "primereact/dropdown";

const Datatable = ({ myData, myClass, HandleRender, pagination }) => {
	const [first, setFirst] = useState([0, 0, 0]);
	const [rows, setRows] = useState([50]);
	const [countPerPage, setCountPerPage] = useState(10);
	const [totalCountFromApi, setTotalCountFromApi] = useState(myData?.count);
	const [nextPage, setNextPage] = useState(myData?.next);
	const [prevPage, setPrevPage] = useState(myData?.previous);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [filteredData, setFilteredData] = useState(myData.results);
	// filter area start here
	const [filterText, setFilterText] = useState("");

	useEffect(() => {
		setTotalCountFromApi(myData?.count);
		setNextPage(myData?.next);
		setPrevPage(myData?.previous);
		setFilteredData(myData?.results);
	}, [myData]);

	function isArabic(text) {
		let arabic = /[\u0600-\u06FF]/;
		// var pattern = /[\u0600-\u06FF\u0750-\u077F]/;
		return arabic.test(text);
	}
	useEffect(() => {
		let filteredItems = [];
		if (filterText === "") {
			setFilteredData(myData.results);
		} else {
			if (isArabic(filterText)) {
				filteredItems = myData.results.filter(
					(item) => item.name_ar && item.name_ar.includes(filterText)
				);
			} else {
				filteredItems = myData.results.filter(
					(item) =>
						item.name_en &&
						item.name_en.toLowerCase().includes(filterText.toLowerCase())
				);
			}
			setFilteredData(filteredItems);
		}
	}, [filterText, myData.results]);

	// filter area end here

	//! handle delete error

	const imageBodyTemplate = (order) => {
		return (
			<>
				<Media
					src={`${order.improved_url}`}
					alt=""
					className="img-fluid customCursor"
					style={{ width: "70px" }}
				/>
			</>
		);
	};
	const btnBodyTemplate = (order) => {
		return (
			<>
				<Button
					color="primary"
					onClick={() =>
						// open in new tab
						{
							window.open(`/products/${order.id}/product-detail`, "_blank");

							window.focus();
						}
					}>
					عرض التفاصيل
				</Button>
			</>
		);
	};

	const onPageChange = (e, index) => {
		setFirst(first.map((f, i) => (index === i ? e.first : f)));
		setRows(rows.map((r, i) => (index === i ? e.rows : r)));
	};

	const handleFilterSubmit = (e) => {
		console.log(e, "eeeee");
		axiosInstance
			.get(`products/product?&page=${1}&page_size=${e}`)
			.then((res) => {
				setFilteredData(res.data.results);
				setPrevPage(res.data.previous);
				setNextPage(res.data.next);
				setLoading(false);
				setTotalCountFromApi(res.data.count);
			});
	};
	const template1 = {
		layout: "PrevPageLink PageLinks NextPageLink RowsPerPageDropdown ",
		PrevPageLink: (options) => {
			return (
				<button
					className={classNames(options.className, "border-round")}
					type="button"
					onClick={(e) => {
						setLoading(true);
						options.onClick(e);
						setLoading(true);
						axiosInstance
							.get(`products/product?&page=${prevPage}&page_size=${countPerPage}`)

							.then((res) => {
								setFilteredData(res.data.results);
								setPrevPage(res.data.previous);
								setNextPage(res.data.next);
								setLoading(false);
								setTotalCountFromApi(res.data.count);
							});
					}}
					disabled={options.disabled}>
					<span className="p-3">Previous</span>
				</button>
			);
		},
		NextPageLink: (options) => {
			return (
				<button
					className={classNames(options.className, "border-round")}
					type="button"
					onClick={(e) => {
						// setCurrentPage(currentPage + 1);
						options.onClick(e);
						setLoading(true);
						axiosInstance
							.get(`products/product?&page=${nextPage}&page_size=${countPerPage}`)
							// .get(`admin/get-test-user-list?page_size=100000`)
							.then((res) => {
								setFilteredData(res.data.results);
								setPrevPage(res.data.previous);
								setNextPage(res.data.next);
								setLoading(false);
								setTotalCountFromApi(res.data.count);
							});

						// e.target.disabled = true;
						// e.target.setAttribute("disabled", true);
					}}
					disabled={options.disabled}>
					<span className="p-3">Next</span>
				</button>
			);
		},
		PageLinks: (options) => {
			if (
				(options.view.startPage === options.page && options.view.startPage !== 0) ||
				(options.view.endPage === options.page &&
					options.page + 1 !== options.totalPages)
			) {
				const className = classNames(options.className, { "p-disabled": true });

				return (
					<span className={className} style={{ userSelect: "none" }}>
						...
					</span>
				);
			}

			return (
				<button
					type="button"
					className={options.className}
					onClick={(e) => {
						setLoading(true);
						options.onClick(e);

						axiosInstance
							.get(
								`products/product?&page=${options.page + 1}&page_size=${countPerPage}`
							)
							// .get(`admin/get-test-user-list?page_size=100000`)
							.then((res) => {
								setFilteredData(res.data.results);
								setPrevPage(res.data.previous);
								setNextPage(res.data.next);
								setLoading(false);
								setTotalCountFromApi(res.data.count);
							});
					}}>
					{options.page + 1}
				</button>
			);
		},

		CurrentPageReport: (options) => {
			return (
				<span
					className="mx-3"
					style={{ color: "var(--text-color)", userSelect: "none" }}></span>
			);
		},
		RowsPerPageDropdown: (options) => {
			const dropdownOptions = [
				{ label: 10, value: 10 },
				{ label: 25, value: 25 },
				{ label: 50, value: 50 },
			];

			return (
				<Dropdown
					value={options.value}
					options={dropdownOptions}
					onChange={(e) => {
						options.onChange(e);
						setCountPerPage(e.value);
						handleFilterSubmit(e.value);
					}}
				/>
			);
		},
	};
	const footer = (
		<>
			<Row>
				<Paginator
					template={template1}
					first={first[0]}
					rows={countPerPage}
					totalRecords={totalCountFromApi}
					onPageChange={(e) => onPageChange(e, 0)}
				/>
			</Row>
		</>
	);
	return (
		<div>
			<Fragment>
				<DataTable
					footer={footer}
					value={filteredData}
					// selectionMode="single"
					// selection={selectedProduct}
					// onSelectionChange={(e) => setSelectedProduct(e.value)}
					// dataKey="id"
					rowHover
					loading={loading}
					showGridlines
					// globalFilterFields={["name_ar", "name_en", "id"]}

					scrollable
					// scrollHeight="flex"
					onRowClick={(e) => navigate(`/products/${e.data.id}/product-detail`)}>
					<Column field="id" header="كود" style={{ textAlign: "right" }}></Column>
					<Column
						field="category.name_ar"
						header="القسم"
						style={{ textAlign: "right" }}></Column>
					<Column
						field="name_ar"
						header="الاسم عربى"
						style={{ textAlign: "right" }}></Column>
					<Column
						field="name_en"
						header="الاسم انجليزي"
						style={{ textAlign: "right" }}></Column>
					<Column
						body={(row) => (
							<div>{row.is_deleted.toString() === "true" ? "مخفى" : "مرئى"}</div>
						)}
						header="الحالة"
						style={{ textAlign: "right" }}></Column>

					<Column
						body={imageBodyTemplate}
						header="صورة"
						style={{ textAlign: "right", maxWidth: "100px" }}></Column>
					<Column
						body={btnBodyTemplate}
						header=""
						style={{ textAlign: "right", maxWidth: "100px" }}></Column>
				</DataTable>
				<ToastContainer autoClose={2000} rtl />
			</Fragment>
		</div>
	);
};

export default Datatable;
