import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
import axiosInstance from "../axios";
import { Toast } from "bootstrap";
import EditPromoCode from "./EditPromoCode";
function PromoCodeDatatable({ HandleRender }) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [render, setRender] = useState(false);
	useEffect(() => {
		setLoading(true);
		axiosInstance.get("/admin/promo-codes").then((res) => {
			setData(res.data.results);
			setLoading(false);
		});
	}, [HandleRender, render]);

	const allowExpansion = (rowData) => {
		return rowData.code.length > 0;
	};
	const [expandedRows, setExpandedRows] = useState(null);
	const rowExpansionTemplate = (row) => {
		return (
			<div className="d-flex justify-content-between">
				<div>
					<h3>حالة الكود</h3>
					<hr />
					<div>{row.is_active === true ? "مفعل" : "غير مفعل"}</div>
				</div>
				<div>
					<div
						onClick={() => {
							if (
								window.confirm(
									`هل انت متأكد من ${row.is_active === true ? "تعطيل" : "تفعيل"} الكود ؟`
								)
							) {
								axiosInstance
									.patch(`/admin/promo-codes/${row.id}`, { is_active: !row.is_active })
									.then((res) => {
										toast.success("تم تعديل حالة الكود بنجاح");
										setRender(!render);
									})
									.catch((err) => {
										toast.error("حدث خطأ");
									});
							}
						}}
						className="btn btn-secondary">
						تفعيل/تعطيل
					</div>
					<EditPromoCode data={row} setRender={setRender} render={render} />
				</div>
			</div>
		);
	};

	return (
		<>
			<DataTable
				value={data}
				rowHover
				loading={loading}
				showGridlines
				scrollable
				expandedRows={expandedRows}
				onRowToggle={(e) => setExpandedRows(e.data)}
				rowExpansionTemplate={rowExpansionTemplate}>
				<Column expander={allowExpansion} style={{ width: "5rem" }} />
				<Column field="id" header="كود" style={{ textAlign: "right" }}></Column>

				<Column
					field="code"
					header="الكود الترويجي"
					style={{ textAlign: "right" }}></Column>

				<Column
					body={(row) => (
						<div>
							{row.promo_type === "shipping"
								? "شحن مجانى"
								: row.promo_type === "discount_percentage"
								? "خصم نسبة"
								: "خصم مبلغ"}
						</div>
					)}
					header="النوع"
					style={{ textAlign: "right" }}></Column>
				<Column
					body={(row) => (
						<div>
							{row.promo_type === "shipping"
								? "شحن مجانى"
								: row.promo_type === "discount_percentage"
								? row.discount_percentage + " %"
								: row.discount_amount + " جنيه"}
						</div>
					)}
					header="النوع"
					style={{ textAlign: "right" }}></Column>
				<Column
					field="usage_limit"
					header="عدد مرات الاستخدام"
					style={{ textAlign: "right" }}></Column>
				<Column
					field="discount_amount_min_amount"
					header="الحد الادنى"
					style={{ textAlign: "right" }}></Column>
				<Column
					field="usage_left"
					header="المتبقى"
					style={{ textAlign: "right" }}></Column>
			</DataTable>
			<ToastContainer autoClose={2000} rtl />
		</>
	);
}

export default PromoCodeDatatable;
