import React, { useEffect, useState } from "react";
import { Button, Col, Label, Row } from "reactstrap";
import axiosInstance from "../../axios";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useSearchParams } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
function OrderReportFilterComponent({
	filterText,
	onFilter,
	onClear,
	onFilterClick,
	onUserChange,
	filterUser,

	onPaymentStatusChange,
	filterPaymentStatus,

	onOrderStatusChange,
	filterOrderStatus,

	onPaymentMethodChange,
	filterPaymentMethod,

	onShippingAreaChange,
	filterShippingArea,

	onCreatedAtStartDateChange,
	filterCreatedAtStartDate,

	onCreatedAtEndDateChange,
	filterCreatedAtEndDate,
	// !display start

	displayUser,
	displayPayStatus,
	displayOrderStatus,
	displayPaymentMethod,
	displayShippingArea,
	// !display end
	onClearAllClick,
}) {
	const [searchParams, setSearchParams] = useSearchParams();

	const [userDataForDropDown, setUserDataForDropDown] = useState([]);
	const [paymentStatus, setPaymentStatus] = useState([
		{ name: "paid", id: "1" },
		{ name: "unpaid", id: "2" },
	]);
	const [orderStatus, setOrderStatus] = useState([
		{ name: "pending", id: "1" },
		{ name: "shipped", id: "5" },
		{ name: "delivered", id: "6" },
		{ name: "refunded", id: "7" },
		{ name: "other", id: "2" },
	]);
	const [paymentMethodDataForDropDown, setPaymentMethodDataForDropDown] =
		useState([]);
	const [shippingAreaDataForDropDown, setShippingAreaDataForDropDown] = useState(
		[]
	);

	useEffect(() => {
		axiosInstance.get("/cart/user-list-for-admin").then((res) => {
			setUserDataForDropDown(res.data.results);
		});
		axiosInstance.get(`/global/delivery-city`).then((res) => {
			setShippingAreaDataForDropDown(res.data);
		});
		axiosInstance.get(`/cart/payment-method`).then((res) => {
			setPaymentMethodDataForDropDown(res.data);
		});
	}, []);

	return (
		<>
			<div className="card flex flex-wrap gap-3 p-fluid ">
				<Row>
					<Col className="d-flex justify-content-between flex-wrap">
						{displayUser === true ? (
							<div>
								<Label htmlFor="calendar-24h" className="font-bold  block">
									اسم العميل
								</Label>
								<MultiSelect
									value={filterUser}
									onChange={onUserChange}
									options={userDataForDropDown}
									optionLabel="first_name"
									filter
									filterMatchMode="contains"
									placeholder="اختر المستخدم"
									maxSelectedLabels={2}
									className="w-full md:w-20rem m-2"
								/>
							</div>
						) : (
							""
						)}

						{displayPayStatus === true ? (
							<div>
								<Label htmlFor="calendar-24h" className="font-bold block">
									حالة الدفع
								</Label>

								<MultiSelect
									className="m-2 w-full md:w-20rem"
									value={filterPaymentStatus}
									onChange={onPaymentStatusChange}
									options={paymentStatus}
									optionLabel="name"
									placeholder="حالة الدفع"
									filter
									filterMatchMode="contains"
									maxSelectedLabels={2}
									// showClear
								/>
							</div>
						) : (
							""
						)}

						{displayOrderStatus === true ? (
							<div>
								<Label htmlFor="calendar-24h" className="font-bold block ">
									حالة الطلب
								</Label>
								<MultiSelect
									className="m-2 w-full md:w-20rem"
									value={filterOrderStatus}
									onChange={onOrderStatusChange}
									options={orderStatus}
									optionLabel="name"
									placeholder="حالة الطلب"
									filter
									filterMatchMode="contains"
									maxSelectedLabels={2}
								/>
							</div>
						) : (
							""
						)}
						{displayPaymentMethod === true ? (
							<div>
								<Label htmlFor="calendar-24h" className="font-bold block ">
									طريقة الدفع
								</Label>
								<MultiSelect
									className="m-2 w-full md:w-20rem"
									value={filterPaymentMethod}
									onChange={onPaymentMethodChange}
									options={paymentMethodDataForDropDown}
									optionLabel="name_ar"
									placeholder=" طريقة الدفع"
									filter
									filterMatchMode="contains"
									maxSelectedLabels={2}
								/>
							</div>
						) : (
							""
						)}
						{displayShippingArea === true ? (
							<div>
								<Label for="calendar-24h" className="font-bold block">
									منطقة الشحن
								</Label>
								<MultiSelect
									className="m-2 w-full md:w-20rem"
									value={filterShippingArea}
									onChange={onShippingAreaChange}
									options={shippingAreaDataForDropDown}
									optionLabel="name_ar"
									placeholder="منطقة الشحن"
									filter
									filterMatchMode="contains"
									maxSelectedLabels={2}
								/>
							</div>
						) : (
							""
						)}
						<div>
							<Label htmlFor="calendar-24h" className="font-bold block mb-2">
								تاريخ بداية
							</Label>
							<Calendar
								value={filterCreatedAtStartDate}
								onChange={onCreatedAtStartDateChange}
								type="date"
							/>
						</div>
						<div>
							<Label htmlFor="calendar-24h" className="font-bold block mb-2">
								تاريخ نهاية
							</Label>
							<Calendar
								value={filterCreatedAtEndDate}
								onChange={onCreatedAtEndDateChange}
								type="date"
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col className="d-flex justify-content-center gap-3 p-3">
						<Button
							color="primary"
							type="button"
							style={{
								borderTopRightRadius: "5px",
								borderBottomRightRadius: "5px",
								borderTopLeftRadius: "5px",
								borderBottomLeftRadius: "5px",
							}}
							onClick={onFilterClick}>
							Search
						</Button>
						<Button
							color="primary"
							type="button"
							style={{
								// height: "32px",
								// width: "64px",
								borderTopRightRadius: "5px",
								borderBottomRightRadius: "5px",
								borderTopLeftRadius: "5px",
								borderBottomLeftRadius: "5px",
							}}
							onClick={() => {
								setSearchParams("");
								onClearAllClick();
							}}>
							clear
						</Button>
					</Col>
				</Row>
				<hr />
			</div>
		</>
	);
}

export default OrderReportFilterComponent;
