import { Button } from "primereact/button";
import React, { useState } from "react";
import "../../../assets/fonts/Amiri-Regular-normal";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

function ExportComponent({ products, startDate, endDate }) {
	const doc = new jsPDF("");
	const cols = [
		{ dataKey: "total_quantity", header: "اجمالي مبيعات كمية" },
		{ dataKey: "total_price", header: "اجمالي المبيعات سعر" },
		{ dataKey: "product_detail_name_ar", header: "اسم الصنف" },
		{ dataKey: "id", header: "الكود" },
	];

	const exportPdf2 = () => {
		doc.setFont("Amiri-Regular");
		doc.setTextColor(155, 34, 38);

		doc.text("تقرير مبيعات الاصناف", 150, 15);
		doc.text("---------------------", 150, 20);

		doc.setTextColor(3, 4, 94);
		doc.text(`من تاريخ ${startDate.split("=")[1].split("T")[0]}`, 140, 25);
		doc.text(`الى تاريخ ${endDate.split("=")[1].split("T")[0]}`, 140, 35);

		var totalPagesExp = "{total_pages_count_string}";

		autoTable(doc, {
			startY: 40,
			body: products,
			columns: cols,
			styles: {
				halign: "center",
				valign: "middle",
			},

			rowPageBreak: "auto",
			columnStyles: {
				0: { minCellWidth: 45 },
				1: { minCellWidth: 45 },
				2: { minCellWidth: 45 },
				3: { minCellWidth: 15 },
				// etc
			},

			headStyles: {
				font: "Amiri-Regular",
				fontSize: 13,
				// fillColor: [51, 173, 255],
				fillColor: [3, 4, 94],
			}, // For Arabic text in the table head
			bodyStyles: { font: "Amiri-Regular" }, // For Arabic text in the table body
			// margin: { top: 40 },
			didDrawPage: (data) => {
				var str = "Page " + doc.internal.getNumberOfPages();

				// Total page number plugin only available in jspdf v1.0+
				if (typeof doc.putTotalPages === "function") {
					str = str + " of " + totalPagesExp;
				}
				doc.setFontSize(10);

				var pageSize = doc.internal.pageSize;
				var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
				doc.text(str, data.settings.margin.left, pageHeight - 10);
			},
		});
		// let finalY = doc.previousAutoTable.finalY;
		// doc.text("Text to be shown relative to the table", 12, finalY + 10);
		if (typeof doc.putTotalPages === "function") {
			doc.putTotalPages(totalPagesExp);
		}

		doc.save("table.pdf");
	};
	function FilteringData(DataArray) {
		let temp = [];
		for (let i = 0; i < DataArray.length; i++) {
			temp.push({
				الكود: DataArray[i].id,
				"اسم الصنف": DataArray[i].product_detail_name_ar,
				"اجمالي المبيعات سعر": DataArray[i].total_price,
				"اجمالي مبيعات كمية": DataArray[i].total_quantity,
			});
		}
		return temp;
	}
	const exportExcel = () => {
		let excelData = FilteringData(products);
		import("xlsx").then((xlsx) => {
			const worksheet = xlsx.utils.json_to_sheet(excelData);
			const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
			const excelBuffer = xlsx.write(workbook, {
				bookType: "xlsx",
				type: "array",
			});
			saveAsExcelFile(excelBuffer, "Products");
		});
	};
	const saveAsExcelFile = (buffer, fileName) => {
		import("file-saver").then((module) => {
			if (module && module.default) {
				let EXCEL_TYPE =
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
				let EXCEL_EXTENSION = ".xlsx";
				const data = new Blob([buffer], {
					type: EXCEL_TYPE,
				});

				module.default.saveAs(
					data,
					fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
				);
			}
		});
	};

	return (
		<>
			<div className="d-flex gap-2">
				<Button
					type="button"
					icon="pi pi-file-excel"
					onClick={exportExcel}
					className="p-button-success mr-2 p-button-outlined"
					tooltip="XLS"
					tooltipOptions={{ position: "bottom" }}
				/>
				<Button
					type="button"
					icon="pi pi-file-pdf"
					onClick={exportPdf2}
					className="p-button-danger mr-2 p-button-outlined"
					tooltip="PDF"
					tooltipOptions={{
						position: "bottom",
					}}></Button>
			</div>
		</>
	);
}

export default ExportComponent;
