import React, { Fragment } from "react";

import TabsetProfile from "./tabset-profile";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, Col, Container, Media, Row, Button } from "reactstrap";
import { useEffect } from "react";
import axiosInstance from "../axios";
import { useState } from "react";

const Profile = () => {
	const [data, setData] = useState(null);
	const [render, setRender] = useState(false);
	useEffect(() => {
		axiosInstance.get(`/user/user-detail`).then((res) => {
			setData(res.data);
		});
	}, [render]);
	return (
		<>
			<Breadcrumb title="Profile" parent="Settings" />
			<Container fluid={true}>
				<Row>
					<Col xl="12">
						<Card className="profile-card">
							<CardBody>
								<TabsetProfile />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Profile;
