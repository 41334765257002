import React, { Fragment, useEffect, useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User, Unlock } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import axiosInstance from "../axios";

const LoginTabset = () => {
	const navigate = useNavigate();

	useEffect(() => {
		let isAuth = localStorage.getItem("access_token");
		if (isAuth && isAuth !== null) {
			navigate("/dashboard");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const initialFormData = Object.freeze({
		email: "",
		password: "",
	});

	const [formData, updateFormData] = useState(initialFormData);

	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		});
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		axiosInstance
			.post(`user/login`, {
				email: formData.email,
				password: formData.password,
			})
			.then((res) => {
				localStorage.clear();
				localStorage.setItem("access_token", res.data.access);
				localStorage.setItem("refresh_token", res.data.refresh);
				const tokenParts = JSON.parse(window.atob(res.data.access.split(".")[1]));

				localStorage.setItem("admin_token", tokenParts.is_superuser);
				axiosInstance.defaults.headers["Authorization"] =
					"Bearer " + localStorage.getItem("access_token");
				navigate("/dashboard/");
				navigate(0);
			})
			.catch((err) => {
				if (
					err.response.data.detail ===
					"No active account found with the given credentials"
				) {
					alert("مستخدم غير موجود");
				}
			});
	};

	const clickActive = (event) => {
		document.querySelector(".nav-link").classList.remove("show");
		event.target.classList.add("show");
	};

	return (
		<div>
			<Fragment>
				<Tabs>
					<TabList className="nav nav-tabs tab-coupon">
						<Tab className="nav-link" onClick={(e) => clickActive(e)}>
							<User />
							تسجيل الدخول
						</Tab>
						{/* <Tab className="nav-link" onClick={(e) => clickActive(e)}>
							<Unlock />
							Register
						</Tab> */}
					</TabList>

					<TabPanel>
						<Form onSubmit={handleSubmit} className="form-horizontal auth-form">
							<FormGroup>
								<Input
									required=""
									name="email"
									type="email"
									className="form-control"
									placeholder="Username"
									id="exampleInputEmail1"
									onChange={handleChange}
								/>
							</FormGroup>
							<FormGroup>
								<Input
									required=""
									name="password"
									type="password"
									className="form-control"
									placeholder="Password"
									onChange={handleChange}
								/>
							</FormGroup>
							<div className="form-terms">
								{/* <div className="custom-control custom-checkbox me-sm-2">
									<Label className="d-block">
										<Input className="checkbox_animated" id="chk-ani2" type="checkbox" />
										Reminder Me{" "}
										<span className="pull-right">
											{" "}
											<a href="/#" className="btn btn-default forgot-pass p-0">
												lost your password
											</a>
										</span>
									</Label>
								</div> */}
							</div>
							<div className="form-button">
								<Button color="primary" type="submit">
									Login
								</Button>
							</div>
						</Form>
					</TabPanel>
					{/* <TabPanel>
						<Form className="form-horizontal auth-form">
							<FormGroup>
								<Input
									required=""
									name="login[username]"
									type="email"
									className="form-control"
									placeholder="Username"
									id="exampleInputEmail12"
								/>
							</FormGroup>
							<FormGroup>
								<Input
									required=""
									name="login[password]"
									type="password"
									className="form-control"
									placeholder="Password"
								/>
							</FormGroup>
							<FormGroup>
								<Input
									required=""
									name="login[password]"
									type="password"
									className="form-control"
									placeholder="Confirm Password"
								/>
							</FormGroup>
							<div className="form-terms">
								<div className="custom-control custom-checkbox me-sm-2">
									<Label className="d-block">
										<Input className="checkbox_animated" id="chk-ani2" type="checkbox" />I
										agree all statements in{" "}
										<span>
											<a href="/#">Terms &amp; Conditions</a>
										</span>
									</Label>
								</div>
							</div>
							<div className="form-button">
								<Button color="primary" type="submit" onClick={() => routeChange()}>
									Register
								</Button>
							</div>
							<div className="form-footer">
								<span>Or Sign up with social platforms</span>
								<ul className="social">
									<li>
										<a href="/#">
											<i className="icon-facebook"></i>
										</a>
									</li>
									<li>
										<a href="/#">
											<i className="icon-twitter-alt"></i>
										</a>
									</li>
									<li>
										<a href="/#">
											<i className="icon-instagram"></i>
										</a>
									</li>
									<li>
										<a href="/#">
											<i className="icon-pinterest-alt"></i>
										</a>
									</li>
								</ul>
							</div>
						</Form>
					</TabPanel> */}
				</Tabs>
			</Fragment>
		</div>
	);
};

export default LoginTabset;
