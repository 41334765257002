import React from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User, Settings } from "react-feather";
import { Button, Col, Form, Input, Label, Row, Table } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import axiosInstance from "../axios";

const TabsetProfile = () => {
	const [data, setData] = useState(null);
	const [render, setRender] = useState(false);
	useEffect(() => {
		axiosInstance.get(`/user/user-detail`).then((res) => {
			setData(res.data);
		});
	}, [render]);

	const initialFormDataPassword = Object.freeze({
		old_password: "",
		password: "",
		confirmed_password: "",
	});

	const [formDataPassword, updateFormDataPassword] = useState(
		initialFormDataPassword
	);

	const handleChangePassword = (e) => {
		updateFormDataPassword({
			...formDataPassword,
			[e.target.name]: e.target.value.trim(),
		});
	};

	const handleSubmitPassword = (e) => {
		e.preventDefault();
		axiosInstance
			.patch(`/user/change-password`, {
				old_password: formDataPassword.old_password,
				password: formDataPassword.password,
				confirmed_password: formDataPassword.confirmed_password,
			})
			.then((res) => {
				formDataPassword(initialFormDataPassword);
				alert("changed success");
			})
			.catch((err) => {
				alert("تاكد من البيانات المدخلة");
			});
	};

	return (
		<div>
			<Tabs>
				<TabList className="nav nav-tabs tab-coupon">
					<Tab className="nav-link">
						<User className="me-2" />
						Profile
					</Tab>
					<Tab className="nav-link">
						<Settings className="me-2" />
						تغيير كلمة المرور
					</Tab>
				</TabList>

				<TabPanel>
					<div className="tab-pane fade show active">
						<div className="table-responsive profile-table">
							{/* <h5 className="f-w-600 f-16 mb-0">{data?.first_name}</h5>
							<div>{data?.email}</div>
							<div>{data?.mobile_number}</div> */}
							<Table className="table-responsive">
								<tbody>
									<tr>
										<td>الاسم:</td>
										<td className="f-w-600 f-16 mb-0">{data?.first_name}</td>
									</tr>
									<tr>
										<td>رقم الموبايل:</td>
										<td>{data?.mobile_number}</td>
									</tr>
									<tr>
										<td>البريد الاكترونى:</td>
										<td>{data?.email}</td>
									</tr>
								</tbody>
							</Table>
						</div>
					</div>
				</TabPanel>
				<TabPanel>
					{/* <div className="tab-pane fade"> */}
					<div className="account-setting">
						{/* <h5 className="f-w-600 f-16">Notifications</h5> */}
						<Row>
							<Col>
								<Form className="theme-form" onSubmit={handleSubmitPassword}>
									<Row>
										<Col md="6">
											<Label className="form-label" for="old_password">
												الرقم السرى القديم
											</Label>
											<Input
												type="text"
												className="form-control"
												id="home-ploat"
												// placeholder="الرقم السرى القديم"
												required=""
												name="old_password"
												onChange={handleChangePassword}
											/>
										</Col>
									</Row>
									<Row>
										<Col md="6">
											<Label className="form-label" for="password">
												الرقم السرى الجديد
											</Label>
											<Input
												type="text"
												className="form-control"
												id="home-ploat"
												// placeholder="الرقم السرى الجديد"
												required=""
												name="password"
												onChange={handleChangePassword}
											/>
										</Col>
										<Col md="6">
											<Label className="form-label" for="confirmed_password">
												اعد كتابة الرقم السرى
											</Label>
											<Input
												type="text"
												className="form-control"
												id="home-ploat"
												// placeholder="اعد كتابة الرقم السرى"
												required=""
												name="confirmed_password"
												onChange={handleChangePassword}
											/>
										</Col>
									</Row>
									<div className="col-md-12 mt-3">
										<Button className="btn btn-sm btn-solid" type="submit">
											تغيير كلمة المرور
										</Button>
									</div>
								</Form>
							</Col>
						</Row>
					</div>

					{/* </div> */}
				</TabPanel>
			</Tabs>
		</div>
	);
};

export default TabsetProfile;
