import React from "react";

import {
	Button,
	Col,
	Form,
	Input,
	Label,
	Row,
	Container,
	Modal,
	ModalHeader,
	ModalBody,
	FormGroup,
} from "reactstrap";
import { useState } from "react";

import axiosInstance from "../axios";

import { ToastContainer, toast } from "react-toastify";
import { ProgressBar } from "primereact/progressbar";

function AddTeamMember({ setRender1, render1 }) {
	const [formData, updateFormData] = useState("");
	const [open, setOpen] = useState(false);
	const [image, setImage] = useState(null);
	const [render, setRender] = useState(false);

	const onOpenModal = () => {
		setOpen(true);
	};

	const onCloseModal = () => {
		setOpen(false);
	};

	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};
	const handleChangeImage = (e) => {
		setImage(e.target.files[0]);
		// Trimming any whitespace
	};

	const [progressValue, setProgressValue] = useState(0);
	const config = {
		headers: { "Content-Type": "multipart/form-data" },
		onUploadProgress: function (progressEvent) {
			var percentCompleted = Math.round(
				(progressEvent.loaded * 100) / progressEvent.total
			);
			setProgressValue(percentCompleted);
		},
	};
	const handleSubmit = (e) => {
		const formData1 = new FormData();
		if (image) {
			if (formData?.title_ar) {
				formData1.append("title_ar", formData?.title_ar);
			}
			if (formData?.title_en) {
				formData1.append("title_en", formData?.title_en);
			}
			if (formData?.description_ar) {
				formData1.append("description_ar", formData?.description_ar);
			}
			if (formData?.description_en) {
				formData1.append("description_en", formData?.description_en);
			}
			formData1.append("url", image);
			formData1.append("is_deleted", true);
			e.preventDefault();
			axiosInstance
				.post(
					`/admin/main-page-slider`,
					formData1,
					config
					// testFunction
				)
				.then((res) => {
					// setRender(res.data.id);
					updateFormData(null);
					setRender(!render);
					onCloseModal("VaryingMdo");
					toast.success("تم الانشاء بنجاح");
					setProgressValue(0);
				})
				.catch((err) => {
					setProgressValue(0);
					if (err.response.status === 400) {
						toast.warning(`تاكد من البيانات المدخلة`);
					} else {
						toast.error(`${err.response.status} تاكد من البيانات المدخلة`);
					}
				});
		} else {
			toast.warning("يرجى اختيار صورة");
		}
	};

	return (
		<>
			<Container fluid={true}>
				<Row>
					<Col xl="12">
						<span
							style={{
								fontSize: 20,
								padding: 11,
								color: "rgb(40, 167, 69)",
							}}
							className="customCursor"
							onClick={(e) => {
								onOpenModal();
							}}>
							{" "}
							اضافة فرع جديد
							<i
								className="fa fa-pencil"
								style={{
									fontSize: 20,
									padding: 11,
									color: "rgb(40, 167, 69)",
								}}></i>
							<Modal
								isOpen={open}
								toggle={onCloseModal}
								style={{ overlay: { opacity: 0.1 } }}>
								<ModalHeader toggle={onCloseModal}>
									<h6 className="modal-title f-w-600" id="exampleModalLabel2">
										اضافة شخص جديد
									</h6>
								</ModalHeader>
								<ModalBody>
									<Form
										className="theme-form"
										onSubmit={(e) => {
											handleSubmit(e);
										}}>
										<div>
											<FormGroup>
												<Label htmlFor="recipient-name" className="col-form-label">
													الاسم :
												</Label>
												<Input
													type="text"
													name="title_ar"
													className="form-control"
													onChange={handleChange}
												/>
											</FormGroup>
											<FormGroup>
												<Label htmlFor="recipient-name" className="col-form-label">
													الوظيفة :
												</Label>
												<Input
													type="text"
													name="title_en"
													className="form-control"
													onChange={handleChange}
												/>
											</FormGroup>

											<FormGroup>
												<Label htmlFor="message-text" className="col-form-label">
													صورة :<p> مقاس الصورة (px) 377 * 494 </p>
												</Label>
												<Input
													name="url"
													className="form-control"
													id="validationCustom02"
													type="file"
													onChange={handleChangeImage}
												/>
											</FormGroup>
											<div className="card">
												<ProgressBar color="secondary" value={progressValue}></ProgressBar>
											</div>
										</div>
										<div className="col-md-12 mt-3 d-flex justify-content-between">
											<Button className="btn-sm " type="submit" color="secondary">
												حفظ الفرع
											</Button>
											<Button
												type="button"
												color="primary"
												onClick={() => onCloseModal("")}>
												اغلاق
											</Button>
										</div>
									</Form>
								</ModalBody>
							</Modal>
						</span>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default AddTeamMember;
