import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from "reactstrap";
import DatatableCity from "./DatatableCity";
import axiosInstance from "../axios";
import { toast } from "react-toastify";

const AddCity = () => {
	const [render, setRender] = useState(false);
	const [open, setOpen] = useState(false);
	const [data, setData] = useState([]);
	const [formData, updateFormData] = useState(null);

	const onOpenModal = () => {
		setOpen(true);
	};

	const onCloseModal = () => {
		setOpen(false);
	};

	useEffect(() => {
		axiosInstance.get(`/admin/delivery-city`).then((res) => {
			setData(res.data);
		});
	}, [render]);

	function HandleRender() {
		setRender(!render);
	}
	const handleSubmit = (e) => {
		e.preventDefault();
		axiosInstance
			.post(`/admin/delivery-city`, formData)
			.then((res) => {
				// setRender(res.data.id);
				updateFormData(null);
				setRender(!render);
				onCloseModal("VaryingMdo");
				if (res.status === "200") {
					toast.success("تم اضافة المدينة بنجاح");
				}
			})
			.catch((err) => {
				if (err.response.status === 400) {
					toast.warning(`تاكد من البيانات المدخلة`);
				} else {
					toast.error(`${err.response.status} تاكد من البيانات المدخلة`);
				}
			});
	};

	const handleChange = (e) => {
		updateFormData({
			...formData,
			// Trimming any whitespace
			[e.target.name]: e.target.value,
		});
	};

	return (
		<Fragment>
			<Breadcrumb title="المدن" />
			{/* <!-- Container-fluid starts--> */}
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<div className="btn-popup pull-right">
									<Button
										type="button"
										color="primary"
										onClick={onOpenModal}
										data-toggle="modal"
										data-original-title="test"
										data-target="#exampleModal">
										اضافة مدينة
									</Button>
									<Modal isOpen={open} toggle={onCloseModal}>
										<ModalHeader toggle={onCloseModal}>
											<h5 className="modal-title f-w-600" id="exampleModalLabel2">
												اضافة مدينة
											</h5>
										</ModalHeader>
										<ModalBody>
											<Form>
												<FormGroup>
													<Label htmlFor="recipient-name" className="col-form-label">
														الاسم عربى :
													</Label>
													<Input
														type="text"
														name="name_ar"
														className="form-control"
														onChange={handleChange}
													/>
												</FormGroup>
												<FormGroup>
													<Label htmlFor="recipient-name" className="col-form-label">
														الاسم انجليزي :
													</Label>
													<Input
														type="text"
														name="name_en"
														className="form-control"
														onChange={handleChange}
													/>
												</FormGroup>
												<FormGroup>
													<Label htmlFor="recipient-name" className="col-form-label">
														السعر :
													</Label>
													<Input
														type="text"
														name="price"
														className="form-control"
														onChange={handleChange}
													/>
												</FormGroup>
											</Form>
										</ModalBody>
										<ModalFooter className="d-flex justify-content-between">
											<Button
												type="button"
												color="secondary"
												onClick={(e) => {
													handleSubmit(e);
												}}>
												حفظ
											</Button>
											<Button
												type="button"
												color="primary"
												onClick={() => onCloseModal("VaryingMdo")}>
												اغلاق
											</Button>
										</ModalFooter>
									</Modal>
								</div>
								<div className="clearfix"></div>
								<div id="basicScenario" className="product-physical">
									<DatatableCity
										myData={data}
										multiSelectOption={false}
										pageSize={10}
										pagination={true}
										class="-striped -highlight"
										HandleRender={HandleRender}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			{/* <!-- Container-fluid Ends--> */}
		</Fragment>
	);
};

export default AddCity;
