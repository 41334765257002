import React from "react";
import { useState } from "react";
import {
	Button,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "reactstrap";
import axiosInstance from "../../axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ProgressBar } from "primereact/progressbar";

function AddImg({ colorData, HandleRenderColorImage }) {
	const [openAddItemImages, setOpenAddItemImages] = useState(false);
	const [formData, updateFormData] = useState(null);
	const [formDataAddImage, updateFormDataAddImage] = useState(null);
	const [image, setImage] = useState(null);
	const { productID } = useParams();
	const onOpenAddItemModal = () => {
		setOpenAddItemImages(true);
	};

	const onCloseAddItemModal = () => {
		setOpenAddItemImages(false);
	};
	const handleChangeAddItem = (e) => {
		updateFormDataAddImage({
			...formDataAddImage,
			// Trimming any whitespace
			[e.target.name]: e.target.value,
		});
	};
	const handleChangeImage = (e) => {
		setImage(e.target.files);
		// Trimming any whitespace
	};
	const [progressValue, setProgressValue] = useState(0);
	const config = {
		headers: { "Content-Type": "multipart/form-data" },
		onUploadProgress: function (progressEvent) {
			var percentCompleted = Math.round(
				(progressEvent.loaded * 100) / progressEvent.total
			);
			setProgressValue(percentCompleted);
		},
	};
	//do something else
	const handleSubmitAddImage = (e) => {
		const id = toast.loading("Please wait...");
		if ((image === null || image.length < 1) && formDataAddImage?.alt !== null) {
			toast.update(id, {
				render: "يجب ادخال جميع البيانات",
				type: "error",
				isLoading: false,
				autoClose: 1000,
			});
		} else {
			for (let i = 0; i < image.length; i++) {
				const formData1 = new FormData();
				formData1.append("url", image[i]);
				if (formDataAddImage?.alt) {
					formData1.append("alt", formDataAddImage?.alt);
				}
				e.preventDefault();
				axiosInstance
					.post(
						`/products/product/${productID}/product-line/${colorData.id}/img`,
						formData1,
						config
					)
					.then((res) => {
						setProgressValue(0);
						HandleRenderColorImage();
						updateFormData(null);
						onCloseAddItemModal("VaryingMdo");
						toast.update(id, {
							render: "تم اضافة الصورة بنجاح",
							type: "success",
							isLoading: false,
							autoClose: 2000,
						});
					})
					.catch((err) => {
						setProgressValue(0);
						if (err.response.status === 400) {
							toast.warning(`تاكد من البيانات المدخلة`);
						} else {
							toast.error(`${err.response.status} تاكد من البيانات المدخلة`);
						}
					});
			}
		}
	};

	return (
		<>
			<Button color="primary" onClick={onOpenAddItemModal}>
				اضافة صورة
				<i className="fa fa-plus "></i>
			</Button>
			<Modal
				size="sm"
				isOpen={openAddItemImages}
				toggle={onCloseAddItemModal}
				style={{ overlay: { opacity: 0.3 } }}>
				<ModalHeader toggle={onCloseAddItemModal}>
					<h2 className="modal-title f-w-600" id="exampleModalLabel2">
						اضافة صورة جديدة
					</h2>
				</ModalHeader>
				<ModalBody>
					<FormGroup>
						<Label htmlFor="recipient-name" className="col-form-label">
							وصف الصورة :
						</Label>
						<Input
							type="text"
							name="alt"
							className="form-control"
							onChange={handleChangeAddItem}
						/>
					</FormGroup>
					<FormGroup>
						<Label htmlFor="message-text" className="col-form-label">
							يمكن اضافة اكثر من صورة معا
						</Label>
						<Input
							name="url"
							className="form-control"
							id="validationCustom02"
							type="file"
							multiple
							onChange={handleChangeImage}
						/>
					</FormGroup>
					<div className="card">
						<ProgressBar color="secondary" value={progressValue}></ProgressBar>
					</div>
				</ModalBody>

				<ModalFooter className="d-flex justify-content-between">
					<Button
						type="button"
						color="secondary"
						onClick={(e) => {
							handleSubmitAddImage(e);
						}}>
						حفظ
					</Button>
					<Button
						type="button"
						color="primary"
						onClick={() => onCloseAddItemModal("VaryingMdo")}>
						اغلاق
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}

export default AddImg;
