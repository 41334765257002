import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import "../../assets/scss/slick.scss";
import "../../assets/scss/slick-theme.scss";
import {
	Card,
	Col,
	Container,
	Row,
	List,
	Label,
	Button,
	Form,
	FormGroup,
	Input,
} from "reactstrap";
import axiosInstance from "../axios";
import { useNavigate, useParams } from "react-router-dom";
import ChangeOrderStatus from "./components/ChangeOrderStatus";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import ChangePaymentStatus from "./components/ChangePaymentStatus";
import { toast } from "react-toastify";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
const OrderDetail = () => {
	const [data, setData] = useState([]);
	const [orderData, setOrderData] = useState(null);
	const { orderId } = useParams();
	const [render, setRender] = useState(false);
	const navigate = useNavigate();
	useEffect(() => {
		axiosInstance
			.get(`/admin/order-detail/${orderId}/order-item-list`)
			.then((res) => {
				setData(res.data);
			});
		axiosInstance.get(`/admin/order-detail/${orderId}`).then((res) => {
			setOrderData(res.data);
		});
	}, [render, orderId]);

	const HandleRender = () => {
		setRender(!render);
	};
	const imageBodyTemplate = (product) => {
		return (
			<a href={`/products/${product?.product_line?.product?.id}/product-detail`}>
				<img
					src={product?.product_line?.product_line_image[0]?.url}
					className="img-fluid"
					style={{ maxHeight: "10vh" }}
					alt=""
					// onClick={() => {
					// 	console.log(product);
					// 	navigate(
					// 		`/products/${product?.product_line?.product?.id}/product-detail`
					// 	);
					// }}
				/>
			</a>
		);
	};

	const [visible, setVisible] = useState(false);
	const [formData, updateFormData] = useState(null);
	const [rowDetail, setRowDetail] = useState(null);
	const handleChange = (e) => {
		updateFormData({
			...formData,
			// Trimming any whitespace
			[e.target.name]: e.target.value,
		});
	};
	const actionBodyTemplate = (rowData) => {
		return (
			<div>
				<Button
					className="btn-block "
					color="primary"
					onClick={() => {
						setVisible(true);
						setRowDetail(rowData);
					}}>
					مرتجع
				</Button>
				<Dialog
					header="عملية مرتجع جزئى"
					visible={visible}
					style={{ width: "70vw" }}
					onHide={() => setVisible(false)}>
					<Form>
						<div className="d-flex justify-content-between">
							<h3>الكمية</h3>
							<p>{rowDetail?.quantity}</p>
							<p>{rowDetail?.product_line.color_ar}</p>
						</div>
						<hr />
						<FormGroup>
							<Label htmlFor="recipient-name" className="col-form-label">
								عمل مرتجع :
							</Label>
							<Input
								type="number"
								name="refund_amount"
								className="form-control"
								onChange={handleChange}
							/>
						</FormGroup>
					</Form>
					<hr />
					<div className="d-flex justify-content-between">
						<Button
							type="button"
							color="secondary"
							onClick={(e) => {
								if (window.confirm("هل انت متأكد من عمل مرتجع ؟")) {
									axiosInstance
										.post(`/admin/refund-order-item/${rowDetail?.id}`, formData)
										.then((res) => {
											toast.success("تم العملية بنجاح", {
												autoClose: 3000,
											});
											setRender(!render);
											setVisible(false);
										})
										.catch((err) => {
											if (err.response.data.error === "invalid amount") {
												toast.error(
													"الكمية غير صحيحة يجب ان تكوت رقم صحيح او مضاعفات الربع مثال: 1.25",
													{
														autoClose: 3000,
													}
												);
											} else if (err.response.data.error === "amount exceeded") {
												toast.error("لا يمكن عمل مرتجع باكثر من الكمية المتاحة", {
													autoClose: 3000,
												});
											} else {
												toast.error("حدث خطأ", {
													autoClose: 3000,
												});
											}
										});
								}
							}}>
							حفظ
						</Button>
						<Button type="button" color="primary" onClick={() => setVisible(false)}>
							اغلاق
						</Button>
					</div>
				</Dialog>
			</div>
		);
	};
	const nameBodyTemplate = (data) => {
		return (
			<div>
				{data?.product_line?.product.name_ar} - {data?.product_line?.color_ar}
			</div>
		);
	};
	const itemTotalPriceTemplate = (data) => {
		return <div>{orderData?.total_items_price}</div>;
	};

	const footer = (
		<>
			<div className="d-flex justify-content-start align-items-center m-2 p-2">
				<h3>اجمالى الطلبية</h3>
				<span>
					{orderData?.total_items_price} + {orderData?.shipping_price} -{" "}
					{orderData?.promo_code_discount} = {orderData?.total_price_after_discount}
				</span>
			</div>
		</>
	);
	return (
		<Fragment>
			<Breadcrumb title="بيانات الطلبية" />
			<Container fluid={true}>
				<Card>
					<Row className="product-page-main card-body">
						<Col xl="12">
							<div className="product-page-details product-right mb-0">
								<div className="d-flex justify-content-between align-items-center m-2 p-2">
									<h2>طلبية رقم: {orderData?.id}</h2>
									<span
										style={{ fontSize: "18px" }}
										className={`order-badge status-${orderData?.order_status.toLowerCase()} `}>
										{orderData?.order_status}
									</span>
								</div>
								<hr />
								<DataTable
									value={data}
									showGridlines
									header="بيانات الطلبية"
									footer={footer}>
									<Column
										style={{ textAlign: "right" }}
										body={nameBodyTemplate}
										header="Code"></Column>
									<Column
										style={{ textAlign: "right" }}
										field="quantity"
										header="الكمية"></Column>
									<Column
										style={{ textAlign: "right" }}
										field="total_price"
										header="اجمالى السعر"
										footer={itemTotalPriceTemplate}></Column>
									<Column
										style={{ textAlign: "right" }}
										field="total_refund_amount"
										header="كمية المرتجع"></Column>

									<Column
										style={{ textAlign: "right" }}
										body={imageBodyTemplate}
										header="صورة"></Column>
									<Column
										style={{ textAlign: "right" }}
										body={actionBodyTemplate}
										header="عمليات"></Column>
								</DataTable>
							</div>
						</Col>
					</Row>
				</Card>
				<Row>
					<Col sm="6">
						<Card className="p-4 ">
							<div>
								<div className="d-flex justify-content-between align-items-center mt-2 p-2">
									<h4 className="product-title">حالة الطلب:</h4>
									<span
										style={{ fontSize: "18px" }}
										className={`order-badge status-${orderData?.order_status.toLowerCase()} `}>
										{orderData?.order_status}
									</span>
								</div>
								<div className="m-2 p-2">
									{orderData?.order_status === "refunded" ? (
										""
									) : (
										<ChangeOrderStatus
											HandleRender={HandleRender}
											orderStatus={orderData?.order_status}
										/>
									)}
								</div>
								<hr />
								<div className="d-flex justify-content-center align-items-center">
									<Button
										className="btn-block "
										color="primary"
										onClick={() => {
											if (window.confirm(`هل تريد عمل مرتجع الطلب ؟`)) {
												axiosInstance
													.post(`/admin/refund-order/${orderId}`, { refund_type: "full" })
													.then((res) => {
														setRender(!render);
														toast.success("تم مرتجع الطلب");
													})
													.catch((err) => {
														if (err.response.data.message === "order not paid") {
															toast.error("الطلبية غير مدفوعة");
														} else {
															toast.error(err.response.data.message);
														}
													});
											}
										}}>
										عملية مرتجع
									</Button>
								</div>
							</div>
						</Card>
					</Col>
					<Col sm="6">
						<Card className="p-4">
							<div className="d-flex justify-content-between align-items-center m-2 p-2">
								<h4>طريقة الدفع</h4>

								<h4>{orderData?.payment_method?.name_ar}</h4>
							</div>
							<hr />
							<h4 className="product-title">حالة الدفع:</h4>
							<div className="d-flex justify-content-between align-items-center m-2 p-2">
								<div className="">
									<ChangePaymentStatus HandleRender={HandleRender} />
								</div>
								<div>
									<span
										style={{ fontSize: "18px" }}
										className={`order-badge status-${orderData?.payment_status.toLowerCase()} `}>
										{orderData?.payment_status}
									</span>
								</div>
							</div>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col sm="6">
						<Card className="p-4 ">
							<div className="product-page-details product-right mb-0">
								<h4 className="product-title">بيانات العميل والشحن:</h4>
								<div className="d-flex justify-content-between align-items-center m-2 p-2">
									<div>
										<h6>
											الاسم: {orderData?.billing_detail[0]?.first_name}{" "}
											{orderData?.billing_detail[0]?.last_name}
										</h6>
										<h6>رقم الهاتف: {orderData?.billing_detail[0]?.phone}</h6>
										<h6>رقم الهاتف: {orderData?.billing_detail[0]?.phone_2}</h6>
										<h6>البريد الالكتروني: {orderData?.billing_detail[0]?.email}</h6>
										<h6>العنوان: {orderData?.billing_detail[0]?.full_address}</h6>
										<h6>ملاحظات: {orderData?.billing_detail[0]?.note}</h6>
									</div>
								</div>
							</div>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default OrderDetail;
