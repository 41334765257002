import React, { Fragment, useState } from "react";
// import Breadcrumb from "../../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import Breadcrumb from "../common/breadcrumb";

import DataTableCount from "./components/dataTableCount";

const ProductCount = () => {
	const [render, setRender] = useState(false);

	const handleRender = () => {
		setRender(!render);
	};

	return (
		<Fragment>
			<Breadcrumb title="تقرير مبيعات الاصناف" />
			{/* <!-- Container-fluid starts--> */}
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<div>
									<DataTableCount handleRender={handleRender} />
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			{/* <!-- Container-fluid Ends--> */}
		</Fragment>
	);
};

export default ProductCount;
