import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
// import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Badge, Button, Col, Form, FormGroup, Input, Label } from "reactstrap";

import axiosInstance from "../../axios";
import { toast } from "react-toastify";
import { ProgressBar } from "primereact/progressbar";
import { useEffect } from "react";

function EditMainDetails({
	data,
	HandleRender,
	selectedSeason,
	setSelectedSeason,
}) {
	console.log(data, "data");
	const { productID } = useParams();

	const [visible, setVisible] = useState(false);
	const [video, setVideo] = useState(null);

	const [selectedVideoType, setSelectedVideoType] = useState(null);
	const videoTypes = [
		{ name: "لايوجد", code: "no-video" },
		{ name: "لينك", code: "video-url" },
		{ name: "رفع فيديو", code: "video-upload" },
	];
	const [formData, updateFormData] = useState(null);
	const [dataID, setDataID] = useState(null);
	const [image, setImage] = useState(null);
	const [category, setCategory] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(null);

	useEffect(() => {
		axiosInstance.get(`/products/category`).then((res) => {
			setCategory(res.data);
		});
	}, []);

	useEffect(() => {
		setSelectedCategory(data?.category);
	}, [data]);
	//code for seasons drop down

	const seasons = [
		{ name: "شتوى", code: "winter" },
		{ name: "صيفى", code: "summer" },
	];
	const handleChange = (e) => {
		updateFormData({
			...formData,
			// Trimming any whitespace
			[e.target.name]: e.target.value,
		});
	};
	const handleChangeSeason = (e) => {
		updateFormData({
			...formData,
			// Trimming any whitespace
			season: e.code,
		});
	};
	const handleChangeImage = (e) => {
		setImage(e.target.files[0]);
		// Trimming any whitespace
	};
	const handleChangeVideo = (e) => {
		setVideo(e.target.files[0]);
		// Trimming any whitespace
	};
	function handleChangeCategory(e) {
		updateFormData({
			...formData,
			// Trimming any whitespace
			category_id: e.target.value.id,
		});
	}

	const handleChangeVideoType = (e) => {
		updateFormData({
			...formData,
			// Trimming any whitespace
			video_type: e,
		});
	};

	const [progressValue, setProgressValue] = useState(0);
	const [progressVideoValue, setProgressVideoValue] = useState(0);
	const config = {
		headers: { "Content-Type": "multipart/form-data" },
		onUploadProgress: function (progressEvent) {
			var percentCompleted = Math.round(
				(progressEvent.loaded * 100) / progressEvent.total
			);
			setProgressValue(percentCompleted);
			setProgressVideoValue(percentCompleted);
		},
	};
	const handleSubmit = (e) => {
		if (formData !== null || image !== null) {
			const formData1 = new FormData();
			if (formData?.category_id) {
				formData1.append("category_id", formData?.category_id);
			}
			if (formData?.name_ar) {
				formData1.append("name_ar", formData?.name_ar);
			}
			if (formData?.name_en) {
				formData1.append("name_en", formData?.name_en);
			}
			if (formData?.description_ar) {
				formData1.append("description_ar", formData?.description_ar);
			}
			if (formData?.description_en) {
				formData1.append("description_en", formData?.description_en);
			}
			if (formData?.price) {
				formData1.append("price", formData?.price);
			}
			if (formData?.discount) {
				formData1.append("discount", formData?.discount);
			}
			if (image) {
				formData1.append("url", image);
			}
			if (formData?.season) {
				formData1.append("season", formData?.season);
			}

			if (formData?.video_type) {
				formData1.append("video_type", formData?.video_type);
			}
			if (video) {
				formData1.append("video_upload", video);
			}
			if (formData?.video_url) {
				formData1.append("video_url", formData?.video_url);
			}

			e.preventDefault();
			axiosInstance
				.patch(`/products/product/${dataID}`, formData1, config)
				.then((res) => {
					setProgressValue(0);
					updateFormData(null);
					HandleRender();
					if (res.status === 200) {
						toast.success("تم التعديل بنجاح");
					}
					setVisible(false);

					window.location.reload();
				})
				.catch((err) => {
					setProgressValue(0);
					if (err.response.status === 400) {
						toast.warning(`تاكد من البيانات المدخلة`);
					} else {
						toast.error(`${err.response.status} تاكد من البيانات المدخلة`);
					}
				});
		} else {
			toast.error("لم يتم تعديل اى بيانات");
		}
	};
	const footerContent = (
		<div>
			<Button
				type="button"
				color="secondary"
				onClick={(e) => {
					handleSubmit(e);
				}}>
				تعديل
			</Button>
			<Button type="button" color="primary" onClick={() => setVisible(false)}>
				إغلاق
			</Button>
			{/* <Button
				label="No"
				icon="pi pi-times"
				onClick={() => setVisible(false)}
				className="p-button-text"
			/>
			<Button
				label="Yes"
				icon="pi pi-check"
				onClick={() => setVisible(false)}
				autoFocus
			/> */}
		</div>
	);
	return (
		<>
			<Col xl="12" className="d-flex align-items-center justify-content-between">
				<h2>
					{data?.name_ar} - {data?.name_en}
				</h2>
				<span>
					<i
						onClick={(e) => {
							setDataID(productID);
							setVisible(true);
						}}
						className="fa fa-pencil"
						style={{
							width: 35,
							fontSize: 20,
							padding: 11,
							color: "rgb(40, 167, 69)",
							cursor: "pointer",
						}}></i>
				</span>
				<Dialog
					header="تعديل الصنف"
					footer={footerContent}
					visible={visible}
					style={{ width: "50vw" }}
					onHide={() => setVisible(false)}>
					<Form>
						<FormGroup>
							<Label for="exampleSelect" className="col-form-label">
								تعديل القسم
							</Label>
							<Dropdown
								value={selectedCategory}
								onChange={(e) => {
									setSelectedCategory(e.value);
									handleChangeCategory(e);
								}}
								options={category}
								optionLabel="name_ar"
								placeholder="Select a Country"
								filter
								className="w-100"
							/>
						</FormGroup>
						<FormGroup>
							<Label htmlFor="recipient-name" className="col-form-label">
								الاسم عربى :
							</Label>
							<Input
								type="text"
								name="name_ar"
								className="form-control"
								onChange={handleChange}
								defaultValue={data?.name_ar}
							/>
						</FormGroup>
						<FormGroup>
							<Label htmlFor="recipient-name" className="col-form-label">
								الاسم انجليزي :
							</Label>
							<Input
								type="text"
								name="name_en"
								className="form-control"
								onChange={handleChange}
								defaultValue={data?.name_en}
							/>
						</FormGroup>
						<FormGroup>
							<Label htmlFor="recipient-name" className="col-form-label">
								الوصف عربى :
							</Label>
							<Input
								type="text"
								name="description_ar"
								className="form-control"
								onChange={handleChange}
								defaultValue={data?.description_ar}
							/>
						</FormGroup>
						<FormGroup>
							<Label htmlFor="recipient-name" className="col-form-label">
								الوصف انجليزي :
							</Label>
							<Input
								type="text"
								name="description_en"
								className="form-control"
								onChange={handleChange}
								defaultValue={data?.description_en}
							/>
						</FormGroup>
						<FormGroup>
							<Label htmlFor="recipient-name" className="col-form-label">
								السعر
							</Label>
							<Input
								type="text"
								name="price"
								className="form-control"
								onChange={handleChange}
								defaultValue={data?.price}
							/>
						</FormGroup>
						<FormGroup>
							<Label htmlFor="recipient-name" className="col-form-label">
								الخصم
							</Label>
							<Input
								type="number"
								name="discount"
								step={1}
								min={0}
								max={100}
								className="form-control"
								onChange={handleChange}
								defaultValue={data?.discount}
							/>
						</FormGroup>
						<FormGroup>
							<Label
								htmlFor="recipient-name"
								className="col-form-label"
								style={{ marginLeft: 15 }}>
								الموسم
							</Label>
							<Dropdown
								value={selectedSeason}
								onChange={(e) => {
									setSelectedSeason(e.value);
									console.log(e, "e");
									handleChangeSeason(e.value);
								}}
								options={seasons}
								optionLabel="name"
								placeholder="اختار الموسم"
								className="w-full md:w-14rem"
							/>
						</FormGroup>
						<FormGroup>
							<Label htmlFor="message-text" className="col-form-label">
								الصورة الرئيسية للمنتج
							</Label>
							<Input
								name="url"
								className="form-control"
								id="validationCustom02"
								type="file"
								onChange={handleChangeImage}
							/>
						</FormGroup>
						<div className="card">
							<ProgressBar color="secondary" value={progressValue}></ProgressBar>
						</div>
						<FormGroup>
							<Label
								htmlFor="recipient-name"
								className="col-form-label"
								style={{ marginLeft: 15 }}>
								نوع الفيديو
							</Label>
							<Input
								id="videoType"
								name="video_type"
								type="select"
								onChange={(e) => {
									console.log(e.target.value, "e.target");
									setSelectedVideoType(e.target.value);
									handleChangeVideoType(e.target.value);
								}}>
								<option selected={true} disabled="disabled">
									برجاء اختيار نوع
								</option>
								{videoTypes.map((video) => (
									<option key={video.id} value={video.code}>
										{video.name}
									</option>
								))}
							</Input>
						</FormGroup>
						<FormGroup>
							{selectedVideoType === "video-url" ? (
								<Input
									id="video"
									type="text"
									name="video_url"
									className="form-control"
									onChange={handleChange}
								/>
							) : selectedVideoType === "video-upload" ? (
								<>
									<Input
										id="video"
										type="file"
										name="video_upload"
										className="form-control"
										onChange={handleChangeVideo}
									/>
								</>
							) : null}
							<div className="card">
								<ProgressBar color="secondary" value={progressValue}></ProgressBar>
							</div>
						</FormGroup>
					</Form>
				</Dialog>
			</Col>
		</>
	);
}

export default EditMainDetails;
