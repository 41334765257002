import React, { useEffect, useState } from "react";
import Sidebar from "./common/sidebar_components/sidebar";
// import RightSidebar from "./common/right-sidebar";
import Footer from "./common/footer";
import Header from "./common/header_components/header";
import { Outlet } from "react-router-dom";

const App = (props) => {
	const [sidebar, setSidebar] = useState(false);
	document.body.classList.add("rtl");

	return (
		<div>
			<div className="page-wrapper">
				<Header sidebar={sidebar} setSidebar={setSidebar} />
				<div className="page-body-wrapper">
					<Sidebar sidebar={sidebar} setSidebar={setSidebar} />
					{/* <RightSidebar /> */}
					<div className="page-body">
						<Outlet />
					</div>
					<Footer />
				</div>
			</div>
			{/* <div
				className="btn-light custom-theme"
				onClick={() => ChangeRtl(side.divName)}>
				{side.divName}
			</div> */}
		</div>
	);
};
export default App;
