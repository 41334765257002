import React, { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
	Button,
	Form,
	FormGroup,
	Input,
	Label,
	Media,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "reactstrap";
import axiosInstance from "../axios";
import { ProgressBar } from "primereact/progressbar";

const SliderDataTabel = ({ myData, myClass, pagination, HandleRender }) => {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [render, setRender] = useState(false);
	const [data, setData] = useState(myData);
	const [formData, updateFormData] = useState(null);
	const [image, setImage] = useState(null);
	const [rowData, setRowData] = useState(null);

	const handleChange = (e) => {
		updateFormData({
			...formData,
			// Trimming any whitespace
			[e.target.name]: e.target.value,
		});
	};

	const handleChangeImage = (e) => {
		setImage(e.target.files[0]);
	};

	const [progressValue, setProgressValue] = useState(0);
	const config = {
		headers: { "Content-Type": "multipart/form-data" },
		onUploadProgress: function (progressEvent) {
			var percentCompleted = Math.round(
				(progressEvent.loaded * 100) / progressEvent.total
			);
			setProgressValue(percentCompleted);
		},
	};
	const handleSubmit = (e) => {
		const formData1 = new FormData();
		if (formData !== null || image !== null) {
			if (formData?.title_ar === "" || formData?.title_ar) {
				formData1.append("title_ar", formData.title_ar);
			}
			if (formData?.title_en === "" || formData?.title_en) {
				formData1.append("title_en", formData.title_en);
			}
			if (formData?.description_ar === "" || formData?.description_ar) {
				formData1.append("description_ar", formData.description_ar);
			}
			if (formData?.description_en === "" || formData?.description_en) {
				formData1.append("description_en", formData.description_en);
			}
			if (image) {
				formData1.append("url", image);
			}
			e.preventDefault();
			axiosInstance
				.patch(`/admin/main-page-slider/${rowData.id}`, formData1, config)
				.then((res) => {
					setProgressValue(0);
					updateFormData(null);
					HandleRender();
					onCloseModal("VaryingMdo");
					toast.success("تم التعديل بنجاح");
				})
				.catch((err) => {
					setProgressValue(0);
					if (err.response.status === 400) {
						toast.warning(`تاكد من البيانات المدخلة`);
					} else {
						toast.error(`${err.response.status} تاكد من البيانات المدخلة`);
					}
				});
		} else {
			toast.error(`تاكد من البيانات المدخلة`);
		}
	};

	const handleSubmitShowHide = (row) => {
		if (window.confirm("هل تريد تغيير حالة الصورة اخفاء/اظهار ؟")) {
			axiosInstance
				.patch(`/admin/main-page-slider/${row.id}`, { is_deleted: !row.is_deleted })
				.then((res) => {
					// setRender(res.data.id);
					updateFormData(null);
					HandleRender();
					toast.success("تم تغيير حالة الصورة");
				})
				.catch((err) => {
					toast.error(` تاكد من البيانات المدخلة`);
				});
		}
	};
	const handleSubmitDelete = (row) => {
		if (window.confirm("هل تريد حذف الصورة نهائياََ ؟")) {
			axiosInstance
				.delete(`/admin/main-page-slider/${row.id}`)
				.then((res) => {
					// setRender(res.data.id);
					updateFormData(null);
					HandleRender();
					toast.success("تم حذف الصورة");
				})
				.catch((err) => {
					toast.error("حدث خطأ!");
				});
		}
	};

	const onOpenModal = () => {
		setOpen(true);
	};

	const onCloseModal = () => {
		setOpen(false);
	};

	const columns = [
		{
			id: "id",
			name: "Id",
			selector: (row) => row.id,
			style: {
				textAlign: "center",
			},
		},

		{
			id: "title_ar",
			name: "الاسم عربى",
			sortable: true,
			selector: (row) => row.title_ar,
			style: {
				textAlign: "center",
			},
		},
		{
			id: "title_en",
			name: "الاسم انجليزي",
			sortable: true,
			selector: (row) => row.title_en,
			style: {
				textAlign: "center",
			},
		},
		{
			id: "description_ar",
			name: "الوصف عربى",
			sortable: true,
			selector: (row) => row.description_ar,
			style: {
				textAlign: "center",
			},
		},
		{
			id: "description_en",
			name: "الوصف انجليزي",
			sortable: true,
			selector: (row) => row.description_en,
			style: {
				textAlign: "center",
			},
		},

		{
			id: "is_deleted",
			name: "مخفى",
			cell: (row) => {
				return <div>{row.is_deleted.toString() === "true" ? "مخفى" : "مرئى"}</div>;
			},
			style: {
				textAlign: "center",
			},
		},
	];

	columns.push({
		name: <b>عمليات</b>,
		id: "delete",
		accessor: (str) => "delete",
		cell: (row, index) => (
			<div>
				<span
					onClick={(e) => {
						setRowData(row);
						handleSubmitShowHide(row);
					}}>
					<i
						className="fa fa-refresh"
						style={{
							width: 35,
							fontSize: 20,
							padding: 11,
							color: "#e4566e",
						}}></i>
				</span>
				<span
					onClick={(e) => {
						setRowData(row);
						handleSubmitDelete(row);
					}}>
					<i
						className="fa fa-trash"
						style={{
							width: 35,
							fontSize: 20,
							padding: 11,
							color: "#e4566e",
						}}></i>
				</span>

				<span>
					<i
						onClick={(e) => {
							setRowData(row);
							onOpenModal();
						}}
						className="fa fa-pencil"
						style={{
							width: 35,
							fontSize: 20,
							padding: 11,
							color: "rgb(40, 167, 69)",
						}}></i>
					<Modal
						isOpen={open}
						toggle={onCloseModal}
						style={{ overlay: { opacity: 0.1 } }}>
						<ModalHeader toggle={onCloseModal}>
							<h6 className="modal-title f-w-600" id="exampleModalLabel2">
								تعديل
							</h6>
						</ModalHeader>
						<ModalBody>
							<Form>
								<FormGroup>
									<Label htmlFor="recipient-name" className="col-form-label">
										الاسم عربى :
									</Label>
									<Input
										type="text"
										name="title_ar"
										className="form-control"
										onChange={handleChange}
										defaultValue={rowData?.title_ar}
									/>
								</FormGroup>
								<FormGroup>
									<Label htmlFor="recipient-name" className="col-form-label">
										الاسم انجليزي :
									</Label>
									<Input
										type="text"
										name="title_en"
										className="form-control"
										onChange={handleChange}
										defaultValue={rowData?.title_en}
									/>
								</FormGroup>
								<FormGroup>
									<Label htmlFor="recipient-name" className="col-form-label">
										الوصف عربى :
									</Label>
									<Input
										type="text"
										name="description_ar"
										className="form-control"
										onChange={handleChange}
										defaultValue={rowData?.description_ar}
									/>
								</FormGroup>
								<FormGroup>
									<Label htmlFor="recipient-name" className="col-form-label">
										الوصف انجليزي :
									</Label>
									<Input
										type="text"
										name="description_en"
										className="form-control"
										onChange={handleChange}
										defaultValue={rowData?.description_en}
									/>
								</FormGroup>
								<FormGroup>
									<Label htmlFor="message-text" className="col-form-label">
										صورة :<p>مقاس الصورة (px) 1920 * 718</p>
									</Label>
									<Input
										name="url"
										className="form-control"
										id="validationCustom02"
										type="file"
										onChange={handleChangeImage}
									/>
								</FormGroup>
								<div className="card">
									<ProgressBar color="secondary" value={progressValue}></ProgressBar>
								</div>
							</Form>
						</ModalBody>
						<ModalFooter className="d-flex justify-content-between">
							<Button
								type="button"
								color="secondary"
								onClick={(e) => {
									handleSubmit(e);
								}}>
								تعديل
							</Button>
							<Button
								type="button"
								color="primary"
								onClick={() => onCloseModal("VaryingMdo")}>
								اغلاق
							</Button>
						</ModalFooter>
					</Modal>
				</span>
			</div>
		),
		style: {
			textAlign: "center",
		},
		sortable: false,
	});

	columns.push({
		name: <b>صورة</b>,
		cell: (row, index) => (
			<div>
				<Media src={`${row.url}`} alt="" className="img-fluid" />
			</div>
		),
		style: {
			textAlign: "center",
		},
		sortable: false,
	});

	return (
		<div>
			<Fragment>
				<DataTable
					data={myData}
					columns={columns}
					className={myClass}
					pagination={pagination}
					striped={true}
					center={true}
				/>

				<ToastContainer autoClose={2000} rtl />
			</Fragment>
		</div>
	);
};

export default SliderDataTabel;
