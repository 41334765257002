import React, { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
	Button,
	Form,
	FormGroup,
	Input,
	Label,
	Media,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Tooltip,
} from "reactstrap";
import axiosInstance from "../axios";
import FilterComponent from "./FilterComponent";
import { useEffect } from "react";
import { ProgressBar } from "primereact/progressbar";

const DatatableCategory = ({ myData, myClass, pagination, HandleRender }) => {
	const [rowData, setRowData] = useState([]);
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [render, setRender] = useState(false);
	// const [checkedValues, setCheckedValues] = useState([]);
	// const [data, setData] = useState(myData);
	const [filteredData, setFilteredData] = useState(myData);

	// filter area start here
	const [filterText, setFilterText] = useState("");

	function isArabic(text) {
		let arabic = /[\u0600-\u06FF]/;
		// var pattern = /[\u0600-\u06FF\u0750-\u077F]/;
		return arabic.test(text);
	}
	useEffect(() => {
		let filteredItems = [];
		if (filterText === "") {
			setFilteredData(myData);
		} else {
			if (isArabic(filterText)) {
				filteredItems = myData.filter(
					(item) => item.name_ar && item.name_ar.includes(filterText)
				);
			} else {
				filteredItems = myData.filter(
					(item) =>
						item.name_en &&
						item.name_en.toLowerCase().includes(filterText.toLowerCase())
				);
			}
			setFilteredData(filteredItems);
		}
	}, [filterText, myData]);

	const subHeaderComponentMemo = (
		<FilterComponent
			onFilter={(e) => {
				setFilterText(e.target.value);
			}}
			// onClear={handleClear}
			filterText={filterText}
		/>
	);
	// filter area end here
	const [formData, updateFormData] = useState(null);
	const [image, setImage] = useState(null);

	const handleChange = (e) => {
		updateFormData({
			...formData,
			// Trimming any whitespace
			[e.target.name]: e.target.value,
		});
	};

	const handleChangeImage = (e) => {
		setImage(e.target.files[0]);
		// Trimming any whitespace
	};
	const [progressValue, setProgressValue] = useState(0);

	const config = {
		headers: { "Content-Type": "multipart/form-data" },
		onUploadProgress: function (progressEvent) {
			var percentCompleted = Math.round(
				(progressEvent.loaded * 100) / progressEvent.total
			);
			setProgressValue(percentCompleted);
		},
	};
	const handleSubmit = (e) => {
		if (formData !== null || image !== null) {
			const formData1 = new FormData();
			if (formData?.name_ar) {
				formData1.append("name_ar", formData.name_ar);
			}
			if (formData?.name_en) {
				formData1.append("name_en", formData.name_en);
			}
			if (formData?.description_ar === "" || formData?.description_ar) {
				formData1.append("description_ar", formData.description_ar);
			}
			if (formData?.description_ar === "" || formData?.description_en) {
				formData1.append("description_en", formData.description_en);
			}
			if (image) {
				formData1.append("url", image);
			}

			e.preventDefault();
			axiosInstance
				.patch(`/products/category/${rowData.id}`, formData1, config)
				.then((res) => {
					setProgressValue(0);
					updateFormData(null);
					HandleRender();
					if (res.status === 200) {
						toast.success("تم تعديل القسم بنجاح");
						onCloseModal("VaryingMdo");
					}
				})
				.catch((err) => {
					setProgressValue(0);
					if (err.response.status === 400) {
						toast.warning(`تاكد من البيانات المدخلة`);
					} else {
						toast.error(`${err.response.status} تاكد من البيانات المدخلة`);
					}
				});
		} else {
			toast.error("من فضلك ادخل البيانات المراد تغييرها");
		}
	};
	const handleSubmitDelete = (row) => {
		if (window.confirm("هل تريد تغيير حالة القسم اخفاء/اظهار ؟")) {
			axiosInstance
				.patch(`/products/category/${row.id}`, { is_deleted: !row.is_deleted })
				.then((res) => {
					// setRender(res.data.id);
					updateFormData(null);
					HandleRender();
					if (res.status === 200) {
						toast.success("تم اخفاء/اظهار القسم بنجاح", {});
					}
				})
				.catch((err) => {
					toast.error(`${err.response.status} تاكد من البيانات المدخلة`);
					console.error(err);
				});
		}
	};

	const onOpenModal = () => {
		setOpen(true);
	};
	const onCloseModal = () => {
		setOpen(false);
	};
	const columns = [
		{
			id: "id",
			name: "Id",
			sortable: true,
			selector: (row) => row.id,
			style: {
				textAlign: "center",
			},
		},
	];
	columns.push({
		name: <b>عمليات</b>,
		id: "delete",
		accessor: (str) => "delete",
		cell: (row, index) => (
			<div>
				<span
					onClick={(e) => {
						setRowData(row);
						handleSubmitDelete(row);
					}}>
					<i
						className="fa fa-eye"
						style={{
							width: 35,
							fontSize: 20,
							padding: 11,
							color: "#e4566e",
						}}></i>
				</span>

				<span>
					<i
						onClick={(e) => {
							setRowData(row);
							onOpenModal();
						}}
						className="fa fa-pencil"
						style={{
							width: 35,
							fontSize: 20,
							padding: 11,
							color: "rgb(40, 167, 69)",
						}}></i>
					<Modal
						isOpen={open}
						toggle={onCloseModal}
						style={{ overlay: { opacity: 0.1 } }}>
						<ModalHeader toggle={onCloseModal}>
							<h6 className="modal-title f-w-600" id="exampleModalLabel2">
								تعديل القسم
							</h6>
						</ModalHeader>
						<ModalBody>
							<Form>
								<FormGroup>
									<Label htmlFor="recipient-name" className="col-form-label">
										الاسم عربى :
									</Label>
									<Input
										type="text"
										name="name_ar"
										className="form-control"
										onChange={handleChange}
										defaultValue={rowData?.name_ar}
									/>
								</FormGroup>
								<FormGroup>
									<Label htmlFor="recipient-name" className="col-form-label">
										الاسم انجليزي :
									</Label>
									<Input
										type="text"
										name="name_en"
										className="form-control"
										onChange={handleChange}
										defaultValue={rowData?.name_en}
									/>
								</FormGroup>
								<FormGroup>
									<Label htmlFor="recipient-name" className="col-form-label">
										الوصف عربى :
									</Label>
									<Input
										type="text"
										name="description_ar"
										className="form-control"
										onChange={handleChange}
										defaultValue={rowData?.description_ar}
									/>
								</FormGroup>
								<FormGroup>
									<Label htmlFor="recipient-name" className="col-form-label">
										الوصف انجليزي :
									</Label>
									<Input
										type="text"
										name="description_en"
										className="form-control"
										onChange={handleChange}
										defaultValue={rowData?.description_en}
									/>
								</FormGroup>
								<FormGroup>
									<Label htmlFor="message-text" className="col-form-label">
										صورة القسم :<span>مقاس الصورة 500 * 500</span>
									</Label>
									<Input
										name="url"
										className="form-control"
										id="validationCustom02"
										type="file"
										onChange={handleChangeImage}
									/>
								</FormGroup>
								<div className="card">
									<ProgressBar color="secondary" value={progressValue}></ProgressBar>
								</div>
							</Form>
						</ModalBody>
						<ModalFooter className="d-flex justify-content-between">
							<Button
								type="button"
								color="secondary"
								onClick={(e) => {
									handleSubmit(e);
								}}>
								حفظ
							</Button>
							<Button
								type="button"
								color="primary"
								onClick={() => onCloseModal("VaryingMdo")}>
								اغلاق
							</Button>
						</ModalFooter>
					</Modal>
				</span>
			</div>
		),
		style: {
			textAlign: "center",
		},
		sortable: false,
	});
	columns.push(
		{
			id: "name_ar",
			name: "الاسم عربى",
			sortable: true,

			selector: (row) => row.name_ar,
			style: {
				textAlign: "center",
			},
		},
		{
			id: "name_en",
			name: "الاسم انجليزي",
			sortable: true,

			selector: (row) => row.name_en,
			style: {
				textAlign: "center",
			},
		},
		{
			id: "description_ar",
			name: "الوصف عربى",
			sortable: true,

			selector: (row) => row.description_ar,
			style: {
				textAlign: "center",
			},
		},
		{
			id: "description_en",
			name: "الوصف انجليزي",
			sortable: true,

			selector: (row) => row.description_en,
			style: {
				textAlign: "center",
			},
		},

		{
			id: "created_at",
			name: "انشأ فى",
			selector: (row) => row.created_at.split("T")[0],
			style: {
				textAlign: "center",
			},
		},
		{
			id: "updated_at",
			name: "اخر التعديل",
			selector: (row) => row.updated_at.split("T")[0],
			style: {
				textAlign: "center",
			},
		},
		{
			id: "is_deleted",
			name: "مخفى",
			cell: (row) => {
				return <div>{row.is_deleted.toString() === "true" ? "مخفى" : "مرئى"}</div>;
			},
			style: {
				textAlign: "center",
			},
		}
	);

	columns.push({
		name: <b>صورة</b>,
		cell: (row, index) => (
			<div>
				<Media src={`${row.url}`} alt="" className="img-fluid" />
			</div>
		),
		style: {
			textAlign: "center",
		},
		sortable: false,
	});

	return (
		<div>
			<Fragment>
				<DataTable
					data={filteredData}
					columns={columns}
					className={myClass}
					pagination={pagination}
					striped={true}
					center={true}
					subHeader
					subHeaderComponent={subHeaderComponentMemo}
				/>

				<ToastContainer autoClose={2000} rtl />
			</Fragment>
		</div>
	);
};

export default DatatableCategory;
