import axios from "axios";
// // work

const baseURL = "https://api.el-yousr-fabrics.com/";
// const baseURL = "http://192.168.1.150:8000/";
// const baseURL =
// "https://e-commerce-back-jellyfish-app-4n2kq.ondigitalocean.app/";

const axiosInstance = axios.create({
	baseURL: baseURL,
	timeout: 60000,
	headers: {
		Authorization: localStorage.getItem("access_token")
			? "Bearer " + localStorage.getItem("access_token")
			: null,
		"Content-Type": "application/json",
		accept: "application/json",
	},
});

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;
		if (typeof error.response === "undefined") {
			alert(
				"Bad Network Connection. " +
					"Looks like CORS might be the problem. " +
					"Sorry about this - we will get it fixed shortly."
			);
			return Promise.reject(error);
		}

		let login_attempt_count = localStorage.getItem("login_attempt_count") || 0;
		if (login_attempt_count >= 2) {
			localStorage.clear();
		}

		if (
			error.response.status === 401 &&
			originalRequest.url === "/token/refresh"
		) {
			localStorage.setItem(
				"login_attempt_count",
				parseInt(login_attempt_count) + 1
			);
			window.location.href = "/auth/login/";
			return Promise.reject(error);
		}

		// if (error.response.status === 403) {
		// 	alert("غير مسموح");
		// 	return Promise.reject(error);
		// }
		if (error.response.status === 500) {
			alert(" حدث خطأ عام برجاء التواصل معنا");
			return Promise.reject(error);
		}
		if (error.response.status === 403) {
			window.location.href = "/403";
			return Promise.reject(error);
		}

		if (
			error.response.data.detail !==
				"No active account found with the given credentials" &&
			error.response.status === 401
		) {
			if (
				localStorage.getItem("refresh_token") === null &&
				localStorage.getItem("access_token") === null
			) {
				window.location.href = "/auth/login/";
			}

			const refreshToken = localStorage.getItem("refresh_token");
			localStorage.setItem(
				"login_attempt_count",
				parseInt(login_attempt_count) + 1
			);

			if (refreshToken) {
				const tokenParts = JSON.parse(window.atob(refreshToken.split(".")[1]));

				// exp date in token is expressed in seconds, while now() returns milliseconds:
				const now = Math.ceil(Date.now() / 1000);
				if (tokenParts.exp > now) {
					return axiosInstance
						.post("/token/refresh", { refresh: refreshToken })
						.then((response) => {
							localStorage.setItem("access_token", response.data.access);
							if (response.data.refresh) {
								localStorage.setItem("refresh_token", response.data.refresh);
							}
							localStorage.setItem("login_attempt_count", 0);

							axiosInstance.defaults.headers["Authorization"] =
								"Bearer " + response.data.access;
							originalRequest.headers["Authorization"] =
								"Bearer " + response.data.access;

							return axiosInstance(originalRequest);
						})
						.catch((err) => {});
				} else {
					window.location.href = "/auth/login/";
				}
			} else {
				window.location.href = "/auth/login/";
			}
		}

		// specific error handling done elsewhere
		return Promise.reject(error);
	}
	// test
);
export default axiosInstance;
