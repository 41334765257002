import React from "react";
import { useState } from "react";

import axiosInstance from "../../axios";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { Button } from "reactstrap";

import { Steps } from "primereact/steps";
import { useEffect } from "react";

function ChangeOrderStatus({
	HandleRender,
	colorData,
	direction,
	orderStatus,
	...args
}) {
	const { orderId } = useParams();

	const [activeIndex, setActiveIndex] = useState(0);
	const [nextStatus, setNextStatus] = useState("pending");
	const items = [
		{
			label: "Pending",
			command: (event) => {
				toast.current.show({
					severity: "info",
					summary: "First Step",
					detail: event.item.label,
				});
			},
		},

		{
			label: "Shipped",
			command: (event) => {
				toast.current.show({
					severity: "info",
					summary: "Third Step",
					detail: event.item.label,
				});
			},
		},
		{
			label: "Delivered",
			command: (event) => {
				toast.current.show({
					severity: "info",
					summary: "Last Step",
					detail: event.item.label,
				});
			},
		},
	];

	useEffect(() => {
		if (orderStatus) {
			if (orderStatus === "pending") {
				setActiveIndex(0);
				setNextStatus("shipped");
			} else if (orderStatus === "shipped") {
				setActiveIndex(1);
				setNextStatus("delivered");
			} else if (orderStatus === "delivered") {
				setActiveIndex(2);
			} else {
				setActiveIndex(999);
				setNextStatus("");
			}
		}
	}, [orderStatus]);
	function changeStatus(e) {
		let x = window.confirm(
			`هل انت متأكد من تغيير حالة الطلب رقم ${orderId} الى ${nextStatus}`
		);

		if (x === true) {
			setSelectedStatus(e.value);
			axiosInstance
				.patch(`/admin/order-change-status/${orderId}`)
				.then((res) => {
					HandleRender();
					toast.success("تم تغيير حالة الطلب بنجاح");
				})
				.catch((err) => {
					if (err.response.status === 400) {
						toast.warning(`لا يمكن تغيير الحالة`);
					} else {
						toast.error(`لا يمكن تغيير الحالة`);
					}
				});
		}
	}
	function changeStatusAdmin(e) {
		let x = window.confirm(`هل انت متأكد من اعادة فتح الطلبية`);

		if (x === true) {
			setSelectedStatus(e.value);
			axiosInstance
				.patch(`/admin/order-change-status/${orderId}`, {
					reset: true,
				})
				.then((res) => {
					HandleRender();
					toast.success("تم تغيير حالة الطلب بنجاح");
				})
				.catch((err) => {
					if (err.response.status === 400) {
						toast.warning(`لا يمكن تغيير الحالة`);
					} else {
						toast.error(`لا يمكن تغيير الحالة`);
					}
				});
		}
	}

	const [selectedStatus, setSelectedStatus] = useState(null);
	const cities = [
		{ name: "pending", code: "pending" },
		{ name: "delivered", code: "delivered" },
		{ name: "shipped", code: "shipped" },
		{ name: "canceled", code: "canceled" },
		{ name: "refunded", code: "refunded" },
	];
	//	onSelect={(e) => setActiveIndex(e.index)}

	return (
		<>
			<ToastContainer rtl autoClose={1000} newestOnTop />

			{/* <div>
				<Dropdown
					value={selectedStatus}
					onChange={(e) => {
						changeStatus(e);
					}}
					options={cities}
					optionLabel="name"
					placeholder="اختر حالة الطلبية"
					className="w-full md:w-14rem"
				/>
			</div> */}
			<div className="">
				{}
				<Steps model={items} activeIndex={activeIndex} readOnly={true} />
				<div className="mt-4 text-center">
					<Button
						onClick={(e) => {
							changeStatus(e);
						}}>
						تغيير
					</Button>
				</div>

				{localStorage.getItem("admin_token") === "true" ||
				localStorage.getItem("admin_token") === true ? (
					<div className="mt-4 text-center">
						<Button
							color="danger"
							onClick={(e) => {
								changeStatusAdmin(e);
							}}>
							اعادة فتح
						</Button>
					</div>
				) : (
					""
				)}
			</div>
		</>
	);
}

export default ChangeOrderStatus;
