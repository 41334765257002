import React from "react";
// import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User, Settings } from "react-feather";
import { Button, Col, Form, Input, Label, Row, Table } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import axiosInstance from "../axios";
import { Home } from "react-feather";
import { TabView, TabPanel } from "primereact/tabview";
import { ToastContainer, toast } from "react-toastify";
const TabsetContact = ({ render1 }) => {
	const [data, setData] = useState(null);
	const [render, setRender] = useState(false);
	useEffect(() => {
		axiosInstance.get(`/admin/contact-us-main-page`).then((res) => {
			setData(res.data);
		});
	}, [render, render1]);

	const [formData, updateFormData] = useState("");

	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmitUpdateContactData = (e, id) => {
		e.preventDefault();
		if (formData) {
			axiosInstance
				.patch(`/admin/contact-us-main-page/${id}`, formData)
				.then((res) => {
					updateFormData(null);
					alert("changed success");
				})
				.catch((err) => {
					if (err.response.status === 400) {
						toast.warning(`تاكد من البيانات المدخلة`);
					} else {
						toast.error(`${err.response.status} تاكد من البيانات المدخلة`);
					}
				});
		}
	};
	const handleDelete = (e, id) => {
		e.preventDefault();
		if (window.confirm("هل انت متاكد من الحذف ؟")) {
			axiosInstance
				.delete(`/admin/contact-us-main-page/${id}`)
				.then((res) => {
					updateFormData(null);
					// HandleRender();
					setRender(!render);
					toast.error("deleted success");
				})
				.catch((err) => {});
		}
	};

	return (
		<div>
			<ToastContainer autoClose={2000} rtl />
			<TabView>
				{data?.map((item) => (
					<TabPanel header={item.title_ar} key={item.id}>
						<Row>
							<Col>
								<Form
									className="theme-form"
									onSubmit={(e) => {
										handleSubmitUpdateContactData(e, item.id);
									}}>
									<div key={item.id}>
										<Row className="m-2">
											<Col md="6">
												<Label className="form-label" for="old_password">
													اسم الفرع عربى
												</Label>
												<Input
													type="text"
													className="form-control"
													id="home-ploat"
													// placeholder="الرقم السرى القديم"
													defaultValue={item?.title_ar}
													name="title_ar"
													onChange={handleChange}
												/>
											</Col>
											<Col md="6">
												<Label className="form-label" for="old_password">
													اسم الفرع انجليزي
												</Label>
												<Input
													type="text"
													className="form-control"
													id="home-ploat"
													// placeholder="الرقم السرى القديم"
													defaultValue={item?.title_en}
													name="title_en"
													onChange={handleChange}
												/>
											</Col>
										</Row>
										<Row className="m-2">
											<Col md="6">
												<Label className="form-label" for="old_password">
													رقم التلفون 1
												</Label>
												<Input
													type="text"
													className="form-control"
													id="home-ploat"
													// placeholder="الرقم السرى القديم"
													defaultValue={item?.phone_1}
													name="phone_1"
													onChange={handleChange}
												/>
											</Col>
											<Col md="6">
												<Label className="form-label" for="old_password">
													2
												</Label>
												<Input
													type="text"
													className="form-control"
													id="home-ploat"
													// placeholder="الرقم السرى القديم"
													defaultValue={item?.phone_2}
													name="phone_2"
													onChange={handleChange}
												/>
											</Col>
											<Col md="6">
												<Label className="form-label" for="old_password">
													رقم التلفون 3
												</Label>
												<Input
													type="text"
													className="form-control"
													id="home-ploat"
													// placeholder="الرقم السرى القديم"
													defaultValue={item?.phone_3}
													name="phone_3"
													onChange={handleChange}
												/>
											</Col>
											<Col md="6">
												<Label className="form-label" for="old_password">
													رقم التلفون 4
												</Label>
												<Input
													type="text"
													className="form-control"
													id="home-ploat"
													// placeholder="الرقم السرى القديم"
													defaultValue={item?.phone_4}
													name="phone_4"
													onChange={handleChange}
												/>
											</Col>
											<Col md="6">
												<Label className="form-label" for="old_password">
													رقم التلفون 5
												</Label>
												<Input
													type="text"
													className="form-control"
													id="home-ploat"
													// placeholder="الرقم السرى القديم"
													defaultValue={item?.phone_5}
													name="phone_5"
													onChange={handleChange}
												/>
											</Col>
										</Row>
										<Row className="m-2">
											<Col md="6">
												<Label className="form-label" for="old_password">
													العنوان
												</Label>
												<Input
													type="text"
													className="form-control"
													id="home-ploat"
													// placeholder="الرقم السرى القديم"
													defaultValue={item?.address}
													name="address"
													onChange={handleChange}
												/>
											</Col>
											<Col md="6">
												<Label className="form-label" for="old_password">
													الخريطة
												</Label>
												<Input
													className="form-control"
													id="home-ploat"
													// placeholder="الرقم السرى القديم"
													defaultValue={item?.google_map_src}
													name="google_map_src"
													onChange={handleChange}
													type="url"
												/>
											</Col>
										</Row>
									</div>
									<div className="col-md-12 mt-3">
										<Button className="btn btn-sm btn-solid" type="submit">
											تعديل البانات
										</Button>
									</div>
								</Form>
							</Col>
						</Row>
						<div className="d-flex justify-content-end">
							<Button
								color="primary"
								className="btn btn-sm btn-solid"
								onClick={(e) => handleDelete(e, item.id)}>
								حذف الفرع
							</Button>
						</div>
					</TabPanel>
				))}
			</TabView>
		</div>
	);
};

export default TabsetContact;
