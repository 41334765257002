import React from "react";

import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "reactstrap";
import axiosInstance from "../axios";
import { toast } from "react-toastify";
import { useEffect } from "react";
function AddPermission({ userPermissionsData, handleRender }) {
	const [visible, setVisible] = useState(false);
	const [selectedPermissions, setSelectedPermissions] = useState([]);
	const [permissions, setPermissions] = useState([]);
	useEffect(() => {
		console.log(userPermissionsData, "userPermissionsData");
		console.log(
			userPermissionsData?.staff_permissions[0]?.permission.id,
			"userPermissionsData"
		);
		let permissionGiven = userPermissionsData?.staff_permissions;
		axiosInstance.get(`/admin/permission-list`).then((res) => {
			console.log(res.data, "res");
			var myArrayFiltered = [];
			for (var i = 0; i < res.data.length; i++) {
				for (var j = 0; j < permissionGiven.length; j++) {
					if (res.data[i].id === permissionGiven[j].permission.id) {
						myArrayFiltered.push(res.data[i]);
					}
				}
			}
			console.log(myArrayFiltered, "myArrayFiltered");
			let finalData = [];
			for (let i = 0; i < res.data.length; i++) {
				if (myArrayFiltered.includes(res.data[i])) {
				} else {
					finalData.push(res.data[i]);
				}
			}
			setPermissions(finalData);
		});
	}, []);

	// const permissions = [
	// 	{ name: "تسجيل", code: "create" },
	// 	{ name: "عمليات", code: "operation" },
	// 	{ name: "تقارير", code: "report" },
	// ];
	const footer = (
		<div>
			<Button
				color="secondary"
				onClick={(e) => {
					handleSubmit(e);
				}}>
				حفظ
			</Button>
		</div>
	);

	const handleSubmit = (e) => {
		e.preventDefault();
		let tempPermission = [];
		for (let i = 0; i < selectedPermissions.length; i++) {
			tempPermission.push(selectedPermissions[i].id);
		}
		axiosInstance
			.post(`/admin/staff-permission-create/${userPermissionsData.id}`, {
				permission_list: tempPermission,
			})
			.then((res) => {
				setVisible(false);
				toast.success("تم تحديث الصلاحية بنجاح");
				handleRender();
			})
			.catch((err) => {
				console.log(err.response.data.error);
				if (err.response.data.error === "permission already exists") {
					alert("تم اعطاء صلاحية بالفعل");
				}
			});
	};
	// const handleSubmit = (e) => {
	// 	e.preventDefault();
	// 	for (let i = 0; i < selectedPermissions.length; i++) {
	// 		axiosInstance
	// 			.post(`/admin/staff-permission-list/${userPermissionsData.id}`, {
	// 				permission_id: selectedPermissions[i].id,
	// 			})
	// 			.then((res) => {
	// 				setVisible(false);
	// 				toast.success("تم اضافة المستخدم بنجاح");
	// 				handleRender();
	// 			})
	// 			.catch((err) => {
	// 				console.log(err.response.data.error);

	// 				if (err.response.data.error === "permission already exists") {
	// 					alert("تم اعطاء صلاحية بالفعل");
	// 				}
	// 			});
	// 	}
	// };

	return (
		<div className="card flex justify-content-center">
			<Button
				color="primary"
				icon="pi pi-external-link"
				onClick={(e) => {
					setVisible(true);
				}}>
				اعطاء صلاحية
			</Button>
			<Dialog
				header="الصلاحيات المتاحة"
				footer={footer}
				visible={visible}
				style={{ width: "50vw" }}
				onHide={() => setVisible(false)}>
				<MultiSelect
					value={selectedPermissions}
					onChange={(e) => setSelectedPermissions(e.value)}
					options={permissions}
					optionLabel="name_ar"
					placeholder="اختر الصلاحيات"
					maxSelectedLabels={3}
					className="w-full d-flex"
					emptyFilterMessage="test"
				/>
			</Dialog>
		</div>
	);
}

export default AddPermission;
