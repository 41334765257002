import React, { Fragment, useState } from "react";
import DataTable from "react-data-table-component";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Media } from "reactstrap";
import axiosInstance from "../axios";

const BestSellerDataTable = ({ myData, myClass, pagination, HandleRender }) => {
	const handleSubmitDelete = (row) => {
		if (window.confirm(`هل تريد حذف  ${row.product.name_ar} ؟`)) {
			axiosInstance
				.delete(`/admin/main-page-best-seller/${row.id}`)
				.then((res) => {
					toast.success("تم الحذف بنجاح");
					HandleRender();
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};

	const [rowDataID, setRowDataID] = useState(null);

	const columns = [
		{
			id: "id",
			name: "Id",
			selector: (row) => row.id,
			style: {
				textAlign: "center",
			},
		},

		{
			id: "name_ar",
			name: "الاسم عربى",
			selector: (row) => row.product.name_ar,
			style: {
				textAlign: "center",
			},
		},
		{
			id: "title_en",
			name: "الاسم انجليزي",
			selector: (row) => row.product.name_en,
			style: {
				textAlign: "center",
			},
		},
	];

	columns.push({
		name: <b>عمليات</b>,
		id: "delete",
		accessor: (str) => "delete",
		cell: (row, index) => (
			<div>
				<span>
					<i
						onClick={(e) => {
							setRowDataID(row.id);
							handleSubmitDelete(row);
						}}
						className="fa fa-trash"
						style={{
							width: 35,
							fontSize: 20,
							padding: 11,
							color: "rgb(40, 167, 69)",
						}}></i>
				</span>
			</div>
		),
		style: {
			textAlign: "center",
		},
		sortable: false,
	});

	columns.push({
		name: <b>صورة</b>,
		cell: (row, index) => (
			<div>
				<Media
					src={`${row.product.url}`}
					alt=""
					className="img-fluid"
					width="150px"
				/>
			</div>
		),
		style: {
			textAlign: "center",
			justifyContent: "center",
		},
		sortable: false,
	});

	return (
		<div>
			<Fragment>
				<DataTable
					data={myData}
					columns={columns}
					className={myClass}
					pagination={pagination}
					striped={true}
					center={true}
				/>

				<ToastContainer autoClose={2000} rtl />
			</Fragment>
		</div>
	);
};

export default BestSellerDataTable;
