import React from "react";
import { useState } from "react";

import axiosInstance from "../../axios";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { Button, Col } from "reactstrap";

function ChangePaymentStatus({ HandleRender, colorData, direction, ...args }) {
	const { orderId } = useParams();
	function changePaymentStatus(e) {
		let x = window.confirm(
			`هل انت متأكد من تغيير حالة الطلب رقم ${orderId} الى "paid"`
		);

		if (x === true) {
			setSelectedStatus(e.value);
			axiosInstance
				.patch(`/admin/order-change-payment-status/${orderId}`)
				.then((res) => {
					HandleRender();
					toast.success("تم تغيير حالة الطلب بنجاح");
				})
				.catch((err) => {
					if (err.response.status === 400) {
						toast.warning(`لا يمكن تغيير الحالة`);
					} else {
						toast.error(`لا يمكن تغيير الحالة`);
					}
				});
		}
	}
	function changePaymentStatusAdmin(e) {
		let x = window.confirm(`هل انت متاكد؟`);

		if (x === true) {
			setSelectedStatus(e.value);
			axiosInstance
				.patch(`/admin/order-change-payment-status/${orderId}`, {
					reset: true,
				})
				.then((res) => {
					HandleRender();
					toast.success("تم تغيير حالة الطلب بنجاح");
				})
				.catch((err) => {
					if (err.response.status === 400) {
						toast.warning(`لا يمكن تغيير الحالة`);
					} else {
						toast.error(`لا يمكن تغيير الحالة`);
					}
				});
		}
	}

	const [selectedStatus, setSelectedStatus] = useState(null);
	const cities = [
		{ name: "paid", code: "paid" },
		{ name: "unpaid", code: "unpaid" },
	];

	return (
		<>
			<ToastContainer rtl autoClose={1000} newestOnTop />
			<div className="gap-1 d-flex">
				<div className=" text-center">
					<Button
						onClick={(e) => {
							changePaymentStatus(e);
						}}>
						تغيير
					</Button>
				</div>
				{localStorage.getItem("admin_token") === "true" ||
				localStorage.getItem("admin_token") === true ? (
					<div className=" text-center">
						<Button
							color="danger"
							onClick={(e) => {
								changePaymentStatusAdmin(e);
							}}>
							اعادة فتح
						</Button>
					</div>
				) : (
					""
				)}
			</div>

			{/* <div>
				<Dropdown
					value={selectedStatus}
					onChange={(e) => {
						changeStatus(e);
					}}
					options={cities}
					optionLabel="name"
					placeholder="اختر حالة الدفع"
					className="w-full md:w-14rem"
				/>
			</div> */}
		</>
	);
}

export default ChangePaymentStatus;
