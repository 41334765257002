import React, { Fragment, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import "react-toastify/dist/ReactToastify.css";

import OrderReportFilterComponent from "../reports/components/OrderReportFilterComponent";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Tag } from "primereact/tag";
import { classNames } from "primereact/utils";
import { Button } from "reactstrap";
import axiosInstance from "../axios";
import { Dropdown } from "primereact/dropdown";
const DatatableOrders = ({ myData = [] }) => {
	const [first, setFirst] = useState([0, 0, 0]);
	const [rows, setRows] = useState([50]);
	const [countPerPage, setCountPerPage] = useState(10);
	const navigate = useNavigate();
	const [totalCountFromApi, setTotalCountFromApi] = useState(null);
	const [nextPage, setNextPage] = useState(null);
	const [prevPage, setPrevPage] = useState(null);
	const [loading, setLoading] = useState(false);
	const [filteredData, setFilteredData] = useState(myData);
	const [searchParams, setSearchParams] = useSearchParams();
	const [render, setRender] = useState(false);

	useEffect(() => {
		axiosInstance
			.get(`/admin/order-list?&page_size=${countPerPage}`)
			.then((res) => {
				setFilteredData(res.data.results);
				setPrevPage(res.data.previous);
				setNextPage(res.data.next);
				setLoading(false);
				setTotalCountFromApi(res.data.count);
			});
	}, [render]);

	// filter area end here

	const onPageChange = (e, index) => {
		setFirst(first.map((f, i) => (index === i ? e.first : f)));
		setRows(rows.map((r, i) => (index === i ? e.rows : r)));
	};

	const template1 = {
		layout: "PrevPageLink PageLinks NextPageLink RowsPerPageDropdown ",
		PrevPageLink: (options) => {
			return (
				<button
					className={classNames(options.className, "border-round")}
					type="button"
					onClick={(e) => {
						setLoading(true);
						options.onClick(e);
						setLoading(true);
						axiosInstance
							.get(`admin/order-list?&page=${prevPage}&page_size=${countPerPage}`)
							.then((res) => {
								setFilteredData(res.data.results);
								setPrevPage(res.data.previous);
								setNextPage(res.data.next);
								setLoading(false);
								setTotalCountFromApi(res.data.count);
							});
					}}
					disabled={options.disabled}>
					<span className="p-3">Previous</span>
				</button>
			);
		},
		NextPageLink: (options) => {
			return (
				<button
					className={classNames(options.className, "border-round")}
					type="button"
					onClick={(e) => {
						options.onClick(e);
						setLoading(true);
						axiosInstance
							.get(`admin/order-list?&page=${nextPage}&page_size=${countPerPage}`)
							.then((res) => {
								setFilteredData(res.data.results);
								setPrevPage(res.data.previous);
								setNextPage(res.data.next);
								setLoading(false);
								setTotalCountFromApi(res.data.count);
							});
					}}
					disabled={options.disabled}>
					<span className="p-3">Next</span>
				</button>
			);
		},
		PageLinks: (options) => {
			if (
				(options.view.startPage === options.page && options.view.startPage !== 0) ||
				(options.view.endPage === options.page &&
					options.page + 1 !== options.totalPages)
			) {
				const className = classNames(options.className, { "p-disabled": true });

				return (
					<span className={className} style={{ userSelect: "none" }}>
						...
					</span>
				);
			}

			return (
				<button
					type="button"
					className={options.className}
					onClick={(e) => {
						setLoading(true);
						options.onClick(e);
						axiosInstance
							.get(
								`admin/order-list?&page=${options.page + 1}&page_size=${countPerPage}`
							)

							.then((res) => {
								setFilteredData(res.data.results);
								setPrevPage(res.data.previous);
								setNextPage(res.data.next);
								setLoading(false);
								setTotalCountFromApi(res.data.count);
							});
					}}>
					{options.page + 1}
				</button>
			);
		},

		RowsPerPageDropdown: (options) => {
			const dropdownOptions = [
				{ label: 10, value: 10 },
				{ label: 25, value: 25 },
				{ label: 50, value: 50 },
			];
			return (
				<Dropdown
					value={options.value}
					options={dropdownOptions}
					onChange={(e) => {
						options.onChange(e);
						setCountPerPage(e.value);
						setRender(!render);
					}}
				/>
			);
		},
		CurrentPageReport: (options) => {
			return (
				<span
					className="mx-3"
					style={{ color: "var(--text-color)", userSelect: "none" }}></span>
			);
		},
	};

	const footer = (
		<Paginator
			template={template1}
			first={first[0]}
			rows={countPerPage}
			totalRecords={totalCountFromApi}
			onPageChange={(e) => onPageChange(e, 0)}
		/>
	);

	const statusBodyTemplate = (order) => {
		return (
			<>
				<span className={`order-badge status-${order?.order_status.toLowerCase()}`}>
					{order?.order_status}
				</span>
			</>
		);
	};
	const paymentStatusBodyTemplate = (order) => {
		return (
			<>
				<span
					className={`order-badge status-${order?.payment_status.toLowerCase()}`}>
					{order?.payment_status}
				</span>
			</>
		);
	};

	return (
		<>
			{/* {subHeaderComponentMemo} */}
			<DataTable
				footer={footer}
				value={filteredData}
				dataKey="id"
				rowHover
				stripedRows
				loading={loading}
				onRowClick={(e) => navigate(`/sales/orders/order-detail/${e.data.id}`)}
				emptyMessage="لا يوجد طلبات  توافق الاختيارات">
				<Column
					className="text-right"
					field="id"
					header="رقم الطلب"
					style={{ minWidth: "10%", textAlign: "right" }}></Column>
				<Column
					className="text-right"
					field="created_at"
					header="تاريخ الطلب"
					body={(rowData) => <>{rowData.created_at.split("T")[0]}</>}
					style={{ minWidth: "15%", textAlign: "right" }}></Column>

				<Column
					className="text-right"
					field="payment_status"
					header="حالة الدفع"
					body={paymentStatusBodyTemplate}
					style={{ minWidth: "15%", textAlign: "right" }}></Column>
				<Column
					className="text-right"
					field="shipping_price"
					header=" الشحن"
					style={{ minWidth: "15", textAlign: "right" }}></Column>
				<Column
					className="text-right"
					field="shipping_area"
					header=" مطقة الشحن"
					style={{ minWidth: "15%", textAlign: "right" }}></Column>
				<Column
					className="text-right"
					field="total_price_after_discount"
					header="السعر بعد الخصم"
					style={{ minWidth: "15%", textAlign: "right" }}></Column>
				<Column
					className="text-right"
					field="payment_method"
					header="طريقة الدفع"
					style={{ minWidth: "15%", textAlign: "right" }}></Column>
				<Column
					className="text-right"
					field="order_status"
					header="حالة الطلب"
					body={statusBodyTemplate}
					style={{ minWidth: "15%", textAlign: "right" }}></Column>
			</DataTable>
		</>
	);
};

export default DatatableOrders;
