import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
// import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import {
	Badge,
	Button,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from "reactstrap";

import axiosInstance from "../../axios";
import { toast } from "react-toastify";
import { ProgressBar } from "primereact/progressbar";
import { useEffect } from "react";
import EditMainDetails from "./EditMainDetails";

function MainDetails({
	data,
	HandleRender,
	selectedSeason,
	setSelectedSeason,
}) {
	const { productID } = useParams();
	const [open, setOpen] = useState(false);
	const [formData, updateFormData] = useState(null);

	const handleDelete = (row) => {
		if (window.confirm("هل تريد تغيير حالة الصنف اخفاء/اظهار ؟")) {
			axiosInstance
				.patch(`/products/product/${productID}`, { is_deleted: !data.is_deleted })
				.then((res) => {
					// setRender(res.data.id);
					updateFormData(null);
					HandleRender();
					toast.success("تم الاخفاء/الاظهار بنجاح !");
				})
				.catch((err) => {
					toast.error("حدث خطأ");
				});
		}
	};

	return (
		<>
			<Row>
				<EditMainDetails
					data={data}
					HandleRender={HandleRender}
					setSelectedSeason={setSelectedSeason}
					selectedSeason={selectedSeason}
				/>
				<hr />
				<Row style={{ display: "flex", justifyContent: "space-between" }}>
					<Col sm="6">
						<h2 className="product-title">تفاصيل</h2>
						<h4>
							{data?.season === "winter" ? (
								<Badge pill>شتوى</Badge>
							) : (
								<Badge pill>صيفي</Badge>
							)}
						</h4>
						<p>
							{data?.description_ar} - {data?.description_en}
						</p>
					</Col>
					<Col sm="6" className="md:mt-3" style={{ textAlign: "end" }}>
						<h2 className="product-title">
							حالة الصنف{" "}
							{data?.is_deleted === true || data?.is_deleted === "true" ? (
								<Badge color="primary" pill>
									مخفى
								</Badge>
							) : (
								<Badge color="success" pill>
									مرئى
								</Badge>
							)}
						</h2>
						<Button
							className="m-2"
							color="primary"
							onClick={(e) => {
								handleDelete(e);
							}}>
							{data?.is_deleted.toString() === "true" ? "مخفى" : "مرئى"}
							{data?.is_deleted.toString() === "true" ? (
								<i
									className="fa fa-eye"
									style={{
										fontSize: 20,
										marginRight: 5,
										color: "#ffffff",
									}}></i>
							) : (
								<i
									className="fa fa-eye-slash"
									style={{
										fontSize: 20,
										marginRight: 5,

										color: "#ffffff",
									}}></i>
							)}
						</Button>
					</Col>
				</Row>
				<Row>
					<Col>
						<h2>الفيديو</h2>
						<p>نوع الفيديو : {data?.video_type}</p>
						{data?.video_type === "video-url" ? (
							<iframe
								width="100%"
								height="315"
								src={data?.video_url}
								title="YouTube video player"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen></iframe>
						) : data?.video_type === "video-upload" ? (
							<video width="100%" height="315" controls>
								<source src={data?.video_upload} type="video/mp4" />
							</video>
						) : null}
					</Col>
				</Row>

				<hr />
				<div className="product-price digits mt-2">
					<Row>
						<Col>
							<h2>السعر</h2>
							<h3>
								{data?.price_after_discount} جنيه
								{data?.discount === 0 ? "" : <del>جنيه{data?.price}</del>}
							</h3>
							{data?.discount === 0 ? "" : <span>{data?.discount}%</span>}
						</Col>
					</Row>
				</div>
			</Row>
		</>
	);
}

export default MainDetails;
