import React, { Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";

import DatatableOrders from "./DatatableOrders";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

const Orders = () => {
	return (
		<Fragment>
			<Breadcrumb title="الطلبيات" />

			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardBody className="order-datatable">
								<DatatableOrders
									pageSize={10}
									pagination={true}
									class="-striped -highlight"
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default Orders;
