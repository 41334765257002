import {
	Home,
	Box,
	Chrome,
	Truck,
	Clipboard,
	Layers,
	Phone,
	Tool,
	Users,
} from "react-feather";

export const MENUITEMS = [
	{
		path: "/dashboard",
		title: "لوحة التحكم",
		icon: Home,
		type: "link",
		badgeType: "primary",
		active: false,
	},
	{
		path: "/main-page",
		title: "الصفحة الرئسية",
		icon: Chrome,
		type: "sub",
		active: false,
		children: [
			{ path: "/slider", title: "الصورة الرئسية", type: "link" },
			{ path: "/ParallaxImage", title: "الصورة المتحركة", type: "link" },
			{ path: "/bestseller", title: "الاكثر مبيعا", type: "link" },
			{ path: "/category-main-page", title: "الاقسام", type: "link" },
		],
	},
	{
		title: "تواصل معنا",
		icon: Phone,
		type: "sub",
		active: false,
		children: [
			{ path: "/contact-us", title: "صفحة تواصل معنا", type: "link" },
			{ path: "/Messages", title: "الرسائل", type: "link" },
		],
	},
	// {
	// 	title: "من نحن",
	// 	icon: Phone,
	// 	type: "sub",
	// 	active: false,
	// 	children: [{ path: "/about-us", title: "صفحة من نحن", type: "link" }],
	// },
	{
		path: "/Delivery",
		title: "التوصيل",
		icon: Truck,
		type: "sub",
		active: false,
		children: [{ path: "/AddCity", title: "المدن", type: "link" }],
	},
	{
		path: "/products",
		title: "الاصناف",
		icon: Box,
		type: "sub",
		active: false,
		children: [
			{ path: "/category", title: "الاقسام", type: "link" },
			{ path: "/products", title: "الاصناف", type: "link" },
		],
	},

	{
		title: "الطلبيات",
		icon: Layers,
		type: "sub",
		active: false,
		children: [
			{ path: "/sales/orders", title: "الطلبيات", type: "link" },
			{ path: "/Invoice", title: "فواتير", type: "link" },
		],
	},
	{
		path: "/Reports",
		title: "التقارير",
		icon: Clipboard,
		type: "sub",
		active: false,
		children: [
			{ path: "/reports/stock-report", title: "تحذير الكميات", type: "link" },

			{
				path: "/reports/order-report",
				title: "اجمالى مبيعات الفترة",
				type: "link",
			},

			// {
			// 	path: "/reports/period-Payment-Method-report",
			// 	title: "مبيعات فترة (نوع الدفع)",
			// 	type: "link",
			// },

			// {
			// 	path: "/reports/payment-status-Report",
			// 	title: "حالة المدفوعات وجهة التعامل ",
			// 	type: "link",
			// },

			// { path: "/reports/refund-report", title: "حالة الطلبيات", type: "link" },

			// { path: "/reports/client-report", title: "مبيعات عميل", type: "link" },
			{ path: "/reports/product-count", title: "مبيعات الاصناف", type: "link" },
		],
	},
	{
		title: "التحكم في الموقع",
		icon: Tool,
		type: "sub",
		active: false,
		children: [
			{ path: "/switch-off", title: "التحكم في الموقع", type: "link" },
			{ path: "/promo-codes", title: "الكوبونات", type: "link" },
		],
	},
	{
		title: "إدارة الصلاحيات والمستخدمين",
		icon: Users,
		type: "sub",
		active: false,
		children: [{ path: "/user-list", title: "ادارة المستخدمين", type: "link" }],
	},
	// {
	// 	title: "Products",
	// 	icon: Box,
	// 	type: "sub",
	// 	active: false,
	// 	children: [
	// 		{
	// 			title: "Physical",
	// 			type: "sub",
	// 			active: false,
	// 			children: [
	// 				{ path: "/products/physical/category", title: "Category", type: "link" },
	// 				{
	// 					path: "/products/physical/sub-category",
	// 					title: "Sub Category",
	// 					type: "link",
	// 				},
	// 				{
	// 					path: "/products/physical/product-list",
	// 					title: "Product List",
	// 					type: "link",
	// 				},
	// 				{
	// 					path: "/products/physical/product-detail",
	// 					title: "Product Detail",
	// 					type: "link",
	// 				},
	// 				{
	// 					path: "/products/physical/add-product",
	// 					title: "Add Product",
	// 					type: "link",
	// 				},
	// 			],
	// 		},
	// 	],
	// },
	// {
	//     title: 'Sales', icon: DollarSign, type: 'sub', active: false, children: [
	//         { path: '/sales/orders', title: 'Orders', type: 'link' },
	//         { path: '/sales/transactions', title: 'Transactions', type: 'link' },
	//     ]
	// },
	// {
	//     title: 'Coupons', icon: Tag, type: 'sub', active: false, children: [
	//         { path: '/coupons/list-coupons', title: 'List Coupons', type: 'link' },
	//         { path: '/coupons/create-coupons', title: 'Create Coupons', type: 'link' },
	//     ]
	// },
	// {
	//     title: 'Pages', icon: Clipboard , type: 'sub', active: false, children: [
	//         { path: '/pages/list-page', title: 'List Page', type: 'link' },
	//         { path: '/pages/create-page', title: 'Create Page', type: 'link' },
	//     ]
	// },
	// {
	//     title: 'Media', path: '/media', icon: Camera, type: 'link', active: false
	// },
	// {
	//     title: 'Menus', icon: AlignLeft, type: 'sub', active: false, children: [
	//         { path: '/menus/list-menu', title: 'List Menu', type: 'link' },
	//         { path: '/menus/create-menu', title: 'Create Menu', type: 'link' },
	//     ]
	// },
	// {
	//     title: 'Users', icon: UserPlus, type: 'sub', active: false, children: [
	//         { path: '/users/list-user', title: 'User List', type: 'link' },
	//         { path: '/users/create-user', title: 'Create User', type: 'link' },
	//     ]
	// },
	// {
	//     title: 'Vendors', icon: Users, type: 'sub', active: false, children: [
	//         { path: '/vendors/list_vendors', title: 'Vendor List', type: 'link' },
	//         { path: '/vendors/create-vendors', title: 'Create Vendor', type: 'link' },
	//     ]
	// },
	// {
	//     title: 'Localization', icon: Chrome, type: 'sub', children: [
	//         { path: '/localization/transactions', title: 'Translations', type: 'link' },
	//         { path: '/localization/currency-rates', title: 'Currency Rates', type: 'link' },
	//         { path: '/localization/taxes', title: 'Taxes', type: 'link' }
	//     ]
	// },
	// {
	//     title: 'Reports',path:'/reports/report', icon: BarChart, type: 'link', active: false
	// },
	// {
	//     title: 'Settings', icon: Settings, type: 'sub', children: [
	//         { path: '/settings/profile', title: 'Profile', type: 'link' },
	//     ]
	// },
	// {
	//     title: 'Invoice',path:'/invoice', icon: Archive, type: 'link', active: false
	// },
	// {
	// 	title: "Login",
	// 	path: "/auth/login",
	// 	icon: LogIn,
	// 	type: "link",
	// 	active: false,
	// },
];
