import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
	Button,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "reactstrap";
import axiosInstance from "../../axios";
import { ToastContainer, toast } from "react-toastify";

function EditColor({ colorData, HandleRenderColor }) {
	const [open, setOpen] = useState(false);
	const [formData, updateFormData] = useState(null);
	const { productID } = useParams();
	const onOpenModal = () => {
		setOpen(true);
	};

	const onCloseModal = () => {
		setOpen(false);
	};
	const handleChange = (e) => {
		updateFormData({
			...formData,
			// Trimming any whitespace
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		if (formData !== null) {
			e.preventDefault();
			if (
				parseFloat(
					formData.stock_quantity
						? formData.stock_quantity
						: colorData.stock_quantity
				) <=
				parseFloat(
					formData.alert_stock_quantity
						? formData.alert_stock_quantity
						: colorData.alert_stock_quantity
				)
			) {
				toast.warning("يجب ان تكون كمية التحذير افل من الكمية المتاحة");
			} else {
				axiosInstance
					.patch(
						`/products/product/${productID}/product-line/${colorData.id}`,
						formData
					)
					.then((res) => {
						// setRender(res.data.id);
						updateFormData(null);
						if (res.status === 200) {
							toast.success("تم التعديل بنجاح");
						}
						HandleRenderColor();
						onCloseModal("VaryingMdo");
					})
					.catch((err) => {
						if (err.response.status === 400) {
							toast.warning(`تاكد من البيانات المدخلة`);
						} else {
							toast.error(`${err.response.status} تاكد من البيانات المدخلة`);
						}
					});
			}
		} else {
			toast.error("يرجى تعديل البيانات");
		}
	};

	return (
		<>
			<ToastContainer autoClose={2000} rtl />
			<span>
				<i
					onClick={(e) => {
						if (colorData !== null) {
							onOpenModal();
						} else {
							toast.error("برجاء اختيار لون");
						}
					}}
					className="fa fa-pencil"
					style={{
						width: 35,
						fontSize: 20,
						padding: 11,
						color: "rgb(40, 167, 69)",
						cursor: "pointer",
					}}></i>
				{/* main modal to edit color start */}
				<Modal isOpen={open} toggle={onCloseModal}>
					<ModalHeader toggle={onCloseModal}>
						<div className="modal-title f-w-600" id="exampleModalLabel2">
							تعديل
						</div>
					</ModalHeader>
					<ModalBody>
						<Form>
							<FormGroup>
								<Label htmlFor="recipient-name" className="col-form-label">
									اللون عربى :
								</Label>
								<Input
									type="text"
									name="color_ar"
									className="form-control"
									onChange={handleChange}
									defaultValue={colorData?.color_ar}
								/>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="recipient-name" className="col-form-label">
									اللون انجليزي :
								</Label>
								<Input
									type="text"
									name="color_en"
									className="form-control"
									onChange={handleChange}
									defaultValue={colorData?.color_en}
								/>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="message-text" className="col-form-label">
									لون :
								</Label>
								<Input
									name="color_value"
									// className="form-control"
									id="validationCustom02"
									type="color"
									onChange={handleChange}
									defaultValue={colorData?.color_value}
								/>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="recipient-name" className="col-form-label">
									الكمية :
								</Label>
								<Input
									type="number"
									name="stock_quantity"
									className="form-control"
									onChange={handleChange}
									defaultValue={colorData?.stock_quantity}
								/>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="recipient-name" className="col-form-label">
									تحذير الكمية :
								</Label>
								<Input
									type="text"
									name="alert_stock_quantity"
									className="form-control"
									onChange={handleChange}
									defaultValue={colorData?.alert_stock_quantity}
								/>
							</FormGroup>
						</Form>
					</ModalBody>
					<ModalFooter className="d-flex justify-content-between">
						<Button
							type="button"
							color="secondary"
							onClick={(e) => {
								handleSubmit(e);
							}}>
							حفظ
						</Button>
						<Button
							type="button"
							color="primary"
							onClick={() => onCloseModal("VaryingMdo")}>
							إغلاق
						</Button>
					</ModalFooter>
				</Modal>
				{/* main modal to edi color end */}
			</span>
		</>
	);
}

export default EditColor;
