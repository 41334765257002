import React from "react";
import { useState } from "react";
import {
	Button,
	Form,
	FormGroup,
	Input,
	Label,
	Media,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	CardText,
	CardSubtitle,
	CardTitle,
	CardBody,
	CardImg,
	Card,
	CardGroup,
	Container,
	Row,
	Col,
} from "reactstrap";
import axiosInstance from "../../axios";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function DeleteImage({ colorData, selectedImage, HandleRenderColor }) {
	const [openImages, setOpenImages] = useState(false);
	const { productID } = useParams();
	const [formData, updateFormData] = useState(null);

	const onOpenModalImages = () => {
		setOpenImages(true);
	};

	const onCloseModalImages = () => {
		setOpenImages(false);
	};
	const handleSubmitDeleteImage = (id) => {
		if (window.confirm(`هل تريد حذف الصورة`)) {
			axiosInstance
				.delete(
					`/products/product/${productID}/product-line/${colorData.id}/img/${selectedImage.id}`
				)
				.then((res) => {
					updateFormData(null);

					if (res.status === 204) {
						toast.success("تم الحذف بنجاح");
					}
					HandleRenderColor();
					onCloseModalImages();
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};
	return (
		<>
			<ToastContainer />

			<Button color="primary" onClick={onOpenModalImages}>
				عرض صورة
				<i className="fa fa-eye"></i>
			</Button>
			<Modal
				isOpen={openImages}
				toggle={onCloseModalImages}
				style={{ overlay: { opacity: 0.3 } }}>
				<ModalHeader toggle={onCloseModalImages}>
					<h6 className="modal-title f-w-600" id="exampleModalLabel2">
						مسح الصورة
					</h6>
				</ModalHeader>
				<ModalBody>
					<Container fluid={true}>
						<Row className="products-admin ratio_asos d-flex justify-content-center">
							<Card className="">
								<img alt={selectedImage?.alt} src={selectedImage?.url} />
								<CardBody>
									<CardTitle tag="h5">{selectedImage?.alt}</CardTitle>
								</CardBody>
							</Card>
						</Row>
					</Container>
				</ModalBody>
				<ModalFooter className="d-flex justify-content-between">
					<Button
						className="d-flex"
						color="secondary"
						onClick={(e) => {
							handleSubmitDeleteImage(selectedImage?.id);
						}}>
						مسح
					</Button>
					<Button type="button" color="primary" onClick={() => onCloseModalImages()}>
						اغلاق
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}

export default DeleteImage;
