import React from "react";
import { useEffect } from "react";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import axiosInstance from "../axios";
import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";

function RecentOrder() {
	const navigate = useNavigate();
	const [filteredData, setFilteredData] = useState([]);
	useEffect(() => {
		axiosInstance
			.get(`report/order-report?order_status=pending&page_size=5`)
			.then((res) => {
				setFilteredData(res.data.results);
			});
	}, []);
	const statusBodyTemplate = (order) => {
		return (
			<>
				<span className={`order-badge status-${order?.order_status.toLowerCase()}`}>
					{order?.order_status}
				</span>
			</>
		);
	};
	return (
		<>
			<Card>
				<CardHeader>
					<h5>اخر الطلبيات</h5>
				</CardHeader>
				<CardBody>
					<div className="user-status table-responsive latest-order-table">
						<DataTable
							selectionMode="single"
							value={filteredData}
							tableStyle={{
								minWidth: "auto",
								textAlign: "center",
							}}
							rowHover
							showGridlines
							onRowClick={(e) =>
								navigate(`/sales/orders/order-detail/${e.data.id}`, {})
							}>
							<Column field="id" header="رقم" style={{ textAlign: "right" }}></Column>
							<Column
								body={(data) => <div>{data.created_at.split("T")[0]}</div>}
								header="تاريخ"
								style={{ textAlign: "right" }}></Column>
							<Column
								field="total_price_after_discount"
								header="اجمالى الطلب"
								style={{ textAlign: "right" }}></Column>
							<Column
								body={statusBodyTemplate}
								header="حالة الطلب"
								style={{ textAlign: "right" }}></Column>
						</DataTable>
					</div>
				</CardBody>
			</Card>
		</>
	);
}

export default RecentOrder;
