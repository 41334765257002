import React, { Fragment, useEffect, useState } from "react";
// import Breadcrumb from "../../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import axiosInstance from "../axios";

import Breadcrumb from "../common/breadcrumb";
import DatatableStockReport from "../common/datatableStockReport";

const StockReport = () => {
	const [render, setRender] = useState(false);

	const [data, setData] = useState([]);

	useEffect(() => {
		axiosInstance.get(`/report/stock-alert`).then((res) => {
			setData(res.data);
		});
	}, [render]);

	const handleRender = () => {
		setRender(!render);
	};

	return (
		<Fragment>
			<Breadcrumb title="تقرير تحذير الكميات" />
			{/* <!-- Container-fluid starts--> */}
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<div id="basicScenario" className="product-physical">
									<DatatableStockReport
										myData={data}
										multiSelectOption={false}
										pageSize={10}
										pagination={true}
										class="-striped -highlight"
										handleRender={handleRender}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			{/* <!-- Container-fluid Ends--> */}
		</Fragment>
	);
};

export default StockReport;
