import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import Datatable from "../common/datatable";
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from "reactstrap";

import axiosInstance from "../axios";
import AddMainProduct from "./AddMainProduct";

const Products = () => {
	const [render, setRender] = useState(false);

	const [data, setData] = useState({});

	useEffect(() => {
		axiosInstance.get(`/products/product`).then((res) => {
			setData(res.data);
		});
	}, [render]);

	function HandleRender() {
		setRender(!render);
	}

	return (
		<Fragment>
			<Breadcrumb title="المنتجات" />
			{/* <!-- Container-fluid starts--> */}
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<AddMainProduct HandleRender={HandleRender} />
								<Datatable
									myData={data}
									multiSelectOption={false}
									pageSize={10}
									pagination={true}
									class="-striped -highlight"
									HandleRender={HandleRender}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			{/* <!-- Container-fluid Ends--> */}
		</Fragment>
	);
};

export default Products;
