import React from "react";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Media,
	Row,
} from "reactstrap";
import Breadcrumb from "../../common/breadcrumb";
import { useEffect } from "react";
import axiosInstance from "../../axios";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { Image } from "react-feather";
import { Chart } from "primereact/chart";
function ProductReport() {
	const { productID } = useParams();
	const [data, setData] = useState(null);
	const [chartPieLable, setChartPieLable] = useState([]);
	const [chartPieColor, setChartPieColor] = useState(["#ffffff"]);
	const [chartPiePrice, setChartPiePrice] = useState([]);
	const [chartQty, setChartQty] = useState([1]);

	useEffect(() => {
		axiosInstance
			.get(`/report/product-line-count-report/${productID}`)
			.then((res) => {
				let tempLable = [];
				let tempColor = [];
				let tempPrice = [];
				let tempQty = [];

				for (let i = 0; i < res.data.product_line.length; i++) {
					tempLable.push(res.data.product_line[i].product_line_details.color_ar);
					tempColor.push(res.data.product_line[i].product_line_details.color_value);
					tempPrice.push(res.data.product_line[i].product_line_total_price);
					tempQty.push(res.data.product_line[i].product_line_total_quantity);
				}

				setData(res.data);
				setChartPieColor(tempColor);
				setChartPiePrice(tempPrice);
				setChartPieLable(tempLable);
				setChartQty(tempQty);
			});
	}, []);

	const chartData = {
		labels: chartPieLable,
		datasets: [
			{
				data: chartQty,
				backgroundColor: chartPieColor,
			},
		],
	};
	const chartData1 = {
		labels: chartPieLable,
		datasets: [
			{
				data: chartPiePrice,
				backgroundColor: chartPieColor,
			},
		],
	};
	// items name
	const [lightOptions] = useState({
		plugins: {
			legend: {
				labels: {
					color: "#495057",
				},
			},
		},
	});

	return (
		<>
			<Breadcrumb title="تقرير صنف" />

			<Container fluid={true}>
				<Card>
					<Row className="p-3">
						<Col md="6">
							<div className="text-center">
								<img
									src={data?.product_details?.improved_url}
									alt=""
									className="img-fluid "
								/>
							</div>
						</Col>
						<Col md="6">
							<div className="text-center ">
								<h2>{data?.product_details?.name_ar}</h2>
								<hr />
								<Row className="px-3">
									<Col className="d-flex justify-content-between">
										<h3>اجمالى الكمية المباعة</h3>
										<span style={{ fontWeight: "bold" }}>
											{data?.product_total_quantity} متر
										</span>
									</Col>
								</Row>
								<Row className="px-3">
									<Col className="d-flex justify-content-between">
										<h3>اجمالى السعر</h3>
										<span style={{ fontWeight: "bold" }}>
											{data?.product_total_price} جنيه
										</span>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
					<hr />
					<Row className="p-3">
						<Col sm="12">
							<Card>
								<CardHeader>
									<h5>المبيعات الكلية</h5>
								</CardHeader>
								<CardBody>
									<Row>
										<Col sm="6">
											<div className="order-graph">
												<h6>اجمالى كميات الصنف</h6>
												<div className="chart-block chart-vertical-center  ">
													<div className="w-50  m-auto">
														<Chart
															type="pie"
															data={chartData}
															options={lightOptions}
															style={{ position: "relative" }}
														/>
													</div>
												</div>
												<div className="order-graph-bottom">
													{data?.product_line?.map((item) => (
														<Media key={item.id}>
															<div
																style={{
																	width: "15px",
																	height: "8px",
																	marginTop: "7px",
																	borderRadius: "4px",
																	backgroundColor: item.product_line_details.color_value,
																}}></div>
															<Media body>
																<h6 className="mb-0">
																	{item.product_line_details.color_ar}{" "}
																	<span className="pull-right">
																		{item.product_line_total_quantity} متر
																	</span>
																</h6>
															</Media>
														</Media>
													))}
												</div>
											</div>
										</Col>
										<Col sm="6">
											<div className="order-graph sm-order-space">
												<h6>نسب الالوان</h6>
												<div className="peity-chart-dashboard text-center">
													<div className="w-50  m-auto">
														<Chart
															type="doughnut"
															data={chartData1}
															options={lightOptions}
															style={{ position: "relative" }}
														/>
													</div>
												</div>
												<div className="order-graph-bottom sales-location">
													{data?.product_line?.map((item) => (
														<Media key={item.id}>
															<div
																style={{
																	width: "15px",
																	height: "8px",
																	marginTop: "7px",
																	borderRadius: "4px",
																	backgroundColor: item.product_line_details.color_value,
																}}></div>
															<Media body>
																<h6 className="mb-0">
																	{item.product_line_details.color_ar}{" "}
																	<span className="pull-right">
																		{item.product_line_total_price} جنيه
																	</span>
																</h6>
															</Media>
														</Media>
													))}
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Card>
			</Container>
		</>
	);
}

export default ProductReport;
