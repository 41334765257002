import React, { useState } from "react";
import { classNames } from "primereact/utils";

import {
	Input,
	Container,
	Row,
	Form,
	Label,
	Col,
	FormGroup,
	Button,
} from "reactstrap";

import { useEffect } from "react";
import { Password } from "primereact/password";
import { Dialog } from "primereact/dialog";
import axiosInstance from "../axios";
import { toast } from "react-toastify";
import { useFormik } from "formik";

const CreateStaffUser = ({ handleRender }) => {
	useEffect(() => {
		let isAuth = localStorage.getItem("access_token");
		if (isAuth && isAuth !== null) {
			// navigate("/dashboard");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const initialFormData = Object.freeze({
	// 	email: "",
	// 	first_name: "",
	// 	confirm_password: "",
	// 	password: "",
	// 	mobile_number: "",
	// });

	// const [formData, updateFormData] = useState(initialFormData);

	// const handleChange = (e) => {
	// 	updateFormData({
	// 		...formData,
	// 		[e.target.name]: e.target.value.trim(),
	// 	});
	// };

	// const handleSubmit = (e) => {
	// 	e.preventDefault();
	// 	axiosInstance
	// 		.post(`/admin/staff-create`, {
	// 			email: formData.email,
	// 			first_name: formData.first_name,
	// 			mobile_number: formData.mobile_number,
	// 			confirm_password: formData.confirm_password,
	// 			password: formData.password,
	// 		})
	// 		.then((res) => {
	// 			updateFormData(initialFormData);
	// 			handleRender();
	// 			setVisible(false);
	// 			toast.success("تم اضافة المستخدم بنجاح");
	// 		})
	// 		.catch((err) => {
	// 			if (
	// 				err.response.data.detail ===
	// 				"No active account found with the given credentials"
	// 			) {
	// 				alert("مستخدم غير موجود");
	// 			}
	// 		});
	// };
	const [visible, setVisible] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [errorMSG, setErrorMSG] = useState("");
	const formik = useFormik({
		initialValues: {
			first_name: "",
			mobile_number: "",
			email: "",
			password: "",
			confirm_password: "",
			// accept: false,
		},

		validate: (data) => {
			let errors = {};

			if (!data.first_name) {
				errors.first_name = "يجب ادخال الاسم";
			}
			if (!data.mobile_number) {
				errors.mobile_number = "يجب ادخال رقم الموبايل";
			}

			if (!data.email) {
				errors.email = "يجب إدخال البريد الإلكنرونى.";
			} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
				errors.email = "عنوان بريد إلكترونى غير صالح. مثال example@gmail.com";
			}

			if (!data.password) {
				errors.password = "كلمة السر مطلوبة.";
			}

			if (!data.confirm_password) {
				errors.confirm_password = "كلمة السر مطلوبة.";
			}
			if (data.password !== data.confirm_password) {
				errors.confirm_password = "كلمة السر يجب ان تكون مطابقة.";
			}

			// if (!data.accept) {
			// 	errors.accept = "يجب عليك الموافقة على الشروط والأحكام";
			// }
			return errors;
		},

		onSubmit: (data) => {
			console.log("data");
			axiosInstance
				.post(`/admin/staff-create`, {
					first_name: formik.values.first_name,
					mobile_number: formik.values.mobile_number,
					email: formik.values.email,
					password: formik.values.password,
					confirm_password: formik.values.confirm_password,
				})
				.then((res) => {
					handleRender();
					setVisible(false);
					toast.success("تم اضافة المستخدم بنجاح");
				})
				.catch((err) => {
					if (err.response.status === 400) {
						setErrorMSG("البريد الالكنرونى مسجل من قبل");
						setErrorMessage(true);
					} else if (err.response.status === 500) {
						setErrorMSG("برجاء المحاولة فى وقت لاحق");
						setErrorMessage(true);
					} else if (err.response.status === 404) {
						setErrorMSG("برجاء المحاولة فى وقت لاحق");
						setErrorMessage(true);
					} else if (err.response.status === 401) {
						setErrorMSG("برجاء المحاولة فى وقت لاحق");
						setErrorMessage(true);
					} else if (err.response.status === 403) {
						setErrorMSG("برجاء المحاولة فى وقت لاحق");
						setErrorMessage(true);
					}
					console.log(err.response.status);
				});
			formik.resetForm();
		},
	});
	const isFormFieldValid = (name) =>
		!!(formik.touched[name] && formik.errors[name]);
	const getFormErrorMessage = (name) => {
		return (
			isFormFieldValid(name) && (
				<small className="p-error">{formik.errors[name]}</small>
			)
		);
	};

	return (
		<>
			<div className="d-flex justify-content-end mb-2">
				<Button
					icon="pi pi-external-link"
					onClick={() => setVisible(true)}
					color="primary">
					اضافة مستخدم
				</Button>
			</div>
			<Dialog
				header="اضافة مستخدم"
				visible={visible}
				style={{ width: "50vw" }}
				onHide={() => setVisible(false)}>
				<Form className="theme-form" onSubmit={formik.handleSubmit}>
					<FormGroup>
						<Label
							className={classNames({ "p-error": isFormFieldValid("first_name") })}
							for="first_name">
							الاسم
						</Label>
						<Input
							type="text"
							id="fname"
							placeholder="الاسم"
							required=""
							name="first_name"
							value={formik.values.first_name}
							onChange={formik.handleChange}
							className={classNames({
								"p-invalid": isFormFieldValid("first_name"),
							})}
						/>
						{getFormErrorMessage("first_name")}
					</FormGroup>
					<FormGroup>
						<Label
							className={classNames({
								"p-error": isFormFieldValid("mobile_number"),
							})}
							for="email">
							البريد الإلكتروني
						</Label>
						<Input
							type="email"
							className={classNames({ "p-invalid": isFormFieldValid("email") })}
							id="email"
							placeholder="البريد الإلكتروني"
							required=""
							value={formik.values.email}
							name="email"
							onChange={formik.handleChange}
						/>
						{getFormErrorMessage("email")}
					</FormGroup>
					<FormGroup>
						<Label
							className={classNames({ "p-error": isFormFieldValid("mobile_number") })}
							for="phone_number">
							رقم الهاتف
						</Label>
						<Input
							type="tel"
							id="phone_number"
							placeholder="رقم الهاتف"
							required=""
							name="mobile_number"
							value={formik.values.mobile_number}
							onChange={formik.handleChange}
							className={classNames({
								"p-error": isFormFieldValid("mobile_number"),
							})}
						/>
						{getFormErrorMessage("mobile_number")}
					</FormGroup>

					<FormGroup>
						<Label
							className={classNames({ "p-error": isFormFieldValid("password") })}
							for="review">
							كلمة المرور
						</Label>
						<Input
							value={formik.values.password}
							// strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
							className={classNames({ "p-invalid": isFormFieldValid("password") })}
							onChange={formik.handleChange}
							type="password"
							// className="form-control"
							id="review"
							placeholder="كلمة المرور"
							required=""
							name="password"
							// onChange={handleChange}
						/>
						{getFormErrorMessage("password")}
					</FormGroup>
					<FormGroup>
						<Label
							className={classNames({
								"p-error": isFormFieldValid("confirm_password"),
							})}
							for="review">
							تأكيد كلمة المرور
						</Label>
						<Input
							id="confirm_password"
							name="confirm_password"
							value={formik.values.confirm_password}
							onChange={formik.handleChange}
							className={classNames({
								"p-invalid": isFormFieldValid("confirm_password"),
							})}
							type="password"
							placeholder="تأكيد كلمة المرور"
							required=""
						/>
						{getFormErrorMessage("confirm_password")}
					</FormGroup>
					<div className="d-flex justify-content-between">
						<Button className="btn btn-solid w-auto" type="submit" color="secondary">
							انشاء
						</Button>
						<Button
							color="primary"
							className="btn btn-solid w-auto"
							onClick={() => setVisible(false)}>
							اغلاق
						</Button>
					</div>
				</Form>
			</Dialog>
		</>
	);
};

export default CreateStaffUser;
