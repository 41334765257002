import React, { Fragment } from "react";
import { ShoppingBag, Download, AlertCircle } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Media } from "reactstrap";

const Notification = ({ totalCount, filteredData }) => {
	const navigate = useNavigate();
	return (
		<Fragment>
			<ul className="notification-dropdown onhover-show-div p-0">
				<li>
					الطلبيات الجديدة{" "}
					<span className="badge rounded-pill badge-primary pull-right">
						{totalCount}
					</span>
				</li>
				{filteredData?.map((item) => {
					return (
						<li
							key={item.id}
							onClick={() => {
								navigate(`/sales/orders/order-detail/${item.id}`, {});
							}}>
							<Media>
								<Media body>
									<h6 className="mt-0">
										<span>
											<ShoppingBag />
										</span>
										لديك طلبية جديدة برقم : {item.id}
									</h6>
								</Media>
							</Media>
						</li>
					);
				})}

				<li
					className="txt-dark"
					onClick={() => {
						navigate("/sales/orders");
					}}>
					<span
						style={{
							cursor: "pointer",
							color: "red",
							fontWeight: "bold",
						}}>
						جميع الطلبيات
					</span>
				</li>
			</ul>
		</Fragment>
	);
};

export default Notification;
